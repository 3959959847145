import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterViewInit {

  // @ViewChild('addmedia') addMediaTemplateRef!: TemplateRef<any>;
  // @ViewChild('verifylinkTemplateRef') verifylinkTemplateRef!: TemplateRef<any>;
  @ViewChild('verifylinkTemplateRef', { static: false }) verifylinkTemplateRef!: TemplateRef<any>; // Access the TemplateRef

  selectedRegister: string = ''
  selectedNetwork: string = ''
  selectedNetwork1: string = ''
  selectedNetwork2: string = ''


  pincode: string = '';
  state: string = '';
  country: string = '';
  street: string = '';
  city: string = '';
  state_district: string = ''

  Register = [
    { name: 'Individual', value: 'Individual' },
    { name: 'Company', value: 'Company' },
  ];

  affiliateNetwork = [
    { name: 'customer service', value: 'customer service' },
  ];

  affiliateNetwork1 = [
    { name: 'standard', value: 'standard' },
  ];

  affiliateNetwork2 = [
    { name: 'ust dial', value: 'ust dial' },
  ]
  userRegiterData: any;
  userId: any;
  constructor(
    private server: ApiService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    // Retrieve userId from query params
    this.route.queryParams.subscribe(params => {
      console.log(params); // Log the entire params object to see its structure
      this.userId = params['userid'];
      console.log(this.userId, 'userId');
    });
    this.userDetailsData();
    // this.emailIdVerification();
    // this.AddressFromPincode();
  }

  RegistrationData(form: any) {
    console.log('reg')
    let data = {
      user_id: this.userId,
      // user_id: '13',
      registerAs: form.registerAs,
      name: form.pname,
      companyName: form.companyName,
      mobileNO: form.mobileNO,
      alternateNO: form.alternateNO,
      industry: form.industry,
      companyType: form.companyType,
      companyURL: form.companyURL,
      source: form.source,
      country: form.country,
      Address: form.Address,
      pincode: form.pincode,
      city: form.city,
      district: form.district,
      state: form.state,
      billing_address: true,
      shipping_address: true
    }
    console.log(data, 'vvvvvv');
    this.server.Registration(data).subscribe((res: any) => {
      console.log(res, 'ressssssssss');
      if (res.statusCode == 200) {
        // this.emailIdVerification();
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/signin']);
          // Redirect based on the role returned from the server
      if (res.result.role === 'admin') {
        this.router.navigate(['/admin/signin']);
      } else if (res.result.role === 'user') {
        this.router.navigate(['/signin']);
      }
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      // this.cartStatusUpdate();
    });

  }

  emailIdVerification() {
    let obj = {
      id: this.userId,
    }
    this.server.emailVerification(obj).subscribe((res: any) => {
      console.log(res, 'red verification email');
      if (res.statusCode == 200) {
        // this.Registration()
        // this.emailIdVerification();
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/signin']);
      } else if (res.statusCode == 400) {
        // this.snackBar.open(res.message, 'x', {
        //   duration: 3000,
        //   verticalPosition: 'top',
        //   panelClass: ['custom-style']
        // });
        // this.openDocDialogWithTemplateRefVerifyLink(this.verifylinkTemplateRef);
        
        setTimeout(()=>{
          this.openDocDialogWithTemplateRefVerifyLink();
          },1000)

      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }

  // openDocDialogWithTemplateRefVerifyLink(templateRef: TemplateRef<any>): void {
  //   this.dialog.open(templateRef, {
  //     height: '300px',
  //     width: '600px',
  //     panelClass: 'my-panelColor',
  //   });
  // }

  openDocDialogWithTemplateRefVerifyLink(): void {
    if (this.verifylinkTemplateRef) {
    this.dialog.open(this.verifylinkTemplateRef, {
      height: '300px',
      width: '600px',
      panelClass: 'my-panelColor',
    });
  }else{
    console.log("dialog is not intialized")
  }
  }
  

  userDetailsData() {
    let obj = {
      id: this.userId,
      // id: '34',
    }
    this.server.oneProfilre(obj).subscribe((res: any) => {
      console.log(res, 'red verification email');
      this.userRegiterData = res.result;
      console.log(this.userRegiterData, 'userRegiterData');
    })
    this.emailIdVerification();
  }

  // someMethod(form: any) {
  //   this.emailIdVerification();
  // }

  emailVerifications() {
    let data = {
      email: this.userRegiterData.email,
    };
    console.log(data, 'email veriff');
    this.server.verificationLinkExpires(data).subscribe((res: any) => {
      console.log(res, 'verificationLinkExpires');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.router.navigate(['/signup']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }

  onPincodeChange(event: any) {
    const pincodeStr = event ? event.toString() : '';  // Convert to string and handle null/undefined cases
  
    console.log(pincodeStr, 'pincode event received');
    
    if (pincodeStr.length === 6) {
      this.pincode = pincodeStr;  // Store the 6-digit pincode
      console.log('Pincode length is 6, calling AddressFromPincode');
      this.AddressFromPincode();
    } else {
      console.log('Pincode is not 6 digits, current length:', pincodeStr.length);
    }
  }


  AddressFromPincode() {
    console.log('AddressFromPincode')
    let data = {
      pincode: this.pincode,
    };
    console.log(data, 'pincode');
    this.server.getAddressFromPincode(data).subscribe((res: any) => {
      console.log(res, 'pincode res');
      this.state = res.result.state;
      this.country = res.result.country;
      this.street = res.result.street;
      this.city = res.result.city;
      this.state_district = res.result.state_district;
     
      console.log(this.state, 'this.state res');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/signup']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }


}
