<div class="card">
  <div class="row m-0 p-0 mainRow">
    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      <h1 class="headings">Personal Information</h1>
      <form class="example-form">
        <!-- <div class="input-container">
              <input [(ngModel)]="name" class="inputField" [disabled]="currentEditable !== 'name'" #nameInput [ngModelOptions]="{standalone: true}">
              <span class="click-overlay" (click)="onEditClick('name')"></span>
            </div>
            
            <div class="input-container">
              <input [(ngModel)]="mobilenumber" class="inputField" [disabled]="currentEditable !== 'mobile'" #mobileInput [ngModelOptions]="{standalone: true}">
              <span class="click-overlay" (click)="onEditClick('mobile')"></span>
            </div>
            
            <div class="input-container">
              <input [(ngModel)]="email" class="inputField" [disabled]="currentEditable !== 'email'" #emailInput [ngModelOptions]="{standalone: true}">
              <span class="click-overlay" (click)="onEditClick('email')"></span>
            </div> -->

        <div class="input-container">
          <input [(ngModel)]="name" class="inputField" [disabled]="currentEditable !== 'name'"
            [ngStyle]="getBackgroundStyle('name')" #nameInput [ngModelOptions]="{standalone: true}">
          <span class="click-overlay" *ngIf="currentEditable !== 'name'" (click)="onEditClick('name')"></span>
          <span class="click-overlay" *ngIf="currentEditable === 'name'" (click)="onSaveClick('name')"></span>
        </div>

        <div class="input-container">
          <input [(ngModel)]="mobilenumber" class="inputField" [disabled]="currentEditable !== 'mobile'"
            [ngStyle]="getBackgroundStyle('mobile')" #mobileInput [ngModelOptions]="{standalone: true}">
          <span class="click-overlay" *ngIf="currentEditable !== 'mobile'" (click)="onEditClick('mobile')"></span>
          <span class="click-overlay" *ngIf="currentEditable === 'mobile'" (click)="onSaveClick('mobile')"></span>
        </div>

        <div class="input-container">
          <input [(ngModel)]="email" class="inputField" [disabled]="currentEditable !== 'email'"
            [ngStyle]="getBackgroundStyle('email')" #emailInput [ngModelOptions]="{standalone: true}">
          <span class="click-overlay" *ngIf="currentEditable !== 'email'" (click)="onEditClick('email')"></span>
          <span class="click-overlay" *ngIf="currentEditable === 'email'" (click)="onSaveClick('email')"></span>
        </div>
      </form>
      
      <!-- <h1 class="headings">Password</h1>
      <div class="password-container">
        <input [type]="showPassword ? 'text' : 'password'" name="password" placeholder="Password" class="manualInput"
          #passwordField>
        <span class="password-toggle" (click)="togglePasswordVisibility(passwordField)"></span>
      </div>
      <button class="passwordBtn">Change Password</button> -->
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      <h1 class="headings">Company Information</h1>
      <form class="example-form">
        <input [(ngModel)]="company_Name" value="Company Name" class="manualdisplay" disabled
          [ngModelOptions]="{standalone: true}">
        <input [(ngModel)]="userId" value="User ID" class="manualdisplay" disabled
          [ngModelOptions]="{standalone: true}">

      </form>

    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 imagess">
      <div class="card image-container">
        <img [src]="url" class="image">
        <div class="camera-container">
          <img src="../../assets/images/addImage.svg" class="camera" (click)="openFilePicker()">
          <input type="file" id="fileInput" (change)="onSelect($event)" style="display: none;" />
        </div>
      </div>
    </div>
  </div>
</div>