// src/app/validators.ts
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function multipleOfValidator(factor: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate if there's no value
    }
    return value % factor === 0 ? null : { 'notMultiple': { value } };
  };
}
