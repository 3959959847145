import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit{
  name: string = 'Name';
  mobilenumber: string = '1234567890';
  email: string = 'example@example.com';
  showPassword: boolean = false;
  company_Name: any;
  userId: any;
  currentEditable: string = ''; // Track the currently editable field
url="../../assets/images/profile-Pcl.svg";
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  nameFormControl = new FormControl('', [Validators.required]);
  mobileNumberFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  AmdinProfileData: any;
  togglePasswordVisibility(passwordField: HTMLInputElement): void {
    this.showPassword = !this.showPassword;
    console.log(' this.showPassword', this.showPassword);
    
    passwordField.type = this.showPassword ? 'text' : 'password';
  }

  constructor(private server: ApiService) {
  }
  ngOnInit() {
    this.oneProfilreData();
  }


  onSelect(e:any){
    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload=(event:any)=>{
        this.url=event.target.result;
        console.log('Selected File',this.url);
        
      }
    }
  }
  onEditClick(field: string): void {
    this.currentEditable = field; 
    console.log('currentEditable',this.currentEditable);
    
  }
  onSaveClick(field: string): void {
    this.currentEditable = '';
    // Type assertion to access the property dynamically
    const fieldValue = (this as any)[field];
    console.log(`Saved ${field}:`, fieldValue);
  }

  getBackgroundStyle(field: string): object {
    return {
      'background-image': this.currentEditable === field
        ? 'url(../../assets/images/save.png)'
        : 'url(../../assets/images/edit-pcl.svg)'
  
    };
  }
  openFilePicker() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }


  oneProfilreData() {
    let data = {
      id: localStorage.getItem('userId')
    }
    this.server.oneProfilre(data).subscribe((res: any) => {
        console.log(res, 'dataaaaaa');
        this.AmdinProfileData = res.result;
        this.name = this.AmdinProfileData.firstName
        this.email = this.AmdinProfileData.email
        this.mobilenumber = this.AmdinProfileData.slc_userprofiles[0].mobileNO
        this.company_Name = this.AmdinProfileData.slc_userprofiles[0].companyName
        this.userId = this.AmdinProfileData.slc_userprofiles[0].user_id
        console.log( this.company_Name, 'this.AmdinProfileData')
      })
  }
}
