import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';



@Component({
  selector: 'app-admin-status',
  templateUrl: './admin-status.component.html',
  styleUrls: ['./admin-status.component.scss']
})
export class AdminStatusComponent {
  AdminStatusData: any;
  @ViewChild('createAdminDialog') createAdminDialog!: TemplateRef<any>;
  @ViewChild('adminStatusUpdateDialog') adminStatusUpdateDialog!: TemplateRef<any>;

  @ViewChild('dialogContent') dialogContent!: ElementRef; // Reference to dialog content
  dialogRef!: MatDialogRef<any>;
  selectedStatus: any;
  adminId: any;

constructor(private server: ApiService, private dialog: MatDialog, private snackBar: MatSnackBar ) {
}

  ngOnInit() {
    this.AdminStatus();
  }

  adminStatus = [
    // { id: '1', adminStatus: 'Accepted', value: 'accepted' },
    { id: '2', adminStatus: 'Rejected', value: 'rejected' }
  ];
  
  
  AdminStatus() {
      let obj = {
        status: 'accepted',
        page: 1,
        size: 10   
       }
      this.server.getAdminStatus(obj).subscribe((res: any) => {
        console.log(res, 'res');
        this.AdminStatusData = res.result.data;
        console.log(this.AdminStatusData, 'adminnnnnnnn')
      });
    }

    openCreateAdminDialog() {
      this.dialogRef = this.dialog.open(this.createAdminDialog, {
        height: '360px', // or any desired fixed height
        width: '900px',  // Optional: set a fixed width
        disableClose: true // Ensures the dialog won't close automatically
      });
    
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogContent.nativeElement.scrollTop = 0; // Reset scroll to top
        console.log('Dialog closed with result:', result);
      });
    }

    createAdmin(form: any) {
      if (form.valid) {
        console.log(form.value, 'form values');  // This will log the form values
        let data = {
          firstName: form.value.firstName,
          lastName: form.value.lastName,
          email: form.value.email,
          confirmEmail: form.value.email,
          password: form.value.password,
          status: 'accepted'
        };
    
        console.log(data, 'dataaaaaaa');  // This will log the constructed data object
        this.server.createAdmin(data).subscribe((res: any) => {
          // this.spinner.hide();
          console.log(res, 'resss');
          if (res.statusCode == 200) {
            this.AdminStatus();
            this.snackBar.open(res.message, 'x', {
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
            // this.router.navigate(['/admin/signin']);
          } else if (res.statusCode == 400) {
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          } else if (res.statusCode == 300) {
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          }
        })
      }
    }
    
    // adminStatusUpdate(data: any, id: any) {
    // console.log(id,'iddddddddd')
    // }


    openAdminStatusUpdateDialog(id: any) {
      console.log(id, 'idddddddd');
      this.adminId = id
      this.dialogRef = this.dialog.open(this.adminStatusUpdateDialog, {
        height: '300px', // or any desired fixed height
        width: '500px',  // Optional: set a fixed width
        disableClose: true // Ensures the dialog won't close automatically
      });
    
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogContent.nativeElement.scrollTop = 0; // Reset scroll to top
        console.log('Dialog closed with result:', result);
      });
    }

    statusUpdateAdmin(form: any) {
      if (form.valid) {
        console.log(form.value, 'form valuesstatus'); 
      }
      let adminStatusUpdateObj = {
        admin_id: this.adminId,
        status: this.selectedStatus
      }
      console.log(adminStatusUpdateObj, 'adminStatusUpdateObj');
      this.server.updateAdminStatus(adminStatusUpdateObj).subscribe((res: any) => {
        // this.spinner.hide();
        console.log(res, 'resss');
        if (res.statusCode == 200) {
          this.AdminStatus();
          this.snackBar.open(res.message, 'x', {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else if (res.statusCode == 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else if (res.statusCode == 300) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      })
    }

    onSelectionChange(event: MatSelectChange) {
      this.selectedStatus = event.value;
      console.log('Selection changed:', this.selectedStatus);
    }


    }
    

  

