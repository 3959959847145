import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';


import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HeaderComponent } from './header/header.component';
import { Footer } from 'primeng/api';
import { FooterComponent } from './footer/footer.component';
import { RegistrationComponent } from './registration/registration.component';
import { CartManagementComponent } from './cart-management/cart-management.component';
import { QuoteManagementComponent } from './quote-management/quote-management.component';
import { PaymentComponent } from './payment/payment.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { HolidayCalendarComponent } from './admin/holiday-calendar/holiday-calendar.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminSignupComponent } from './admin/admin-signup/admin-signup.component';
import { AdminStatusComponent } from './admin/admin-status/admin-status.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'signin', component: LoginComponent, pathMatch: 'full' },
  { path: 'registration', component: RegistrationComponent, pathMatch: 'full' },
  { path: 'header', component: HeaderComponent, pathMatch: 'full' },
  { path: 'footer', component: FooterComponent, pathMatch: 'full' },
  { path: 'profile', component: ProfileComponent, pathMatch: 'full' },
  { path: 'cart', component: CartManagementComponent, pathMatch: 'full' },
  { path: 'quote', component: QuoteManagementComponent, pathMatch: 'full' },
  { path: 'signup', component: SignupComponent, pathMatch: 'full' },
  { path: 'shipping', component: PaymentComponent, pathMatch: 'full' },
  { path: 'password', component: ForgotPasswordComponent, pathMatch: 'full' },
  { path: 'payment-success', component: PaymentSuccessComponent, pathMatch: 'full' },
  { path: 'payment-fail', component: PaymentFailComponent, pathMatch: 'full' },
  { path: 'admin/dashboard', component: DashboardComponent, pathMatch: 'full' },
  { path: 'admin/profile', component: AdminProfileComponent, pathMatch: 'full' },
  { path: 'admin/calendar', component: HolidayCalendarComponent, pathMatch: 'full' },
  { path: 'admin/signin', component: AdminLoginComponent, pathMatch: 'full' },
  { path: 'admin/signup', component: AdminSignupComponent, pathMatch: 'full' },
  { path: 'admin/status', component: AdminStatusComponent, pathMatch: 'full' },


  { path: '**', redirectTo: '/signin', pathMatch: 'full' },


  


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
