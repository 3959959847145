// import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { SharedService } from '../cart-management/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';



@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  // constructor(private server: ApiService) { }

  // ngOnInit() {
   
  // }

  // data() {
  //   let data = {
  //     ids: '',
  //     email: localStorage.getItem('userEmail'),
  //   }
  //   this.server.getAddress(data).subscribe((res: any) => {
  //     console.log(res, 'ressssss');
  //   });

  // }

  quoteId: string[] = [];
  quoteIds: any;
  grandTotal: any;
  email: any;

  constructor(private sharedService: SharedService, private server: ApiService,  private router: Router,) { }

  ngOnInit() {
    this.fetchAddress();
    const paymentData = this.sharedService.getPaymentData();
    if (paymentData) {
      this.quoteIds = paymentData.quoteIds;
      this.grandTotal = paymentData.grandTotal;
      console.log(this.quoteIds, 'received selected cart ids');
      console.log(this.grandTotal, 'received grand total');
    }

  }

  fetchAddress1() {
    const usermail = localStorage.getItem('userEmail') || '';
    let quoteId = localStorage.getItem('quoteId');
    
    // Ensure quoteId is a string, not an array within a string
    if (quoteId && quoteId.startsWith('["') && quoteId.endsWith('"]')) {
        quoteId = JSON.parse(quoteId)[0]; // Extracting the string from the array
    }
    const data = {
        ids: quoteId ? [quoteId] : [],
        email: usermail
    };
    console.log(data, 'objffffffffffff');
    this.server.sendTeamMail(data).subscribe((res: any) => {
        console.log(res, 'sendTeamMail Response');
        this.router.navigate(['/quote']);
    });
    
}

fetchAddress() {
  const usermail = localStorage.getItem('userEmail') || '';
  const quoteIdsStr = localStorage.getItem('quoteId');

  let quoteIds = [];
  if (quoteIdsStr) {
    quoteIds = quoteIdsStr.split(",").map(id => id.trim());
    // Ensure quoteIds is an array of strings, not an array within a string
    if (quoteIdsStr.startsWith('["') && quoteIdsStr.endsWith('"]')) {
        quoteIds = JSON.parse(quoteIdsStr); // Parsing the array
    }
  }
  const data = {
    ids: quoteIds,
    email: usermail
  };
  console.log(data, 'objffffffffffff');
  this.server.sendTeamMail(data).subscribe((res: any) => {
    console.log(res, 'sendTeamMail Response');
    this.router.navigate(['/quote']);
  });
}


}


