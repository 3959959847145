import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedService } from './shared.service';


@Component({
  selector: 'app-cart-management',
  templateUrl: './cart-management.component.html',
  styleUrls: ['./cart-management.component.scss']
})
export class CartManagementComponent implements OnInit {
  getCartDataData: any;
  priceCalculation: any;
  selectedIds: string[] = []; // Array to store selected item IDs
  orderDetailsCalcution: any;
  // orderDetails: any;
  orderDetails: any = { grandTotal: 0 };  // Initialize with default value

  constructor(
    private server: ApiService,
    private router: Router,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService

  ) { }

  ngOnInit(): void {
    this.cartData();
  }

  cartData() {
    this.spinner.show();
    let obj = {
      user_id: localStorage.getItem('userId'),
    }
    console.log(obj, 'cal obj')
    this.server.cartManagementData(obj).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'ressssss');
      this.getCartDataData = res.result;
      console.log(this.getCartDataData, 'this.cart data');
      this.priceCalculation = this.getCartDataData[0]?.slc_price;
      console.log(this.priceCalculation, 'eeeeeee')
    })
  }


  goToHomeScreen() {
    this.router.navigate(['/home']);
  }

  // calculation(itemId: string) {
  //   const index = this.selectedIds.indexOf(itemId);
  //   if (index === -1) {
  //     this.selectedIds.push(itemId);
  //   } else {
  //     this.selectedIds.splice(index, 1);
  //   }
  //   console.log(this.selectedIds, 'selected IDs array');
  //   this.cartByIdsCalData();
  // }

  // cartByIdsCalData() {
  //   let params = new HttpParams().set('pcb_ids', JSON.stringify(this.selectedIds));
  //   console.log(this.selectedIds, 'cal obj id');
  //   this.server.cartByIdCalculationData(params).subscribe((res: any) => {
  //     console.log(res, 'ressssss');
  //     this.orderDetails = res.result;
  //     console.log(this.orderDetails, 'orderDetailsCalcution');
  //   });
  // }


  calculation(itemId: string) {
    const index = this.selectedIds.indexOf(itemId);
    if (index === -1) {
      this.selectedIds.push(itemId);
    } else {
      this.selectedIds.splice(index, 1);
    }
    console.log(this.selectedIds, 'selected IDs array');
    this.cartByIdsCalData();
  }

  cartByIdsCalData() {
    if (this.selectedIds.length === 0) {
      this.orderDetails.grandTotal = 0;
      this.orderDetails.items = [];  // Clear items if needed
      console.log(this.orderDetails.grandTotal, 'grand total set to 0 because no items are selected');
    } else {
      let params = new HttpParams().set('pcb_ids', JSON.stringify(this.selectedIds));
      console.log(this.selectedIds, 'cal obj id');
      this.server.cartByIdCalculationData(params).subscribe((res: any) => {
        console.log(res, 'response from cartByIdCalculationData');
        this.orderDetails = res.result;
        console.log(this.orderDetails, 'orderDetailsCalcution');
      });
    }
  }

 

  getGrandTotal(): number {
    return this.orderDetails?.grandTotal ?? 0;
  }



  cartPayment(item: any) {
    console.log(item.quote_ids, 'selected cart ids');
    const selectedCartIds = item.quote_ids.join(',');
    // const grandTotal = item.grandTotal
    // console.log(item.grandTotal, 'grand total');
    // Navigate to the shipping route with both quoteId and grandTotal

     // Store data in the shared service
     this.sharedService.setPaymentData({
      quoteIds: selectedCartIds,
    });


    this.router.navigate(['/shipping'], {
      queryParams: {
        quoteId: selectedCartIds,
        // grandTotal: grandTotal
      }
    });
  }

}
