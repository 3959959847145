import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../app/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) { }

    getuserdetails(data: any) {
        return this.http.post(`${environment.url}/userLogin`, data);
    }
    createuser(data: any) {
        return this.http.post(`${environment.url}/createuser`, data);
    }
    getallusers() {
        return this.http.get(`${environment.url}/getUsers`);
    }

    updateuser(data: any) {
        return this.http.put(`${environment.url}/users/update`, data);

    }
    deleteuser(data: any) {
        return this.http.post(`${environment.url}/users/delete`, data);
    }
    getuserdata(id: any) {
        return this.http.get(`${environment.url}/getuserbyid?id=${id}`);
    }

    //pcb
    getBorderLayer() {
        return this.http.get(`${environment.url}/quotedesign/getAllBoardLayer`);
    }

    createQuote(data: any) {
        return this.http.post(`${environment.url}/quotedesign/create_quote`, data);

    }

    createUser(data: any) {
        return this.http.post(`${environment.url}/userAuth/create_new_user`, data);

    }

    createAdmin(data: any) {
        return this.http.post(`${environment.url}/userAuth/admin_signup`, data);
    }

    loginUser(data: any) {
        return this.http.post(`${environment.url}/userAuth/user_login`, data);
    }

    adminLogin(data: any) {
        return this.http.post(`${environment.url}/userAuth/admin_login`, data);
    }

    PcbPriceCalculation(obj: any) {
        console.log(obj, 'server calllllllll')
        return this.http.get(`${environment.url}/quotedesign/pcbPriceCalculation?quantity=${obj.quantity}&width=${obj.width}&length=${obj.length}&boardLayer=${obj.boardLayer}&surfaceFinish=${obj.surfaceFinish}&pcbRows=${obj.pcbRows}&pcbColumns=${obj.pcbColumns}&pcb_quantity=${obj.pcb_quantity}&pcbType=${obj.pcbType}&type=${obj.type}&thickness=${obj.thickness}&finishedCopper=${obj.finishedCopper}&material=${obj.material}&il_copper=${obj.il_copper}&solderMask=${obj.solderMask}`);
    }

    updateCartStatusApi(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateCart`, data);
    }

    updateQuoteStatusApi(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateQutoteStatus`, data);
    }

    cartManagementData(obj: any) {
        console.log(obj, 'server cart');
        return this.http.get(`${environment.url}/quotedesign/getByCartStatus?user_id=${obj.user_id}`);
    }

    QuoteManagementData(obj: any) {
        console.log(obj, 'server cart');
        return this.http.get(`${environment.url}/quotedesign/getPcbByQuoteStatus?user_id=${obj.user_id}`);
    }

    // cartByIdCalculationData(obj: any) {
    //     console.log(obj, 'server cart by id');
    //     return this.http.get(`${environment.url}/quotedesign/accumulatedPriceCalculation?pcb_ids=${obj.pcb_ids}`);
    // }

    cartByIdCalculationData(params: HttpParams): Observable<any> {
        console.log(params, 'server cart by id');
        return this.http.get(`${environment.url}/quotedesign/accumulatedPriceCalculation`, { params });
      }

      Registration(data: any) {
        return this.http.post(`${environment.url}/userAuth/update_user`, data);
    }

    oneProfilre(obj: any) {
        console.log(obj, 'server oneProfilre');
        return this.http.get(`${environment.url}/userAuth/getoneuser?id=${obj.id}`);
    }

    getMultipleQuotesByIds(obj: any) {
        console.log(obj, 'server getMultipleQuotesByIds');
        return this.http.get(`${environment.url}/quotedesign/getMultipleQuotesByIds?quote_ids=${obj.quote_ids}`);
    }

    emailQuote(data: any) {
        return this.http.post(`${environment.url}/quotedesign/sendQuoteMail`, data);
    }

    getAddress(obj: any) {
        console.log(obj, 'server getAddress');
        return this.http.get(`${environment.url}/userAuth/getAllUserAddress?user_id=${obj.user_id}`);
    }

    updateAddress(data: any) {
        return this.http.post(`${environment.url}/userAuth/updateAddress`, data);

    }

    deleteAddress(data: any) {
        return this.http.delete(`${environment.url}/userAuth/deleteAddress?id=${data.id}`,);

    }

    emailVerification(obj: any) {
        console.log(obj, 'server getAddress');
        return this.http.get(`${environment.url}/userAuth/verifyemail?id=${obj.id}`);
    }

    forgotPassword(data: any) {
        return this.http.post(`${environment.url}/userAuth/forgot_password`, data);
    }

    updatePassword(data: any) {
        return this.http.post(`${environment.url}/userAuth/update_password`, data);
    }

    quotedesignSendQuoteMail(data: any) {
        return this.http.post(`${environment.url}/quotedesign/sendQuoteMail`, data);
    }

    getQuoteById(id : any) {
        return this.http.get(`${environment.url}/quotedesign/getQuoteById?quote_id=${id}`);
    }
  
    processPayment(data: any) {
        return this.http.post(`${environment.url}/payments/process_payment`, data);
    }

    processPaymentTokenTest(data: any) {
        return this.http.post(`https://test.ccavenue.com/transaction/transaction.do?command=${data.command}&encRequest=${data.encRequest}&access_code=${data.access_code}`, data);
    }

    // getPriceByUserPayments(data : any) {
    //     return this.http.get(`${environment.url}/quotedesign/getPriceByUser?quote_ids=${data.quote_ids}&user_id=${data.user_id}`);
    // }

    getPriceByUserPayments(data: { quote_ids: string[], user_id: string }): Observable<any> {
        // Construct query parameters string
        const params = `quote_ids=${JSON.stringify(data.quote_ids)}&user_id=${data.user_id}`;
        
        // Make the HTTP GET request
        return this.http.get(`${environment.url}/quotedesign/getPriceByUser?${params}`);
      }


    //   sendTeamMail(data: any) {
    //     return this.http.post(`${environment.url}/quotedesign/sendTeamMail`, data);
    // }

    sendTeamMail(data: any) {
        // Ensure that the ids field is sent as an array
        if (data.ids && !Array.isArray(data.ids)) {
            data.ids = [data.ids];
        }
        
        // Make the HTTP POST request
        return this.http.post(`${environment.url}/quotedesign/sendTeamMail`, data);
    }


    verificationLinkExpires(data: any) {
        return this.http.post(`${environment.url}/userAuth/verificationLinkExpires`, data);
    }

    getQuotesByPaymentStatus(obj: any) {
        console.log(obj, 'getQuotesByPaymentStatus');
        return this.http.get(`${environment.url}/quotedesign/getQuotesByPaymentStatus?user_id=${obj.user_id}&page=${obj.page}&size=${obj.size}&payment_status=${obj.payment_status}&startDate=${obj.startDate}&endDate=${obj.endDate}`);
    }

    getAddressFromPincode(data: any) {
        console.log(data, 'getAddressFromPincode');
        return this.http.get(`${environment.url}/userAuth/getAddressFromPincode?pincode=${data.pincode}`);
    }

    //admin
    getalluserForAdmin(page: number, size: number): Observable<any>  {
        // console.log(obj, 'getalluserForAdmin');
        return this.http.get(`${environment.url}/userAuth/getalluser?page=${page}&size=${size}`);
    }

    getallQuotesForAdmin(page: number, size: number): Observable<any>  {
        // console.log(obj, 'getalluserForAdmin');
        return this.http.get(`${environment.url}/quotedesign/getAllQuotes?page=${page}&size=${size}`);
    }

    getAllCompletedPaymentQuotes(page: number, size: number): Observable<any>  {
        // console.log(obj, 'getalluserForAdmin');
        return this.http.get(`${environment.url}/quotedesign/getAllCompletedPaymentQuotes?page=${page}&size=${size}`);
    }

    getPriceHistoryByDateRange(startDate: string, endDate: string, page: number, size: number): Observable<any>  {
        // console.log(obj, 'getalluserForAdmin');
        return this.http.get(`${environment.url}/quotedesign/getPriceHistoryByDateRange?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`);
    }

    getHolidayCalender(year: number): Observable<any> {
        return this.http.get(`${environment.url}/quotedesign/getHolidayCalender`, {
          params: new HttpParams().set('year', year.toString())
        });
      }

    getAllThicknessPrices() {
        return this.http.get(`${environment.url}/quotedesign/getAllThicknessPrices`);
    }

    updateThicknessPrice(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateThicknessPrice`, data);
    }

    getAllSurfacePrices() {
        return this.http.get(`${environment.url}/quotedesign/getAllSurfacePrices`);
    }

    updateSurfaceFinishPrice(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateSurfaceFinishPrice`, data);
    }

    getAllFinishedCopperPrices() {
        return this.http.get(`${environment.url}/quotedesign/getAllFinishedCopperPrices`);
    }

    updateFinishedCooperPrice(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateFinishedCooperPrice`, data);
    }

    getAllMaterialPrices() {
        return this.http.get(`${environment.url}/quotedesign/getAllMaterialPrices`);
    }

    updateMaterialPrice(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateMaterialPrice`, data);
    }

    getAllToolingCostPrices() {
        return this.http.get(`${environment.url}/quotedesign/getAllToolingCostPrices`);
    }

    updateToolingCostPrice(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateToolingCostPrice`, data);
    }

    updateOrderStatus(data: any) {
        return this.http.post(`${environment.url}/quotedesign/updateOrderStatus`, data);
    }

    uploadCalenderCsv(data: any) {
        return this.http.post(`${environment.url}/quotedesign/uploadCalenderCsv`, data);
    }

    getAdminStatus(obj: any) {
        console.log(obj, 'server status');
        return this.http.get(`${environment.url}/userAuth/getAdminsByStatus?status=${obj.status}&page=${obj.page}&size=${obj.size}`);
    }

    updateAdminStatus(data: any) {
        return this.http.post(`${environment.url}/userAuth/updateAdminStatus`, data);
    }

    
    
}