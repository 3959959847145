import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  private subscription: Subscription = new Subscription();
  userData: any;
  userId: any;
  showDiv1: boolean = true;
  userEmail: any;
  getEmail: any;
 

  constructor(
    // public api: ApiService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private server: ApiService,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,

  ) { }


  ngOnInit(): void {
    // this.loginForm = this.fb.group({
    //   email: ['', Validators.required],
    //   password: ['', Validators.required],
    //   rememberMe: [false]
    // });
  }



  showSnackBar(message: string, panelClass: string) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: [panelClass],
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  passwordFieldType: string = 'password';
  togglePasswordVisibility() {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }

  toggleDivs(value: any) {
    this.showDiv1 = !this.showDiv1;
    console.log(value, 'valllllllllllll');
    this.userEmail = value.email;
  }

  log() {
    let data = {
      email: this.userEmail
    }
    console.log(data, 'forgot password data')
    this.server.forgotPassword(data).subscribe((res: any) => {
      console.log(res, 'email resssss');
      this.getEmail = res.result.email;
      console.log(this.getEmail, 'getEmail getEmail');
      if (res.statusCode == 200) {

        // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/home'], { queryParams: { userId: this.userId } });
        // this.router.navigate(['/home']);

      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } 
    });
  }


  login(form: any) {
    this.spinner.show();
    console.log(form, 'formmm')
    let obj = {
      email: form.email,
      password: form.password
    }
    this.server.loginUser(obj).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resss');
      if (res.statusCode == 200) {
        this.userData = res.result;
        this.userId = this.userData.id;
     
        console.log(this.userId, 'userIddddd');
        console.log(this.userData, 'userDataaaaa');
        localStorage.setItem('userId', this.userData.id);
        console.log(localStorage.getItem('userId'));
        localStorage.setItem('userEmail', this.userData.email);
        console.log(localStorage.getItem('userEmail'));
        localStorage.setItem('accessToken', this.userData.accessToken);
        console.log(localStorage.getItem('accessToken'));
        localStorage.setItem('Role', 'User'); 
        // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/home'], { queryParams: { userId: this.userId } });
        this.router.navigate(['/home']);

      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }

    })

  }
}
