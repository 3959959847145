import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar'; 

@Component({
  selector: 'app-holiday-calendar',
  templateUrl: './holiday-calendar.component.html',
  styleUrls: ['./holiday-calendar.component.scss']
})
export class HolidayCalendarComponent implements OnInit {

  @ViewChild('fileInput') fileInput: any;
  selectedFile: File | null = null;
  holidaysByMonth: any = {};
  fileName: string = '';

  constructor(private server: ApiService, private snackBar: MatSnackBar) { }

  // ngOnInit() {
  //   this.HolidayCalender();
  // }



  // HolidayCalender() {
  //   const year = 2024
  //   this.server.getHolidayCalender(year).subscribe((res: any) => {
  //   console.log(res, 'ressssssss')
  //   })
  // }



  // constructor(private server: YourService) {}

  ngOnInit(): void {
    this.HolidayCalender();
  }

  HolidayCalender() {
    const year = 2024;
    this.server.getHolidayCalender(year).subscribe((res: any) => {
      this.holidaysByMonth = this.organizeHolidaysByMonth(res.result);
    });
  }

  organizeHolidaysByMonth(holidays: any[]): any {
    const months: any = {};
    holidays.forEach(holiday => {
      const date = new Date(holiday.date);
      const month = date.toLocaleString('default', { month: 'long' });

      if (!months[month]) {
        months[month] = [];
      }

      months[month].push({
        date: date.getDate(),
        day: holiday.day.trim(),
        description: holiday.holiday_description
      });
    });
    return months;
  }


  getMonthNames(): string[] {
    return [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }

  // Handle file selection
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.fileName = file.name; 
      console.log('Selected file:', file);
    }
    this.data();
  }

 data() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);  // Attach the selected file to the form data

      // Call the API to upload the file
      this.server.uploadCalenderCsv(formData).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.snackBar.open('File uploaded successfully!', 'Close', {
              duration: 3000,  // Snackbar will close automatically after 3 seconds
              panelClass: ['success-snackbar']  // Optional, for adding custom styles
            });
          }
          console.log(res, 'Response');
        },
        (err: any) => {
          console.error('Error:', err);
        }
      );
    } else {
      console.error('No file selected!');
    }
  }

}


