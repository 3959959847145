// import { Component } from '@angular/core';
import { Component, HostListener, signal, ViewChild, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';



interface Prices {
  [key: number]: number;
}


export interface Registrations {
  sno: number;
  name: string;
  mobilenumber: number;
  email: string;
  companyname: string;
  place: string;
  viewProfile: string;
}
export interface Quatations {
  sno: number;
  quotenumber: number;
  pcbname: string;
  orderdate: Date;

  deliverydate: Date;
  status: string;
  viewDetails: string;
}
export interface Orders {
  sno: number;
  orderid: string;
  orderdate: Date;
  // orderdetails: string;
  // customerdetails: string;
  trackid: string,
  trackurl: string,
  status: string;

}



export interface Price {
  category: string;
  subcategory: string;
  layertype: string;
  previousprice: any;
  changedprice: any;
  pricechangeddate: Date;
}
const ELEMENT_DATA: Registrations[] = [
  { sno: 1, name: 'Lahari', mobilenumber: 9848677542, email: 'lahari@gmail.com', companyname: 'Hashstack Solutions', place: 'VSP', viewProfile: 'view' },
  { sno: 2, name: 'Lahari', mobilenumber: 9848677542, email: 'lahari@gmail.com', companyname: 'Hashstack Solutions', place: 'VSP', viewProfile: 'view' },
  { sno: 3, name: 'Lahari', mobilenumber: 9848677542, email: 'lahari@gmail.com', companyname: 'Hashstack Solutions', place: 'VSP', viewProfile: 'view' },

];
const ELEMENT_DATA1: Quatations[] = [
  { sno: 1, quotenumber: 1001, pcbname: 'mmm', orderdate: new Date('2024-04-22'), deliverydate: new Date('2024-04-30'), status: 'paid', viewDetails: 'view' },
  { sno: 2, quotenumber: 1001, pcbname: 'mmm', orderdate: new Date('2024-04-22'), deliverydate: new Date('2024-04-30'), status: 'paid', viewDetails: 'view' },
  { sno: 3, quotenumber: 1001, pcbname: 'mmm', orderdate: new Date('2024-04-22'), deliverydate: new Date('2024-04-30'), status: 'paid', viewDetails: 'view' },

];
const ELEMENT_DATA2: Orders[] = [
  { sno: 1, orderid: 'SUL8346001', orderdate: new Date('2024-04-22'), trackid: 'ac123', trackurl: 'abb122', status: 'shipped' },
  { sno: 2, orderid: 'SUL8346001', orderdate: new Date('2024-04-22'), trackid: 'ac123', trackurl: 'abb122', status: 'Order received' },
  { sno: 3, orderid: 'SUL8346001', orderdate: new Date('2024-04-22'), trackid: 'ac123', trackurl: 'abb122', status: 'Order picked by courier' },
];
const ELEMENT_DATA3: Price[] = [
  { category: 'Thickness', subcategory: '0.8', layertype: '1 Layer', previousprice: '1000 ₹', changedprice: '1500 ₹', pricechangeddate: new Date('29-07-2024') },
  { category: 'Thickness', subcategory: '0.8', layertype: '1 Layer', previousprice: '1000 ₹', changedprice: '1500 ₹', pricechangeddate: new Date('29-07-2024') },

  { category: 'Thickness', subcategory: '0.8', layertype: '1 Layer', previousprice: '1000 ₹', changedprice: '1500 ₹', pricechangeddate: new Date('29-07-2024') },

];

interface Food {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  @ViewChild('dialogTemplateTrackId') dialogTemplateTrackId!: TemplateRef<any>;
  @ViewChild('dialogTemplateURL') dialogTemplateURL!: TemplateRef<any>;
  @ViewChild('registrationDataDialog') registrationDataDialog!: TemplateRef<any>;
  @ViewChild('quatationDataDialog') quatationDataDialog!: TemplateRef<any>;
  @ViewChild('OrderDetailsDataDialog') OrderDetailsDataDialog!: TemplateRef<any>;

  selectedValue: string = '';
  selectedCar: string = '';
  totalUsers = 0;
  pageSize = 10;
  currentPage = 0;
  // totalPages = 0

  totalUsersForQuotes = 0;
  QuotepageSize = 10;
  QuotecurrentPage = 0;

  totalPriceHistoryRecords = 0;
  priceHistoryPageSize = 2;
  priceHistoryCurrentPage = 0;

  // completedOrderDetails: any[] = [];
  paginatedOrderDetails: any;
  totalUsersForOrder = 0;
  OrderpageSize = 10;
  OrdercurrentPage = 0;




  url = "../../assets/images/profile-Pcl.svg";
  startDate: Date | null = null;
  endDate: Date | null = null;
  currentDateTime: string = '';
  private timer: any;
  selectedItem: string = 'dashboard';
  iddss: any;
  trackid: any;
  OrStatus: string = 'order-received';
  statusOptions = [
    { canName: 'sateesh', value: 'sateesh', id: 1 },
    { canName: 'naveen', value: 'naveen', id: 2 },
    { canName: 'rajesh', value: 'rajesh', id: 3 },
    { canName: 'vijay', value: 'vijay', id: 4 },
  ];
  trackurl: any;
  RegisterData: any;
  quotationsData: any;
  completedOrderDetails: any;
  trackingId: any;
  trackingUrl: any;
  OrderDetailsAtPopup: any;
  QuotePopupDetails: any;
  AmdinProfileData: any;
  name: any;
  userId: any;
  registerUserId: any;
  RegistrationUserDetailsByIdData: any;
  updateStatus(event: any) {
    const selectedCanIds = event.value?.map((can: any) => can.id);
    console.log('Selected Candidate IDs:', selectedCanIds);
  }

  foods: Food[] = [
    { value: 'order-received', viewValue: 'In Progress' },
    { value: 'order-shipped', viewValue: 'Shipped' },
    { value: 'order-delivered', viewValue: 'Delivered' },
  ];

  onStatusChange(event: any) {
    console.log('Selected:', event.value); // Logs the selected value
    this.OrStatus = event.value; // Update the selected value to OrStatus
    console.log('Selected status:', this.OrStatus); // Logs the selected value
  }

  selectItem(item: string) {
    this.selectedItem = item;
  }

  layers = [1, 2, 4, 6];
  isMobile!: boolean;

  constructor(private server: ApiService, private spinner: NgxSpinnerService, private dialog: MatDialog) {
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 600;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit() {
    this.AdminById();
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setMonth(this.startDate.getMonth() - 1);

    this.noOfRegistrations(1, this.pageSize);
    this.noOfQuotes(1, this.pageSize);
    // this.AllCompletedPayments(1, this.pageSize)
    this.PriceHistoryByDateRange(this.currentPage, this.pageSize, this.formatDate(this.startDate), this.formatDate(this.endDate));
    this.searchPriceHistory();
    this.updateDateTime();
    this.startTimer(); // Start the timer to update time every second
    const element = { status: 'order-shipped' }; // Replace with your actual data
    this.updateStatus(element);
    console.log('Initial OrStatus:', this.OrStatus);
    console.log('Available statusOptions:', this.statusOptions);
    // Fetch data when the component loads
    //  this.AllCompletedPayments(this.OrdercurrentPage, this.OrderpageSize);
    this.AllCompletedPayments(this.OrdercurrentPage + 1, this.OrderpageSize);

  }

  // readonly panelOpenState = signal(false);
  displayedColumns: string[] = ['sno', 'name', 'mobilenumber', 'email', 'companyname', 'place', 'viewProfile'];
  displayedColumns1: string[] = ['sno', 'quotenumber', 'pcbname', 'orderdate', 'deliverydate', 'status', 'viewDetails'];
  displayedColumns2: string[] = ['sno', 'orderid', 'orderdate', 'trackid', 'trackurl', 'status'];
  displayedColumns3: string[] = ['sno', 'category', 'subcategory', 'layertype', 'previousprice', 'changedprice', 'pricechangeddate'];

  dataSource = new MatTableDataSource<Registrations>(ELEMENT_DATA);
  dataSourceQuotations = new MatTableDataSource<Quatations>(ELEMENT_DATA1);
  dataSourceOrders = new MatTableDataSource<Orders>(ELEMENT_DATA2);
  dataSourcePrice = new MatTableDataSource<Price>(ELEMENT_DATA3);
  @ViewChild('paginator1', { static: true }) paginator1!: MatPaginator;
  @ViewChild('paginator2', { static: true }) paginator2!: MatPaginator;
  @ViewChild('paginator3', { static: true }) paginator3!: MatPaginator;
  @ViewChild('paginator4', { static: true }) paginator4!: MatPaginator;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator1;
    this.dataSourceQuotations.paginator = this.paginator2;
    this.dataSourceOrders.paginator = this.paginator3;
    this.dataSourcePrice.paginator = this.paginator4;
  }

  noOfRegistrations(page: number, size: number) {
    this.spinner.show();
    let data = {
      page: page,
      size: size
    }
    console.log(data, 'getalluserForAdmin obj');
    this.server.getalluserForAdmin(page, size).subscribe((res: any) => {
      console.log(res, 'getalluserForAdmin res');
      this.spinner.hide();
      if (res.statusCode === 200 && res.status === 'SUCCESS') {
        this.RegisterData = res.result.data;
        console.log(this.RegisterData, 'RegisterData')
        this.totalUsers = res.result.pagination.totalUsers;
        this.pageSize = parseInt(res.result.pagination.pageSize, 10);
        console.log(this.pageSize, 'pagesizeee')
        this.currentPage = res.result.pagination.currentPage - 1;
        this.paginator.pageIndex = this.currentPage;
      }
    })

  }

  onPageChange(event: any) {
    this.noOfRegistrations(event.pageIndex + 1, event.pageSize);
  }

  calculateSno(index: number): number {
    console.log('Page Size:', this.pageSize);
    console.log('Current Page:', this.currentPage);
    return this.currentPage * this.pageSize + index + 1;
  }

  noOfQuotes(page: number, size: number) {
    this.server.getallQuotesForAdmin(page, size).subscribe((res: any) => {
      if (res.statusCode === 200 && res.status === 'SUCCESS') {
        this.quotationsData = res.result.data;
        console.log(this.quotationsData, 'quotationsData')
        this.totalUsersForQuotes = res.result.pagination.totalQuotes;
        this.QuotepageSize = size;
        this.QuotecurrentPage = page - 1;
      }
    });
  }

  onPageChangeQuote(event: any) {
    this.noOfQuotes(event.pageIndex + 1, event.pageSize);
  }

  quotecalculateSno(index: number): number {
    console.log('QuotepageSize Size:', this.QuotepageSize);
    console.log('QuotecurrentPage Page:', this.QuotecurrentPage);
    return this.QuotecurrentPage * this.QuotepageSize + index + 1;
  }

  AllCompletedPayments(page: number, size: number) {
    this.server.getAllCompletedPaymentQuotes(page, size).subscribe((res: any) => {
      if (res.statusCode === 200 && res.status === 'SUCCESS') {
        this.completedOrderDetails = res.result.data;
        this.totalUsersForOrder = res.result.pagination.totalQuotes; 
        this.OrderpageSize = size;
        this.OrdercurrentPage = page - 1;
      }
    });
  }


  onPageChangeOrder(event: any) {
    this.OrdercurrentPage = event.pageIndex; // Keep this zero-based
    this.AllCompletedPayments(this.OrdercurrentPage + 1, event.pageSize); // Page index + 1 when sending to API
  }


  orderCalculateSno(index: number): number {
    return this.OrdercurrentPage * this.OrderpageSize + index + 1;
  }


  PriceHistoryByDateRange(page: number, size: number, startDate: string, endDate: string) {
    console.log('Fetching price history for:', startDate, 'to', endDate);
    this.server.getPriceHistoryByDateRange(startDate, endDate, page, size).subscribe((res: any) => {
      if (res.statusCode === 200 && res.status === 'SUCCESS') {
        const startSno = (page - 1) * size + 1;
        const formattedData2 = res.result.data.map((item: any, index: number) => ({
          category: item.category,
          subcategory: item.subCategory, // Replace 'id' with actual field name if different
          layertype: item.layerType, // Replace 'date' with actual field name
          previousprice: item.previousPrice, // Replace 'price' with actual field name
          changedprice: item.changedPrice, // Replace with the actual field name for status
          pricechangeddate: item.priceChangeDate, // Replace with the actual field name for status
        }));
        // Set the data to your component variable (assumed as dataSourcePriceHistory)
        this.dataSourcePrice = formattedData2;
        console.log(this.dataSourcePrice, 'dataSourcePrice');
        // Handle pagination
        const pagination = res.result.pagination;
        this.totalPriceHistoryRecords = res.result.pagination.totalItems; // Set total records
        this.priceHistoryPageSize = pagination.pageSize; // Set page size
        this.priceHistoryCurrentPage = pagination.currentPage; // Set current page
      }
    });
  }

  onPageChangePriceHistory(event: any) {
    // event.pageIndex is 0-based, so add 1 for 1-based API call
    const page = event.pageIndex + 1;
    const size = event.pageSize;
    // Ensure startDate and endDate are formatted correctly
    const formattedStartDate = this.startDate ? this.formatDate(this.startDate) : '';
    const formattedEndDate = this.endDate ? this.formatDate(this.endDate) : '';
    // Call the API with current page, size, and selected dates
    this.PriceHistoryByDateRange(page, size, formattedStartDate, formattedEndDate);
  }

  // Helper function to format date as needed (e.g., 'YYYY-MM-DD')
  formatDate(date: Date): string {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();
    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  }

  // Method to handle the search button click
  searchPriceHistory() {
    if (this.startDate && this.endDate) {
      // Format dates as needed
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedEndDate = this.formatDate(this.endDate);
      // Call your API method with the formatted dates
      this.PriceHistoryByDateRange(1, this.pageSize, formattedStartDate, formattedEndDate);
    } else {
      console.error('Please select both start and end dates');
    }
  }

  priceHistorycalculateSno(index: number): number {
    return (this.priceHistoryCurrentPage - 1) * this.priceHistoryPageSize + index + 1;
  }

  ngOnDestroy() {
    this.stopTimer(); // Clear the timer when the component is destroyed
  }

  updateDateTime() {
    const now = new Date();
    this.currentDateTime = this.formatDateTime(now);
  }

  // Helper method to format date and time as 'DD-MM-YYYY : HH:MMAM/PM'
  formatDateTime(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    // const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24hr to 12hr format
    return `${day}-${month}-${year} : ${formattedHours}:${minutes}:${ampm}`;
  }

  // Method to start the timer
  startTimer() {
    this.timer = setInterval(() => {
      this.updateDateTime(); // Update the time every second
    }, 1000); // 1000 milliseconds = 1 second
  }

  // Method to stop the timer
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  openFilePicker() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onSelect(e: any) {
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
        console.log('Selected File', this.url);
      }
    }
  }

  updateOrderStatus() {
    let data = {
      quote_id: this.iddss,
      order_status: this.OrStatus,
      track_id: this.trackid,
      track_url: this.trackurl
    };

    console.log(data, 'data obj');
    this.server.updateOrderStatus(data).subscribe(
      (res: any) => {
        console.log(res, 'dataaaaaa');

        // Call AllCompletedPayments after successful update
        this.AllCompletedPayments(1, 10);  // Pass the appropriate page and size here
        let data = {
          id: this.iddss,
          email: localStorage.getItem('userEmail')
        }
        this.server.OrderStatusMail(data).subscribe((res: any) => {
          console.log(res, 'dataaaaaa');
        })
      },
      (err: any) => {
        console.error('Error updating order status:', err);
      }
    );
  }

  updateOrderStatusTrackId() {
    let data = {
      quote_id: this.iddss,
      order_status: this.OrStatus,
      track_id: this.trackid,
      track_url: this.trackurl
    };

    console.log(data, 'data obj');
    this.server.updateOrderStatus(data).subscribe(
      (res: any) => {
        console.log(res, 'dataaaaaa');
        // Call AllCompletedPayments after successful update
        this.AllCompletedPayments(1, 10);  // Pass the appropriate page and size here
      },
      (err: any) => {
        console.error('Error updating order status:', err);
      }
    );
  }

  updateOrderStatusTrackUrl() {
    let data = {
      quote_id: this.iddss,
      order_status: this.OrStatus,
      track_id: this.trackid,
      track_url: this.trackurl
    };

    console.log(data, 'data obj');
    this.server.updateOrderStatus(data).subscribe(
      (res: any) => {
        console.log(res, 'dataaaaaa');

        // Call AllCompletedPayments after successful update
        this.AllCompletedPayments(1, 10);  // Pass the appropriate page and size here
      },
      (err: any) => {
        console.error('Error updating order status:', err);
      }
    );
  }

  formatStatus(status: string): string {
    return status?.replace(/-/g, ' ');
  }

  rowData(item: any) {
    console.log(item, 'elee');
    this.iddss = item.quote_id;
    this.trackid = item.track_id;
    this.trackurl = item.track_url;
    this.OrStatus = item.order_status
    console.log(this.OrStatus, 'OrStatus')
    // Check if order status is 'inprogress' and either track_id or track_url is empty
    if (this.OrStatus === 'order-received' && (!this.trackid || !this.trackurl)) {
      alert('Please add Tracking ID and Tracking URL');
      return; // Exit the function if conditions are met
    }

    const dialogRef = this.dialog.open(this.dialogTemplate, {
      data: { id: this.iddss }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }

  rowData1(item: any) {
    console.log(item, 'elee');
    this.iddss = item.quote_id;
    this.trackid = item.track_id;
    this.trackurl = item.track_url;
    this.OrStatus = item.order_status
    const dialogRef = this.dialog.open(this.dialogTemplateTrackId, {
      data: { id: this.iddss }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }

  rowData2(item: any) {
    console.log(item, 'elee');
    this.iddss = item.quote_id;
    this.trackid = item.track_id;
    this.trackurl = item.track_url;
    this.OrStatus = item.order_status

    const dialogRef = this.dialog.open(this.dialogTemplateURL, {
      data: { id: this.iddss }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }


  registrationDetails(item: any) {
    console.log(item, 'iiiiiii');
    this.registerUserId = item.id;
    console.log(this.registerUserId, 'registeriddd');

    setTimeout(() => {
      this.getRegistrationUserDetailsById();
    }, 1000)
  }

  getRegistrationUserDetailsById() {
    let data = {
      id: this.registerUserId
    };
    this.server.oneProfilre(data).subscribe((res: any) => {
      console.log(res, 'dataaaaaa');
      this.RegistrationUserDetailsByIdData = res.result;
      console.log(this.RegistrationUserDetailsByIdData, 'this.RegistrationUserDetailsByIdData');

      // Open dialog after data is fetched
      this.openDialog();
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(this.registrationDataDialog, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }

  QtatationsDetails(item: any) {
    console.log(item, 'iiiiiii');
    this.QuotePopupDetails = item
    const dialogRef = this.dialog.open(this.quatationDataDialog, {
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }


  orderDetailsData1(item: any) {
    console.log(item, 'iiiiiii')
    this.OrderDetailsAtPopup = item
    console.log('OrderDetailsAtPopup:', this.OrderDetailsAtPopup);
    const dialogRef = this.dialog.open(this.OrderDetailsDataDialog);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  orderDetailsData(item: any) {
    console.log(item, 'iiiiiii')
    this.OrderDetailsAtPopup = item
    const dialogRef = this.dialog.open(this.OrderDetailsDataDialog, {
      width: '700px'  // Set the width of the dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }


  AdminById() {
    let data = {
      admin_id: localStorage.getItem('userId')
    }
    this.server.getAdminById(data).subscribe((res: any) => {
      console.log(res, 'dataaaaaa');
      this.AmdinProfileData = res.result;
      this.name = this.AmdinProfileData.firstName
      // this.email = this.AmdinProfileData.email
      // this.mobilenumber = this.AmdinProfileData.slc_userprofiles[0].mobileNO
      // this.company_Name = this.AmdinProfileData.slc_userprofiles[0].companyName
      this.userId = this.AmdinProfileData.id
      console.log(this.AmdinProfileData, 'eeeeeeeeee')
    })
  }

  formatStatusOrders(orderStatus: string): string {
    let formattedStatus = orderStatus?.replace(/-/g, ' ');
    if (orderStatus === 'order-received') {
      return 'In Progress';
    } else if (orderStatus === 'order-delivered') {
      return 'Delivered';
    } else if (orderStatus === 'order-shipped') {
      return 'Shipped';
    } else if (orderStatus === 'paid') {
      return 'Paid';
    } else if (orderStatus === 'pending') {
      return 'Pending';
    }
    return formattedStatus;
  }


  ddd() {
    let data = {
      id: 1,
      email: 'ss@gmail.com'
    };
    this.server.OrderStatusMail(data).subscribe((res: any) => {
      console.log(res, 'dataaaaaa');
    })
  }


  downloadFile(fileUrl: string | undefined) {
    if (fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileUrl.split('/').pop() ?? 'default-file-name'; // Fallback if filename is not available
      link.click();
    } else {
      console.error('File URL is undefined.');
    }
  }



  getFileExtension(filename: string | undefined): string {
    if (!filename) {
      return ''; // Return empty string if filename is undefined or empty
    }
    return filename.split('.').pop() ?? ''; // Return empty string if no extension is found
  }


}



