import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  isSelected = 'string'
  // isSelected1 = 'string'
  selectedShippingAddress: any;
  selectedBillingAddress: any;
  AddressData: any[] = [];
  BillingAddressData: any;
  defaultShippingAddressIndex: number = 0; // Index of the default shipping address
  // selectedAddress: any; // Property to hold the selected address
  // selectedAddress: string = '';
  selectedAddress: any = {};
  shippingAddress: any = {};
  isShippingAddressChecked: boolean = false;
  isBillingAddressChecked: boolean = false;
  paymentScreen: boolean = true;
  isSelected1: boolean = false; // Track the selected state
  showPayment: boolean = true;
  // isSelectedShipping: boolean = false;
  // isSelectedBilling: boolean = false;
  QuoteDetails: any;
  quoteIds: any;
  OrderByIdDetails: any;
  userDetails: any;
  paymentFormHtml: string = '';
  paymentToken: any;
  encRequest: any;
  access_code: any;
  grandTotalAmount: any;
  defaultShippingAddress: any;
  defaultBillingAddress: any;
  // quoteId: any;
  quoteId: string[] = [];
  // selectedOrderId: any;
 selectedOrderId: string = '';
 pincodeError: boolean = false;

 pincode: string = '';
  state: string = '';
  country: string = '';
  street: string = '';
  city: string = '';
  state_district: string = ''


 
    // Column names for the mat-table
    displayedColumns: string[] = [
      'sno',   
      'pcbName', 
      'quote_id', 
      'pcbType', 
      'quantity', 
      'order_type', 
      'order_date',
      'dimensions',
      'layers',
      'material',
      'thickness',
      // 'surfaceFinish',
      // 'solderMask',
      // 'legend',
      // 'gerberFile'
    ];
  
    // Sample data for QuoteDetails (you can fetch or bind your API response here)
    QuoteDetails11 = [
      {
        pcbName: 'PCB 1',
        quote_id: '0659',
        pcbType: 'FR4',
        quantity: 300,
        order_type: 'Single Piece',
        order_date: new Date(),
        length: 10,
        width: 20,
        boardLayer: 2,
        material: 'FR-4 Tg 130-140',
        thickness: 1.6,
        // surfaceFinish: 'HASL Lead',
        // solderMask: 'Green',
        // legend: 'White',
        // gerberFile: 'path/to/gerberFile1'
      },

      {
        pcbName: 'PCB 11',
        quote_id: '0659',
        pcbType: 'FR4',
        quantity: 300,
        order_type: 'Single Piece',
        order_date: new Date(),
        length: 10,
        width: 20,
        boardLayer: 2,
        material: 'FR-4 Tg 130-140',
        thickness: 1.6,
        surfaceFinish: 'HASL Lead',
        solderMask: 'Green',
        legend: 'White',
        gerberFile: 'path/to/gerberFile1'
      }
      // Add more items as needed
    ];
  
  

  constructor(
    private dialog: MatDialog,
    private server: ApiService,
    private snackBar: MatSnackBar,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
    private _Router: Router,
    private route: ActivatedRoute,
    private http: HttpClient


  ) {
    this.selectedAddress = this.AddressData[this.defaultShippingAddressIndex];
    this.shippingAddress = this.AddressData[this.defaultShippingAddressIndex];

    // this.route.params.subscribe(params => {
    //   this.quoteId = params['quoteId'];
    //   console.log(this.quoteId, 'quote id Address screen');
    // });

    // this.route.queryParams.subscribe(params => {
    //   const quoteIdsStr = params['quoteId'];
    //   this.quoteId = quoteIdsStr ? quoteIdsStr.split(',') : [];
    //   console.log(this.quoteId, 'received quote ids');
    //   const ids = params['quoteId'];
    //   console.log(ids, 'idssssss')
    //   this.selectedOrderId = ids.replace(/,/g, '');
    //   console.log(this.selectedOrderId, 'idsssswww')
    //   this.grandTotalAmount = +params['grandTotal'];  // Convert to number if necessary
    //   console.log(this.grandTotalAmount, 'received grand total');
    // });

    // this.route.queryParams.subscribe(params => {
    //   const quoteIdsStr = params['quoteId'];
    //   this.quoteId = quoteIdsStr ? quoteIdsStr.split(',').map(id => id.trim()) : [];
    //   console.log(this.quoteId, 'received quote ids');
  
    //   // Convert to string without commas for selectedOrderId
    //   this.selectedOrderId = this.quoteId.join('');
    //   console.log(this.selectedOrderId, 'idsssswww');
  
    //   // Example usage: making an API call
    // this.getPriceByUserData();
    // });
  
  }


  ngOnInit() {
    this.paymentAddress();
    this.route.queryParams.subscribe(params => {
      const quoteIdsStr = params['quoteId'];
      this.quoteId = quoteIdsStr ? quoteIdsStr.split(",").map((id: string) => id.trim()) : [];
  //     this.quoteId = quoteIdsStr ? 
  // quoteIdsStr.split(",")
  //   .map((id: string) => id.trim())
  //   .filter((id: string) => id.length <= 2 && /^\d+$/.test(id)) : [];
      console.log(this.quoteId, 'received quote ids');

 // Store quoteId in localStorage
      localStorage.setItem('quoteId', JSON.stringify(this.quoteId));

      // this.selectedOrderId = this.quoteId.join('');
      this.selectedOrderId = this.quoteId.join('').slice(0, 6);
      console.log(this.selectedOrderId, 'idsssswww');
      this.getPriceByUserData();
    });
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '550px',
      height: '300px',
      backdropClass: "popupbgblur",
      autoFocus: false
    });

  }


  openDialogWithTemplateRefAddAddress(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '700px',
      height: '300px',
      backdropClass: "popupbgblur",
      autoFocus: false
    });

  }

  paymentAddress() {
    let data = {
      user_id: localStorage.getItem('userId'),
    };
    this.server.getAddress(data).subscribe((res: any) => {
      console.log(res, 'ressssss');
      this.AddressData = res.result;
      this.BillingAddressData = res.result;
      console.log(this.BillingAddressData, 'getBillingAddressData');

        // Filter the AddressData array to include only items where shipping_address is true
    this.defaultShippingAddress = this.AddressData.filter((address: any) => address.shipping_address === true);
    console.log(this.defaultShippingAddress, 'defaultShippingAddress');

    // Filter the AddressData array to include only items where billing_address is true
    this.defaultBillingAddress = this.BillingAddressData.filter((address: any) => address.billing_address === true);
    console.log(this.defaultBillingAddress, 'defaultBillingAddress');
      
    });
  }



  SaveAddress(form: any) {
    this.spinner.show();
    let data = {
      user_id: localStorage.getItem('userId'),
      address_id: '',
      country: form.country,
      Address: form.Address,
      pincode: form.pincode,
      city: form.city,
      district: form.district,
      state: form.state,
      billing_address: this.isBillingAddressChecked,
      shipping_address: this.isShippingAddressChecked
    }
    console.log(data, 'add address obj');
    this.server.Registration(data).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'res ass address');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.state = '',
        this.country = '',
        this.street = '',
        this.city = '',
        this.state_district = ''
        // this.router.navigate(['/home']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      this.paymentAddress();
    });

  }

  payment() {
    console.log('eeeeeee')
    this.paymentScreen = !this.paymentScreen;
    // this.QuoteByIdData();
    this.paymentUserDetails();
    this.MultipleQuotesByIds();
  }

  back() {
    this.paymentScreen = !this.paymentScreen;
  }

  // toggleShippingAddress(item: any) {
  //   console.log('addresssssss11')
  //   // Logic to toggle the billing address
  //   if (item.shipping_address) {
  //    this.shippingAddress = item,
  //    console.log(this.shippingAddress, 'addresssssss')
  //  } else {
  //    this.shippingAddress = '';
  //  }
  //   item.shipping_address = !item.shipping_address;
  //   this.addressUpdate(item);
  // }

  toggleShippingAddress(item: any) {
    console.log('addresssssss11');

    // Logic to toggle the shipping address
    if (item.shipping_address) {
      this.shippingAddress = item;
      console.log(this.shippingAddress, 'addresssssss');
    } else {
      this.shippingAddress = '';
    }
    item.shipping_address = !item.shipping_address;

    // Toggle the value of shipping_address

    // Update the address after the toggle
    this.addressUpdate(item);
    this.paymentAddress();
  }


  addressUpdate(item: any) {
    let obj = {
      id: item.id,
      user_id: localStorage.getItem('userId'),
      shipping_address: true
    }
    console.log(obj, 'obj update address');
    this.server.updateAddress(obj).subscribe((res: any) => {
    });
    this.paymentAddress();
  }


  toggleBillingAddress(item: any) {
    console.log('addresssssss11')
    // Logic to toggle the billing address
    if (item.billing_address) {
      this.selectedAddress = item,
        console.log(this.selectedAddress, 'addresssssss')
    } else {
      this.selectedAddress = '';
    }

    item.billing_address = !item.billing_address;
    this.addressUpdateBilling(item);
    this.paymentAddress();

  }

  addressUpdateBilling(item: any) {
    let obj = {
      id: item.id,
      user_id: localStorage.getItem('userId'),
      billing_address: true,
    }
    console.log(obj, 'obj update address');
    this.server.updateAddress(obj).subscribe((res: any) => {
    });
    this.paymentAddress();
  }


  handleCheckboxChange(event: any) {
    if (event.target.id === 'shippingCheckbox') {
      this.isShippingAddressChecked = event.target.checked;
      console.log('Shipping Address Checked:', this.isShippingAddressChecked);
    } else if (event.target.id === 'billingCheckbox') {
      this.isBillingAddressChecked = event.target.checked;
      console.log('Billing Address Checked:', this.isBillingAddressChecked);
    }
  }

  onRadioChange() {
    this.isSelected1 = !this.isSelected1;
  }

  openDialogWithTemplateRef1(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '650px',
      height: '300px',
      backdropClass: "popupbgblur",
      autoFocus: false
    });
  }

  creditCard(value: any) {
    console.log(value, 'valaaa')
  }

  QuoteByIdData() {
    this.server.getQuoteById(this.quoteId).subscribe((res: any) => {
      console.log(res, 'ressssssssss');
      this.OrderByIdDetails = res.result;
      console.log(this.OrderByIdDetails, 'OrderByIdDetails');
    });

  }

  paymentUserDetails() {
    let obj = {
      id: localStorage.getItem('userId'),
    }
    this.server.oneProfilre(obj).subscribe((res: any) => {
      console.log(res, 'ressssssssss');
      this.userDetails = res.result;
      console.log(this.userDetails, 'OrderByIdDetails');
    });
  }

  MultipleQuotesByIds() {
    let obj = {
      quote_ids: localStorage.getItem('quoteId'),
    }
    // console.log(obj, 'objgg')
    this.server.getMultipleQuotesByIds(obj).subscribe((res: any) => {
      console.log(res, 'res getMultipleQuotesByIds');
      this.QuoteDetails = res.result;
      console.log(this.QuoteDetails, 'QuoteDetails');
    });
  }

  paymentProcessData(form: any) {
    let obj = {
      quote_ids: this.quoteId,
      order_id: this.selectedOrderId,
      amount: this.grandTotalAmount,
      user_id: localStorage.getItem('userId'),
      billing_name: this.userDetails.firstName,
      billing_address: this.selectedAddress?.Address || this.defaultBillingAddress[0]?.Address || '',
      billing_phone: this.selectedAddress?.mobileNO || this.defaultBillingAddress[0]?.mobileNO || '9989022722',
      billing_country: this.selectedAddress?.country || this.defaultBillingAddress[0]?.country || '',
      billing_zip: this.selectedAddress?.pincode || this.defaultBillingAddress[0]?.pincode || '',
      billing_city: this.selectedAddress?.district || this.defaultBillingAddress[0]?.district || '',
      billing_state: this.selectedAddress?.state || this.defaultBillingAddress[0]?.state || '',
      billing_email: this.selectedAddress?.email || this.defaultBillingAddress[0]?.email || '',
      shipping_address: this.shippingAddress?.Address || this.defaultShippingAddress[0]?.Address || '',
      shipping_name: this.userDetails.firstName,
      shipping_email: this.shippingAddress?.email || this.defaultShippingAddress[0]?.email || '',
      shipping_phone: this.shippingAddress?.mobileNO || this.defaultShippingAddress[0]?.mobileNO || '9989022721',
      shipping_country: this.shippingAddress?.country || this.defaultShippingAddress[0]?.country || '',
      shipping_zip: this.shippingAddress?.pincode || this.defaultShippingAddress[0]?.pincode || '',
      shipping_city: this.shippingAddress?.district || this.defaultShippingAddress[0]?.district || '',
      shipping_state: this.shippingAddress?.state || this.defaultShippingAddress[0]?.state || '',
    }
    console.log(obj, 'objjjjj payment');
    this.server.processPayment(obj).subscribe((res: any) => {
      // console.log(res, 'res processPayment');
      if (res.statusCode == 200) {
        this.paymentToken = res.result;
        console.log(this.paymentToken, 'paymentToken');
        this.encRequest = res.result.encRequest;
        console.log(this.encRequest, 'encRequest');
        this.access_code = res.result.accessCode;
        console.log(this.access_code, 'access_code');
        this.paymentTokenss();
        this.snackBar.open(res.message, 'x', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
  }


  // paymentTokenss() {
  //   let data = {
  //     command: 'initiateTransaction',
  //     encRequest: this.encRequest,
  //     access_code: this.access_code
  //   }
  //   console.log(data, 'objjjjj token');
  //   this.server.processPaymentTokenTest(data).subscribe((res: any) => {
  //     console.log(res, 'processPaymentTokenTest res');
  //   });
  // }


  paymentTokenss() {
    let data = {
      command: 'initiateTransaction',
      encRequest: this.encRequest,
      access_code: this.access_code
    };

    const url = `https://test.ccavenue.com/transaction/transaction.do?command=${data.command}&encRequest=${data.encRequest}&access_code=${data.access_code}`;
    console.log(data, 'objjjjj token');
    console.log(url, 'Constructed URL');

    // Redirect the browser to the constructed URL
    window.location.href = url;
  }

  getPriceByUserData() {
    const userId = localStorage.getItem('userId') || ''; // Ensure user_id is always a string
    let data = {
      quote_ids: this.quoteId,
      user_id: userId
    };
    console.log(data, 'obj paymeny money');
    this.server.getPriceByUserPayments(data).subscribe((res: any) => {
      console.log(res, 'getPriceByUserPayments res');
      this.grandTotalAmount = res.result.totalPrice;
      console.log(this.grandTotalAmount, 'grandTotalAmount1');
    });
  }



  // onPincodeChange(event: any) {
  //   const pincodeStr = event ? event.toString() : '';
  //   console.log(pincodeStr, 'pincode event received');
  //   if (pincodeStr.length === 6) {
  //     this.pincode = pincodeStr;
  //     console.log('Pincode length is 6, calling AddressFromPincode');
  //     this.PincodeAddressAtPayment();
  //   } else {
  //     console.log('Pincode is not 6 digits, current length:', pincodeStr.length);
  //   }
  // }


onPincodeChange(event: any) {
  const pincodeStr = event ? event.toString().replace(/\D/g, '') : '';  // Allow only digits
  console.log(pincodeStr, 'pincode event received');
  if (pincodeStr.length > 6) {
    this.pincodeError = true;  // Set error if length exceeds 6 digits
    console.log('Pincode length exceeds 6 digits');
  } else {
    this.pincodeError = false;  // Clear error
    if (pincodeStr.length === 6) {
      this.pincode = pincodeStr;  // Store the 6-digit pincode
      console.log('Pincode length is 6, calling AddressFromPincode');
      this.PincodeAddressAtPayment();
    } else {
      console.log('Pincode is not 6 digits, current length:', pincodeStr.length);
    }
  }
}



  PincodeAddressAtPayment() {
    console.log('AddressFromPincode')
    let data = {
      pincode: this.pincode,
    };
    console.log(data, 'pincode');
    this.server.getAddressFromPincode(data).subscribe((res: any) => {
      console.log(res, 'pincode res');
      this.state = res.result.state;
      this.country = res.result.country;
      this.street = res.result.street;
      this.city = res.result.city;
      this.state_district = res.result.state_district;
     
      console.log(this.state, 'this.state res');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/signup']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }

}









