import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';



// Define interface for price items
interface PriceItem {
  layer: number;
  price: number;
}

// Define interface for material prices data
interface MaterialPricesData {
  [material: string]: PriceItem[]; // material is a string, and it maps to an array of PriceItems
}

// Define interface for tooling cost items
interface ToolingCostItem {
  layer: number;
  tooling_cost: number;
}

// Define interface for tooling cost prices data
interface ToolingCostPricesData {
  [tooling_name: string]: ToolingCostItem[]; // tooling type is a string, mapped to an array of ToolingCostItems
}


@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {
  // thicknessInputDisabled = true
  thicknessData: any[] = [];
  SurfacePricesData: any;
  FinishedCopperPricesData: any[] = [];
  // materialPricesData: any[] = [];
  // toolingCostPricesData: any[] = [];

  thicknessInputDisabled: boolean = true;
  surfaceFinishInputDisabled: boolean = true;
  tooling_nameInputDisabled: boolean = true;
  materialInputDisabled: boolean = true;
  finishedCopperInputDisabled: boolean = true;



  thicknesses: number[] = [0.8, 1, 1.2, 1.6, 2, 2.4];
  priceData: { [thickness: number]: { [layer: number]: number } } = {};

  // finishedCoopers: number[] = [35, 60, 95, 130];
  // finishedCopperData: { [finishedCopper: number]: { [layer: number]: number } } = {};

  finishedCoopers: number[] = [35, 60, 95, 130];
  finishedCopperData: { [finishedCopper: string]: { [layer: number]: number } } = {};

// surfacepriceData: { [key: string]: { [layer: number]: number } } = {};
  surfacepriceData: { [surfaceFinish: string]: { [layer: number]: number } } = {};
  surfaceFinishes: string[] = ['ENIG', 'HASL Lead', 'HASL Lead Free'];

  // materiales: string[] = ['FR-4 Tg 130-140', 'FR-4 Tg 180'];
  // materialData: { [material: string]: { [layer: number]: number } } = {};

  materials: string[] = ['FR-4 Tg 130-140', 'FR-4 Tg 180']; // Example materials
  materialData: { [material: string]: { [layer: number]: number } } = {};
  materialPricesData: { [material: string]: PriceItem[] } = {};


  // Tooling cost data response and data structure
  toolingCosts: string[] = ['SS', 'DS', 'ML4', 'ML6']; // Example tooling types
  toolingCostData: { [tooling_name: string]: { [layer: number]: number } } = {};
  toolingCostPricesData: ToolingCostPricesData = {};


  updatedSurfaceFinish: any = [];
  updatedFinishedCopper:any = [];
  updatedMaterial:any = []


  constructor(private server: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.AllThicknessPrices();
    this.AllSurfacePrices();
    this.AllFinishedCopperPrices();
    this.AllMaterialPrices();
    this.fetchAndInitializeToolingCostPrices();
  }

  enableInputs() {
    this.thicknessInputDisabled = false;
  }

  tooling_name() {
    this.tooling_nameInputDisabled = false;
  }
  material() {
    this.materialInputDisabled = false;
  }
  finishedCopper() {
    this.finishedCopperInputDisabled = false;
  }
  surfaceFinish() {
    this.surfaceFinishInputDisabled = false;
  }


  // AllThicknessPrices() {
  //   this.server.getAllThicknessPrices().subscribe(
  //     (res: any) => {
  //       console.log(res, 'Response from getAllThicknessPrices');
  //       if (res && res.statusCode === 200 && res.status === "SUCCESS") {
  //         this.thicknessData = res.result;
  //         console.log(this.thicknessData, 'Assigned thickness data');
  //       } else {
  //         console.log('Failed to fetch thickness prices or no data found');
  //       }
  //     },
  //     (error: any) => {
  //       console.error('Error fetching thickness prices:', error);
  //     }
  //   );
  // }

  // getPrice(thickness: number, layer: number): number {
  //   const thicknessData = this.thicknessData[thickness];
  //   if (thicknessData) {
  //     const layerData = thicknessData.find((item: any) => item.layer === layer);
  //     console.log(layerData, 'layer dataaaaaa')
  //     return layerData ? layerData.price : 0;
  //   }
  //   return 0;
  // }

  // updatePricing() {
  //   this.thicknessInputDisabled = false
  //   let data = {

  //   }
  //   this.server.updateThicknessPrice(data).subscribe((res: any) => {
  //     console.log(res, 'Response from updateThicknessPrice');
  //   }
  //   );
  // }


  AllThicknessPrices() {
    this.spinner.show();
    this.server.getAllThicknessPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllThicknessPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.thicknessData = res.result;
          this.initializePriceData();
          console.log(this.thicknessData, 'Assigned thickness data');
        } else {
          console.log('Failed to fetch thickness prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching thickness prices:', error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  initializePriceData() {
    this.spinner.show();
    this.thicknesses.forEach(thickness => {
      this.priceData[thickness] = {};
      for (let layer of [1, 2, 4, 6]) {
        const price = this.getPrice(thickness, layer);
        this.priceData[thickness][layer] = price;
      }
    });
    this.spinner.hide();
  }

  getPrice(thickness: number, layer: number): number {
    const thicknessData = this.thicknessData[thickness];
    if (thicknessData) {
      const layerData = thicknessData.find((item: any) => item.layer === layer);
      return layerData ? layerData.price : 0;
    }
    return 0;
  }

  updatePricing() {
    let data = [];
    for (const thickness in this.priceData) {
      for (const layer in this.priceData[thickness]) {
        const thicknessId = this.thicknessData[+thickness]?.find((item: { layer: number; }) => item.layer === +layer)?.id;
        if (thicknessId) {
          data.push({
            thicknessId: thicknessId,
            thickness: +thickness,     
            layer: +layer,        
            price: this.priceData[thickness][layer]
          });
        } else {
          console.warn(`No thicknessId found for thickness ${thickness} and layer ${layer}`);
        }
      }
    }
    console.log('Data to be sent:', data); 
    this.server.updateThicknessPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateThicknessPrice');
        this.spinner.hide();
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllThicknessPrices();
        } else if (res.statusCode === 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating thickness prices:', error);
        this.spinner.hide();
      }
    );
  }


  AllSurfacePrices() {
    this.server.getAllSurfacePrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from SurfacePricesData');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.SurfacePricesData = res.result;
          this.initializeSurfacePriceData();
          console.log(this.SurfacePricesData, 'Assigned SurfacePrices data');
        } else {
          console.log('Failed to fetch Surface prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching SurfacePrices prices:', error);
      }
    );
  }
  
  initializeSurfacePriceData() {
    const surfacePricesResult = this.SurfacePricesData;
    for (const surfaceFinish in surfacePricesResult) {
      if (surfacePricesResult.hasOwnProperty(surfaceFinish)) {
        this.surfacepriceData[surfaceFinish] = {};
        surfacePricesResult[surfaceFinish].forEach((priceItem: any) => {
          const layer = priceItem.layer;
          const price = priceItem.price;
          this.surfacepriceData[surfaceFinish][layer] = price;
        });
      }
    }
    console.log('Initialized surfacepriceData:', this.surfacepriceData);
  }
  
  getsurfacePrice(surfaceFinish: string, layer: number): number {
    const surfaceFinishData = this.surfacepriceData[surfaceFinish];
    if (surfaceFinishData) {
      const layerData = surfaceFinishData[layer];
      return layerData || 0;
    }
    return 0;
  }

  updateSurfacePricing() {
    let data = [];
    console.log(this.surfacepriceData, 'dggg')
    for (let j = 0; j < this.updatedSurfaceFinish.length; j++) {
      const surfaceFinish = this.updatedSurfaceFinish[j].surfaceFinish;
      const layer = this.updatedSurfaceFinish[j].layer;
      const price = this.updatedSurfaceFinish[j].price;
      const surfaceFinishId = this.SurfacePricesData[surfaceFinish].find((item: any) => item.layer == layer).id;
      data.push({
        surfaceFinishId: surfaceFinishId,
        surfaceFinish: surfaceFinish,  // Adjust if you need to map surfaceFinish to a specific id
        layer: layer,
        price: price
      });
    }
    console.log('Data to be sent for surface finishes:', data);
    
    this.server.updateSurfaceFinishPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateSurfacePrice');
        this.spinner.hide();
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllSurfacePrices(); // Refresh the data after update
        } else if (res.statusCode === 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating surface finish prices:', error);
        this.spinner.hide();
      }
    );
  }

  onSurfacePriceChange(surfaceFinish: string, layer: number, newValue: number) {
    const index = this.updatedSurfaceFinish.findIndex((item: any) => {
      return (item.surfaceFinish == surfaceFinish && item.layer == layer);
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedSurfaceFinish[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedSurfaceFinish.push({ surfaceFinish, layer, price: newValue });
    }
  }
  
  
  

  AllFinishedCopperPrices() {
    this.server.getAllFinishedCopperPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllFinishedCopperPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.FinishedCopperPricesData = res.result;
          this.initializeFinishedCopperPriceData();
          console.log(this.FinishedCopperPricesData, 'Assigned FinishedCopperPrices data');
        } else {
          console.log('Failed to fetch FinishedCopperPricesData prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching FinishedCopperPricesData prices:', error);
      }
    );
  }

  initializeFinishedCopperPriceData() {
    this.finishedCoopers.forEach(finishedCopper => {
      this.finishedCopperData[finishedCopper] = {};
      const priceItems = this.FinishedCopperPricesData[finishedCopper];
      if (priceItems) {
        priceItems.forEach((priceItem: any) => {
          const layer = priceItem.layer;
          const price = priceItem.price;
          this.finishedCopperData[finishedCopper][layer] = price;
        });
      }
    });
  }

  getFinishedCopperPrice(finishedCopper: number, layer: number): number {
    const finishedCopperData = this.finishedCopperData[finishedCopper];
    if (finishedCopperData) {
      const layerData = finishedCopperData[layer];
      return layerData || 0;
    }
    return 0;
  }



  updateFinishedCopperPricing() {
    let data = [];
    for (let j = 0; j < this.updatedFinishedCopper.length; j++) {
      const finishedCopper = this.updatedFinishedCopper[j].finishedCopper;
      const layer = this.updatedFinishedCopper[j].layer;
      const price = this.updatedFinishedCopper[j].price;
      const finishedCopperId = this.FinishedCopperPricesData[finishedCopper].find((item: any) => item.layer == layer).id;
      data.push({
        finishedCopperId: finishedCopperId,
        finishedCopper: finishedCopper,  // Adjust if you need to map finishedCopper to a specific id
        layer: layer,
        price: price
      });
    }
    console.log('Data to be sent for finished copper:', data);
    
    this.server.updateFinishedCooperPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateFinishedCopperPrice');
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllFinishedCopperPrices(); // Refresh the data after update
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating finished copper prices:', error);
      }
    );
  }
  
  onFinishedCopperPriceChange(finishedCopper: number, layer: number, newValue: number) {
    const index = this.updatedFinishedCopper.findIndex((item: any) => {
      return (item.finishedCopper == finishedCopper && item.layer == layer);
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedFinishedCopper[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedFinishedCopper.push({ finishedCopper, layer, price: newValue });
    }
  }





  AllMaterialPrices() {
    this.server.getAllMaterialPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllMaterialPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.materialPricesData = res.result;  // Assign fetched data to a variable
          this.initializeMaterialPriceData();   // Initialize price data
          console.log(this.materialPricesData, 'Assigned MaterialPrices data');
        } else {
          console.log('Failed to fetch MaterialPricesData prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching MaterialPricesData prices:', error);
      }
    );
  }

  initializeMaterialPriceData() {
    this.materials.forEach((material: string) => {
      this.materialData[material] = {};  // Initialize data object for each material
  
      // Now TypeScript knows materialPricesData[material] is an array of PriceItem[]
      const priceItems = this.materialPricesData[material];  
  
      if (priceItems) {
        priceItems.forEach((priceItem: PriceItem) => {
          const layer = priceItem.layer;
          const price = priceItem.price;
  
          // Store the price for the given material and layer
          this.materialData[material][layer] = price;
        });
      }
    });
  }
  

  getMaterialPrice(material: string, layer: number): number {
    const materialData = this.materialData[material];
    if (materialData) {
      const layerData = materialData[layer];
      return layerData || 0;
    }
    return 0;
  }

  // updateMaterialPricing() {
  //   let data = [];
  //   for (let j = 0; j < this.updatedMaterial.length; j++) {
  //     const material = this.updatedMaterial[j].material;
  //     const layer = this.updatedMaterial[j].layer;
  //     const price = this.updatedMaterial[j].price;
  //     const materialId = this.materialPricesData[material].find((item: any) => item.layer == layer).id;
  
  //     data.push({
  //       materialId: materialId,
  //       material: material,  // Adjust if you need to map material to a specific id
  //       layer: layer,
  //       price: price
  //     });
  //   }
  
  //   console.log('Data to be sent for material pricing:', data);
  
  //   this.server.updateMaterialPrice(data).subscribe(
  //     (res: any) => {
  //       console.log(res, 'Response from updateMaterialPrice');
  //       if (res.statusCode === 200) {
  //         this.snackBar.open(res.message, 'x', {
  //           duration: 3000,
  //           verticalPosition: 'top',
  //           panelClass: ['custom-style']
  //         });
  //         this.AllMaterialPrices(); // Refresh the data after update
  //       } else {
  //         this.snackBar.open('Something went wrong', 'x', {
  //           duration: 3000,
  //           verticalPosition: 'top',
  //           panelClass: ['custom-style']
  //         });
  //       }
  //     },
  //     (error: any) => {
  //       console.error('Error updating material prices:', error);
  //     }
  //   );
  // }
  
  onMaterialPriceChange(material: string, layer: number, newValue: number) {
    const index = this.updatedMaterial.findIndex((item: any) => {
      return (item.material == material && item.layer == layer);
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedMaterial[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedMaterial.push({ material, layer, price: newValue });
    }
  }


  // Method to fetch and initialize all tooling cost prices
  fetchAndInitializeToolingCostPrices() {
    this.server.getAllToolingCostPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllToolingCostPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.toolingCostPricesData = res.result;  // Assign fetched data to a variable
          this.initializeToolingCostPriceData();   // Initialize price data
          console.log(this.toolingCostPricesData, 'Assigned ToolingCostPrices data');
        } else {
          console.log('Failed to fetch ToolingCostPricesData prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching ToolingCostPricesData prices:', error);
      }
    );
  }

  // Initialize tooling cost price data
  initializeToolingCostPriceData() {
    this.toolingCosts.forEach((tooling_name: string) => {
      this.toolingCostData[tooling_name] = {}; // Initialize each tooling type's data as an empty object

      const priceItems: ToolingCostItem[] = this.toolingCostPricesData[tooling_name]; // Get price items for the tooling type
      if (priceItems) {
        priceItems.forEach((priceItem: ToolingCostItem) => {
          const layer = priceItem.layer;
          const price = priceItem.tooling_cost;

          // Store the price for the given tooling and layer
          this.toolingCostData[tooling_name][layer] = price;
        });
      }
    });
  }

  // Method to get the price for a specific tooling and layer
  getToolingCostPrice(tooling_name: string, layer: number): number {
    const toolingData = this.toolingCostData[tooling_name];
    if (toolingData) {
      const layerData = toolingData[layer];
      return layerData || 0;
    }
    return 0;
  }


  

}  