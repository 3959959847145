<app-spinner></app-spinner>

<div class="signup-cont">
  <div class="center-container">
    <mat-card class="signupcard">
      <!-- <div class="title mt-2" align="center">
        <h1>User Registration</h1>
    </div> -->
      <div class="row m-0 p-0">
        <div class="col-md-6 col-xl-6 col-xxl-6  bacimg">
          <img src="../../assets/images/SignupImage.png" alt="Background Image" />
        </div>

        <!-- <div class="col-md-6 col-xl-6 col-xxl-6 cardimg ">
          <form #createUser="ngForm">
            <div class="" style="width: 100%; margin: 10px;">
              <input class="example-form-field" ngModel name="firstName" matInput type="text"
                placeholder="Enter first name" required>
            </div>

            <div class="" style="width: 100%;  margin: 10px;">
              <input class="example-form-field" ngModel name="lastName" matInput type="text"
                placeholder="Enter last name" required>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <input class="example-form-field" ngModel name="email" matInput type="text" placeholder="Enter email"
                required>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <input class="example-form-field" ngModel name="confirmEmail" matInput type="text"
                placeholder="Re-enter email" required>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <div style="position: relative;">
                <input class="field" [type]="passwordFieldType" ngModel name="password" placeholder="Enter password"
                  required />
                <img class="eye" src="../../assets/images/icons-eye.png" (click)="togglePasswordVisibility()">
              </div>
            </div>

            <div class="regbtn mt-1">
              <button [disabled]="createUser.invalid" (click)="signup(createUser.value)" class='reg'
                mat-raised-button>Register</button>
              <p class="haveact mt-1">Already have an Account? <span style="cursor: pointer;" routerLink="/signin"
                  class="underline-text">Login</span></p>

            </div>
          </form>
        </div> -->


        <div class="col-md-6 col-xl-6 col-xxl-6 cardimg ">
          <form #createUser="ngForm" (ngSubmit)="signup(createUser.value)" novalidate>
            <div class="" style="width: 100%; margin: 10px;">
              <label class="labels">First Name <b style="color: red;">*</b></label>
              <input class="example-form-field" ngModel name="firstName" #firstName="ngModel" matInput type="text"
                placeholder="Enter first name" required>
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                <div *ngIf="firstName.errors">
                  First name is required.
                </div>
              </div>
            </div>

            <div class="" style="width: 100%;  margin: 10px;">
              <label class="labels">Last Name <b style="color: red;"> *</b></label>
              <input class="example-form-field" ngModel name="lastName" #lastName="ngModel" matInput type="text"
                placeholder="Enter last name" required>
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                <div *ngIf="lastName.errors">
                  Last name is required.
                </div>
              </div>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <label class="labels">Email <b style="color: red;"> *</b></label>
              <input class="example-form-field" ngModel name="email" #email="ngModel" matInput type="email"
                placeholder="Enter email" required>
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                <div *ngIf="email.errors">
                  Email is required.
                </div>
                <div *ngIf="email.errors && !email.errors">
                  Please enter a valid email address.
                </div>
              </div>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <label class="labels">Re-Enter Email<b style="color: red;"> *</b></label>
              <input class="example-form-field" ngModel name="confirmEmail" #confirmEmail="ngModel" matInput
                type="email" placeholder="Re-enter email" required>
              <div *ngIf="confirmEmail.invalid && (confirmEmail.dirty || confirmEmail.touched)" class="text-danger">
                <div *ngIf="confirmEmail.errors">
                  Confirmation email is required.
                </div>
                <div *ngIf="confirmEmail.value !== email.value && !confirmEmail.errors">
                  Emails do not match.
                </div>
              </div>
            </div>

            <div class="mb-1" style="width: 100%;  margin: 10px;">
              <div style="position: relative;">
                <label class="labels">Password<b style="color: red;"> *</b></label>
                <input [type]="passwordFieldType" class="field" ngModel name="password" #password="ngModel"
                  type="password" placeholder="Enter password" required />
                <img class="eye" src="../../assets/images/icons-eye.png" (click)="togglePasswordVisibility()">
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                  <div *ngIf="password.errors">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="regbtn mt-1">
              <button [disabled]="createUser.invalid" class='reg' mat-raised-button>User Register</button>
              <p class="haveact mt-1">Already have an Account? <span style="cursor: pointer;" routerLink="/signin"
                  class="underline-text">Login</span></p>
            </div>
          </form>
        </div>

      </div>
    </mat-card>
  </div>
</div>