import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignupComponent } from './signup/signup.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableModule } from 'primeng/table';
import {MatChipsModule} from '@angular/material/chips';
import {  MatCardModule } from '@angular/material/card';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RegistrationComponent } from './registration/registration.component';
import {MatRadioModule} from '@angular/material/radio';
import { CartManagementComponent } from './cart-management/cart-management.component';
import { QuoteManagementComponent } from './quote-management/quote-management.component';
import { PaymentComponent } from './payment/payment.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { PriceListComponent } from './admin/price-list/price-list.component'; // Import MatCheckboxModule for Angular Material
import {MatNativeDateModule} from '@angular/material/core';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { HolidayCalendarComponent } from './admin/holiday-calendar/holiday-calendar.component';
// import { NgxSpinnerModule } from 'ngx-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import {MatInputModule} from '@angular/material/input';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminSignupComponent } from './admin/admin-signup/admin-signup.component';
import { CommonModule } from '@angular/common';
import { AdminStatusComponent } from './admin/admin-status/admin-status.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    LoginComponent,
    SignupComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent,
    CartManagementComponent,
    QuoteManagementComponent,
    PaymentComponent,
    SpinnerComponent,
    ForgotPasswordComponent,
    PaymentSuccessComponent,
    PaymentFailComponent,
    DashboardComponent,
    PriceListComponent,
    AdminProfileComponent,
    HolidayCalendarComponent,
    AdminLoginComponent,
    AdminSignupComponent,
    AdminStatusComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatFormFieldModule,
    TableModule ,
    MatCardModule,
    MatChipsModule,
    MatRadioModule,
    NgxSpinnerModule,
    FormsModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    CommonModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
