<div class="card holidayCard">
    <div class="row m-0 p-0 mainContent">
      <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5 col-xxl-5 text">*Sunday Holiday</div>
      <div class="col-12 col-sm-6 col-md-5 col-lg-7 col-xl-7 col-xxl-7">
        <div *ngIf="!fileName" class="upload" (click)="triggerFileUpload()">Upload file</div>
        <div *ngIf="fileName" class="upload" (click)="triggerFileUpload()"> {{ fileName }}</div>
         <input type="file" #fileInput accept=".csv" (change)="onFileSelected($event)" style="display: none;" />
      </div>
      <div class="row m-0 p-0 cardss">
        <div *ngFor="let month of getMonthNames()" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 main">
          <div class="months">
            <button>{{ month }}</button>
          </div>
          <div class="card eachCard">
            <!-- {{ holidaysByMonth[month] | json }} -->
            <div *ngFor="let holiday of holidaysByMonth[month]">
              {{ holiday.date }} - {{ holiday.day }}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  