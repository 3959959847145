<div class="succ_page">

    <div class="succ_cont">
        <div class="close_1">
        </div>
        <img class="succ_img" src="../../assets/images/icons-payment-cancel.png">
        <p class="pay_succ">Your payment was not successfully processed.</p>
        <button mat-button class="payBtn" matDialogClose routerLink="/quote">Please try again</button>
    </div>
</div>

