import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  userId: any;
  userdetails: any = {};
  profileData: any = { userdetails: [{ mobileNO: '' }] };
  editMode: boolean = false;
  disabledName: boolean = false
  passwordChange: boolean = false;
  getAddressData: any;
  // isShippingAddressChecked: boolean = false;
  // isBillingAddressChecked: boolean = false;
  // isShippingAddressChecked1: boolean = false;
  isShippingAddressChecked: boolean = false;
  isBillingAddressChecked: boolean = false;
  password: any; // Define the password property
  confirmPassword: any; // Define the confirmPassword property
  Address_Id: any;

  pincode: string = '';
  state: string = '';
  country: string = '';
  street: string = '';
  city: string = '';
  state_district: string = ''

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private server: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
    
  ) { }

  ngOnInit() {
    this.dataa();
    this.addressData();
    // this.route.paramMap.subscribe(params => {
    //   this.userId = params.get('id');
    //   console.log( this.userId);
    // });

  }

  toggleEditMode() {
    this.snackBar.open('Now you can able to edit profile', 'x', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['custom-style']
    });
    this.editMode = !this.editMode;
    console.log(this.editMode, 'edit!');

  }

  toggleEditModePassword() {
    this.snackBar.open('Now you can able to reset password', 'x', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['custom-style']
    });
    this.passwordChange = !this.passwordChange;
    console.log(this.passwordChange, 'passwordChange!');

  }

  saveChanges() {
    // Implement logic to save changes
    console.log("Changes saved!");
    this.editMode = false; // Disable edit mode after saving
    console.log(this.editMode, 'Changes saved!');

  }

  dataa() {
    this.spinner.show();
    let data = {
      id: localStorage.getItem('userId'),
    }
    this.server.oneProfilre(data).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'ressssss');
      this.profileData = res.result;
      console.log(this.profileData, 'profileData');

    });

  }


  // add = [{
  //     name: '',
  //     mobileNO: '',
  //     email: '',
  //     state: '',
  //     pincode: '',
  //     country: ''
  // }]

  // addInviteUser() {
  //   this.add.push({
  //     name: '',
  //     mobileNO: '',
  //     email: '',
  //     state: '',
  //     pincode: '',
  //     country: ''

  //   });
  // }

  // removeInviteUser(idx: any) {
  //   this.add.splice(idx, 1);
  // }

  // inviteUsersInDashboard() {
  //   // console.log(form, 'createInviteTeamForm')
  //   let obj = {
  //     admin_email: localStorage.getItem('admin_email'),
  //     organization: localStorage.getItem('organization'),
  //     team: this.add
  //   }
  //   console.log(obj, 'invite obj')
  //   this.server.inviteTeam(obj).subscribe((res: any) => {
  //     console.log(res, 'invite res');
  //     this.inviteTeamData = res.result;
  //     if (res.statusCode == 200) {
  //       this.snackBar.open(res.message, 'x', {
  //         duration: 3000,
  //         verticalPosition: 'top',
  //       });

  //     }
  //     this.getAllHrByOrganizationdata();
  //     // this.steptwo(createQuestionForm);
  //   });
  // }


  addressData() {
    this.spinner.show();
    let data = {
      user_id: localStorage.getItem('userId'),
    }
    this.server.getAddress(data).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'ressssss');
      this.getAddressData = res.result;
      console.log(this.getAddressData, 'getAddressData');
      this.Address_Id = this.getAddressData[0]?.id;
      console.log(this.Address_Id, 'Address_Id');
    });

  }


  passwordFieldType: string = 'password';
  togglePasswordVisibility() {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }

  openDialogWithTemplateRefAddAddress(templateRef: TemplateRef<any>) {
    console.log('eeeeeeeeeeeeeee')
    this.dialog.open(templateRef, {
      width: '700px',
      height: '300px',
      backdropClass: "popupbgblur",
      autoFocus: false
    });

  }

  AddressSave(form: any) {
    this.spinner.show();
    let data = {
      user_id: localStorage.getItem('userId'),
      address_id: '',
      country: form.country,
      Address: form.Address,
      pincode: form.pincode,
      city: form.city,
      district: form.district,
      state: form.state,
      billing_address: this.isBillingAddressChecked,
      shipping_address: this.isShippingAddressChecked
    }
    // console.log(data, 'add address obj');
    this.server.Registration(data).subscribe((res: any) => {
      this.spinner.hide();
      // console.log(res, 'res ass address');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.state = '';
        this.country = '';
        this.street = '';
        this.city = '';
        this.state_district = '';
        // this.router.navigate(['/home']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      this.addressData();
    });

  }

  deleteAddress(item: any) {
   let obj = {
    id: item.id
   }
   this.server.deleteAddress(obj).subscribe((res: any) => {
   console.log(res, 'res delete');
   if (res.statusCode == 200) {
    this.snackBar.open(res.message, 'x', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['custom-style']
    });
    // this.router.navigate(['/home']);
  } else if (res.statusCode == 400) {
    this.snackBar.open(res.message, 'x', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['custom-style']
    });
  } else {
    this.snackBar.open('Something went wrong', 'x', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['custom-style']
    });
  }
  this.addressData();
   });
  }

  updateProfile(form: any) {
    let data = {
      user_id: localStorage.getItem('userId'),
      address_id: this.Address_Id,
      companyName: form.Name,
      mobileNO: form.mobileNO,
      // registerAs: form.registerAs,
      // name : form.pname,
      // companyName: form.companyName,
      // alternateNO: form.alternateNO,
      companyURL: form.companyURL,
      industry: form.industry,
      companyType: form.companyType,
      source: form.source,
    }
    console.log(data, 'vvvvvv');
    this.server.Registration(data).subscribe((res: any) => {
      console.log(res,'ressssssssss');
      if (res.statusCode == 200) {
        this.editMode = false;
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      this.addressData();
    });
    
  }

  
  handleCheckboxChange(event: any) {
      if (event.target.id === 'shippingCheckbox') {
          this.isShippingAddressChecked = event.target.checked;
          console.log('Shipping Address Checked:', this.isShippingAddressChecked);
      } else if (event.target.id === 'billingCheckbox') {
          this.isBillingAddressChecked = event.target.checked;
          console.log('Billing Address Checked:', this.isBillingAddressChecked);
      }
  }

  updatePasswordAtProfile(form: any) {
    console.log(form, 'formmmmmmmmmmm')
    let obj = {
      email: this.profileData.slc_userprofiles[0].email,
      password: form.password,
      confirmPassword: form.confirmPassword,
    }
    console.log(obj, 'objjjjj')
    this.server.updatePassword(obj).subscribe((res: any) => {
      console.log(res, 'resssssss');
      if (res.statusCode == 200) {

        // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.password = ''
        this.confirmPassword = ''
        // this.router.navigate(['/home'], { queryParams: { userId: this.userId } });
        // this.router.navigate(['/signin']);

      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
  }


  // onPincodeChange(event: any) {
  //   const pincodeStr = event ? event.toString() : ''; 
  
  //   console.log(pincodeStr, 'pincode event received');
    
  //   if (pincodeStr.length === 6) {
  //     this.pincode = pincodeStr;  
  //     console.log('Pincode length is 6, calling AddressFromPincode');
  //     this.PincodeAddressAtProfile();
  //   } else {
  //     console.log('Pincode is not 6 digits, current length:', pincodeStr.length);
  //   }
  // }

  pincodeError: boolean = false;  // Property to track if there's an error

  onPincodeChange(event: any) {
    const pincodeStr = event ? event.toString().replace(/\D/g, '') : '';  // Allow only digits
    
    console.log(pincodeStr, 'pincode event received');
    
    if (pincodeStr.length > 6) {
      this.pincodeError = true;  // Set error if length exceeds 6 digits
      console.log('Pincode length exceeds 6 digits');
    } else {
      this.pincodeError = false;  // Clear error
      if (pincodeStr.length === 6) {
        this.pincode = pincodeStr;  // Store the 6-digit pincode
        console.log('Pincode length is 6, calling AddressFromPincode');
        this.PincodeAddressAtProfile();
      } else {
        console.log('Pincode is not 6 digits, current length:', pincodeStr.length);
      }
    }
  }
  
  


  PincodeAddressAtProfile() {
    console.log('AddressFromPincode')
    let data = {
      pincode: this.pincode,
    };
    console.log(data, 'pincode');
    this.server.getAddressFromPincode(data).subscribe((res: any) => {
      console.log(res, 'pincode res');
      this.state = res.result.state;
      this.country = res.result.country;
      this.street = res.result.street;
      this.city = res.result.city;
      this.state_district = res.result.state_district;
     
      console.log(this.state, 'this.state res');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
       
        // this.router.navigate(['/signup']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }


}
