import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';



// Define interface for price items
interface PriceItem {
  layer: number;
  price: number;
}

interface PriceItem {
  id: number;
  layer: number;
  price: number;
  previousPrice: number;
  isActive: boolean;
  effectiveDate: string | null;
  expiryDate: string | null;
  createdAt: string;
  updatedAt: string;
}

interface BoardLayerPrices {
  [layer: number]: PriceItem[];
}

interface ToolingCostItem {
  id: number;
  tooling_name: string;
  layer: number;
  tooling_cost: number;
  isActive: boolean;
  effectiveDate: string;
  previousPrice: number;
  expiryDate: string | null;
  createdAt: string;
  updatedAt: string;
}

interface ToolingCostPrices {
  [tooling_name: string]: ToolingCostItem[];
}



// Define interface for material prices data
interface MaterialPricesData {
  [material: string]: PriceItem[]; // material is a string, and it maps to an array of PriceItems
}

// Define interface for tooling cost items
interface ToolingCostItem {
  layer: number;
  tooling_cost: number;
}

// Define interface for tooling cost prices data
interface ToolingCostPricesData {
  [tooling_name: string]: ToolingCostItem[]; // tooling type is a string, mapped to an array of ToolingCostItems
}


interface SolderMaskItem {
  solderMask_cost: number;
}

interface SolderMaskPrices {
  [solder_mask_name: string]: SolderMaskItem[];
}



@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {
  // thicknessInputDisabled = true
  thicknessData: any[] = [];
  SurfacePricesData: any;
  // BasePricesData: any;
  FinishedCopperPricesData: any[] = [];
  // AllBoardLayerPricesData: any[] = [];
  // AllBoardLayerPricesData: BoardLayerPrices = {}; // Define this with the type

  // materialPricesData: any[] = [];
  // toolingCostPricesData: any[] = [];

  thicknessInputDisabled: boolean = true;
  surfaceFinishInputDisabled: boolean = true;
  tooling_nameInputDisabled: boolean = true;
  materialInputDisabled: boolean = true;
  finishedCopperInputDisabled: boolean = true;
  borderLayerInputDisabled: boolean = true;
  basePriceInputDisabled: boolean = true;
  solderMaskInputDisabled: boolean = true





  thicknesses: number[] = [0.8, 1, 1.2, 1.6, 2, 2.4];
  priceData: { [thickness: number]: { [layer: number]: number } } = {};

  // finishedCoopers: number[] = [35, 60, 95, 130];
  // finishedCopperData: { [finishedCopper: number]: { [layer: number]: number } } = {};

  finishedCoopers: number[] = [35, 60, 95, 130];
  finishedCopperData: { [finishedCopper: string]: { [layer: number]: number } } = {};


  // boardLayerData: { [layer: number]: { price: number, id: number } } = {}; 
  // boardLayerData: { [layer: number]: { price: number, id: number } } = {};
  boardLayers: number[] = [1, 2, 4, 6]; 
  boardLayerInputDisabled: boolean = false; 
  AllBoardLayerPricesData: BoardLayerPrices = {}; 
  boardLayerData: { [layer: number]: number } = {}; 

 // Initialize variables
AllToolingCostPricesData: ToolingCostPrices = {};
toolingCostData: { [tooling_name: string]: number } = {};

AllSolderMaskPricesData: SolderMaskPrices = {};  // This will hold the fetched solder mask prices from the API.
solderMaskCostData: { [solder_mask_name: string]: number } = {};  // This will hold the solder mask cost values.
solderMasks: string[] = ['White', 'Red', 'NO SM', 'Green', 'Blue', 'Black']; // Example tooling types



// surfacepriceData: { [key: string]: { [layer: number]: number } } = {};
  surfacepriceData: { [surfaceFinish: string]: { [layer: number]: number } } = {};
  surfaceFinishes: string[] = ['ENIG', 'HASL Lead', 'HASL Lead Free'];

  // basepriceData: { [order_type: string]: { [layer: number]: number } } = {};
  // orderTypes: string[] = ['Express', 'Super-Fast'];
  // basePricesData: { [orderType: string]: PriceItem[] } = {};

  // basepriceData: { [order_type: string]: { [layer: number]: number } } = {};
  // orderTypes: string[] = ['Standard', 'Premium'];
  // basePricesData: { [orderType: string]: PriceItem[] } = {};
  
  basePricesData: any;
  basepriceData: { [order_type: string]: { [layer: number]: number } } = {};
  orderTypes: string[] = ['Standard', 'Premium'];
  updatedBasePrices: any[] = [];

  solderMaskPricesData: any


  // materiales: string[] = ['FR-4 Tg 130-140', 'FR-4 Tg 180'];
  // materialData: { [material: string]: { [layer: number]: number } } = {};

  materials: string[] = ['FR-4 Tg 130-140', 'FR-4 Tg 180']; // Example materials
  materialData: { [material: string]: { [layer: number]: number } } = {};
  materialPricesData: any;


  // Tooling cost data response and data structure
  toolingCosts: string[] = ['SS', 'DS', 'ML4', 'ML6']; // Example tooling types
  // toolingCostData: { [tooling_name: string]: { [layer: number]: number } } = {};
  // toolingCostPricesData: any;

 
  updatedSurfaceFinish: any = [];
  updatedFinishedCopper:any = [];
  updatedBoardLayer: any = [];
  updatedMaterial:any = []
  // updatedBasePrices: any = []
  updatedTooling:any[] = []
  AllToolingCostsData: any;

  constructor(private server: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.AllThicknessPrices();
    this.AllSurfacePrices();
    this.AllFinishedCopperPrices();
    this.AllMaterialPrices();
    this.AllBoardLayerPrices();
    this.AllBasePrices();
    this.AllToolingCostPrices();
    this.AllSolderMaskPrices();
  }

  enableInputs() {
    this.thicknessInputDisabled = false;
  }

  tooling_name() {
    this.tooling_nameInputDisabled = false;
  }
  material() {
    this.materialInputDisabled = false;
  }
  finishedCopper() {
    this.finishedCopperInputDisabled = false;
  }
  surfaceFinish() {
    this.surfaceFinishInputDisabled = false;
  }
  borderLayer() {
    this.borderLayerInputDisabled = false
  }
  basePriceEdit() {
    this.basePriceInputDisabled = false
  }

  solderMask_name() {
    this.solderMaskInputDisabled = false
  }


  // AllThicknessPrices() {
  //   this.server.getAllThicknessPrices().subscribe(
  //     (res: any) => {
  //       console.log(res, 'Response from getAllThicknessPrices');
  //       if (res && res.statusCode === 200 && res.status === "SUCCESS") {
  //         this.thicknessData = res.result;
  //         console.log(this.thicknessData, 'Assigned thickness data');
  //       } else {
  //         console.log('Failed to fetch thickness prices or no data found');
  //       }
  //     },
  //     (error: any) => {
  //       console.error('Error fetching thickness prices:', error);
  //     }
  //   );
  // }

  // getPrice(thickness: number, layer: number): number {
  //   const thicknessData = this.thicknessData[thickness];
  //   if (thicknessData) {
  //     const layerData = thicknessData.find((item: any) => item.layer === layer);
  //     console.log(layerData, 'layer dataaaaaa')
  //     return layerData ? layerData.price : 0;
  //   }
  //   return 0;
  // }

  // updatePricing() {
  //   this.thicknessInputDisabled = false
  //   let data = {

  //   }
  //   this.server.updateThicknessPrice(data).subscribe((res: any) => {
  //     console.log(res, 'Response from updateThicknessPrice');
  //   }
  //   );
  // }


  AllThicknessPrices() {
    this.spinner.show();
    this.server.getAllThicknessPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllThicknessPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.thicknessData = res.result;
          this.initializePriceData();
          console.log(this.thicknessData, 'Assigned thickness data');
        } else {
          console.log('Failed to fetch thickness prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching thickness prices:', error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  initializePriceData() {
    this.spinner.show();
    this.thicknesses.forEach(thickness => {
      this.priceData[thickness] = {};
      for (let layer of [1, 2, 4, 6]) {
        const price = this.getPrice(thickness, layer);
        this.priceData[thickness][layer] = price;
      }
    });
    this.spinner.hide();
  }

  getPrice(thickness: number, layer: number): number {
    const thicknessData = this.thicknessData[thickness];
    if (thicknessData) {
      const layerData = thicknessData.find((item: any) => item.layer === layer);
      return layerData ? layerData.price : 0;
    }
    return 0;
  }

  updatePricing() {
    let data = [];
    for (const thickness in this.priceData) {
      for (const layer in this.priceData[thickness]) {
        const thicknessId = this.thicknessData[+thickness]?.find((item: { layer: number; }) => item.layer === +layer)?.id;
        if (thicknessId) {
          data.push({
            thicknessId: thicknessId,
            thickness: +thickness,     
            layer: +layer,        
            price: this.priceData[thickness][layer]
          });
        } else {
          console.warn(`No thicknessId found for thickness ${thickness} and layer ${layer}`);
        }
      }
    }
    console.log('Data to be sent:', data); 
    this.server.updateThicknessPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateThicknessPrice');
        this.spinner.hide();
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllThicknessPrices();
        } else if (res.statusCode === 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating thickness prices:', error);
        this.spinner.hide();
      }
    );
  }


  AllSurfacePrices() {
    this.server.getAllSurfacePrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from SurfacePricesData');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.SurfacePricesData = res.result;
          this.initializeSurfacePriceData();
          console.log(this.SurfacePricesData, 'Assigned SurfacePrices data');
        } else {
          console.log('Failed to fetch Surface prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching SurfacePrices prices:', error);
      }
    );
  }
  
  initializeSurfacePriceData() {
    const surfacePricesResult = this.SurfacePricesData;
    for (const surfaceFinish in surfacePricesResult) {
      if (surfacePricesResult.hasOwnProperty(surfaceFinish)) {
        this.surfacepriceData[surfaceFinish] = {};
        surfacePricesResult[surfaceFinish].forEach((priceItem: any) => {
          const layer = priceItem.layer;
          const price = priceItem.price;
          this.surfacepriceData[surfaceFinish][layer] = price;
        });
      }
    }
    console.log('Initialized surfacepriceData:', this.surfacepriceData);
  }
  
  getsurfacePrice(surfaceFinish: string, layer: number): number {
    const surfaceFinishData = this.surfacepriceData[surfaceFinish];
    if (surfaceFinishData) {
      const layerData = surfaceFinishData[layer];
      return layerData || 0;
    }
    return 0;
  }

  updateSurfacePricing() {
    let data = [];
    console.log(this.surfacepriceData, 'dggg')
    for (let j = 0; j < this.updatedSurfaceFinish.length; j++) {
      const surfaceFinish = this.updatedSurfaceFinish[j].surfaceFinish;
      const layer = this.updatedSurfaceFinish[j].layer;
      const price = this.updatedSurfaceFinish[j].price;
      const surfaceFinishId = this.SurfacePricesData[surfaceFinish].find((item: any) => item.layer == layer).id;
      data.push({
        surfaceFinishId: surfaceFinishId,
        surfaceFinish: surfaceFinish,  // Adjust if you need to map surfaceFinish to a specific id
        layer: layer,
        price: price
      });
    }
    console.log('Data to be sent for surface finishes:', data);
    
    this.server.updateSurfaceFinishPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateSurfacePrice');
        this.spinner.hide();
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllSurfacePrices(); // Refresh the data after update
        } else if (res.statusCode === 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating surface finish prices:', error);
        this.spinner.hide();
      }
    );
  }

  onSurfacePriceChange(surfaceFinish: string, layer: number, newValue: number) {
    const index = this.updatedSurfaceFinish.findIndex((item: any) => {
      return (item.surfaceFinish == surfaceFinish && item.layer == layer);
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedSurfaceFinish[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedSurfaceFinish.push({ surfaceFinish, layer, price: newValue });
    }
  }
  
  
  

  AllFinishedCopperPrices() {
    this.server.getAllFinishedCopperPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from getAllFinishedCopperPrices');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.FinishedCopperPricesData = res.result;
          this.initializeFinishedCopperPriceData();
          console.log(this.FinishedCopperPricesData, 'Assigned FinishedCopperPrices data');
        } else {
          console.log('Failed to fetch FinishedCopperPricesData prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching FinishedCopperPricesData prices:', error);
      }
    );
  }

  initializeFinishedCopperPriceData() {
    this.finishedCoopers.forEach(finishedCopper => {
      this.finishedCopperData[finishedCopper] = {};
      const priceItems = this.FinishedCopperPricesData[finishedCopper];
      if (priceItems) {
        priceItems.forEach((priceItem: any) => {
          const layer = priceItem.layer;
          const price = priceItem.price;
          this.finishedCopperData[finishedCopper][layer] = price;
        });
      }
    });
  }

  getFinishedCopperPrice(finishedCopper: number, layer: number): number {
    const finishedCopperData = this.finishedCopperData[finishedCopper];
    if (finishedCopperData) {
      const layerData = finishedCopperData[layer];
      return layerData || 0;
    }
    return 0;
  }


  updateFinishedCopperPricing() {
    let data = [];
    for (let j = 0; j < this.updatedFinishedCopper.length; j++) {
      const finishedCopper = this.updatedFinishedCopper[j].finishedCopper;
      const layer = this.updatedFinishedCopper[j].layer;
      const price = this.updatedFinishedCopper[j].price;
      const finishedCopperId = this.FinishedCopperPricesData[finishedCopper].find((item: any) => item.layer == layer).id;
      data.push({
        finishedCopperId: finishedCopperId,
        finishedCopper: finishedCopper,  // Adjust if you need to map finishedCopper to a specific id
        layer: layer,
        price: price
      });
    }
    console.log('Data to be sent for finished copper:', data);
    
    this.server.updateFinishedCooperPrice(data).subscribe(
      (res: any) => {
        console.log(res, 'Response from updateFinishedCopperPrice');
        if (res.statusCode === 200) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.AllFinishedCopperPrices(); // Refresh the data after update
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      },
      (error: any) => {
        console.error('Error updating finished copper prices:', error);
      }
    );
  }
  
  onFinishedCopperPriceChange(finishedCopper: number, layer: number, newValue: number) {
    const index = this.updatedFinishedCopper.findIndex((item: any) => {
      return (item.finishedCopper == finishedCopper && item.layer == layer);
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedFinishedCopper[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedFinishedCopper.push({ finishedCopper, layer, price: newValue });
    }
  }


  
    // Fetch board layer prices
    AllBoardLayerPrices() {
      this.server.getAllBoardLayerPrices().subscribe(
        (res: any) => {
          console.log(res, 'Res getAllBoardLayerPrices');
          if (res && res.statusCode === 200 && res.status === "SUCCESS") {
            this.AllBoardLayerPricesData = res.result as BoardLayerPrices;
            this.initializeBoardLayerPriceData();
            console.log(this.AllBoardLayerPricesData, 'Assigned getAllBoardLayerPrices data');
          } else {
            console.log('Failed to fetch getAllBoardLayerPrices prices or no data found');
          }
        },
        (error: any) => {
          console.error('Error fetching getAllBoardLayerPrices prices:', error);
        }
      );
    }
  
    initializeBoardLayerPriceData() {
      Object.keys(this.AllBoardLayerPricesData).forEach(layerKey => {
        const layer = parseInt(layerKey, 10); 
        const priceItems = this.AllBoardLayerPricesData[layer] as PriceItem[]; 
        if (priceItems && Array.isArray(priceItems)) {
          priceItems.forEach((priceItem: PriceItem) => {
            const price = priceItem.price;
            this.boardLayerData[layer] = price;
            console.log(this.boardLayerData[layer], 'this.boardLayerData[layer]' )
          });
        }
      });
    }
    
    // Method to get price by layer
    getBoardLayerPrice(layer: number): number {
      return this.boardLayerData[layer] || 0;
    }
  
    // Handle price change
    onBoardLayerPriceChange(layer: number, newPrice: number) {
      this.boardLayerData[layer] = newPrice;
      // Perform any additional actions, like updating the server
      console.log(`Price for layer ${layer} changed to ${newPrice}`);
    }

    updateBorderLayerPricing() {
      let data = [];
      for (let key in this.boardLayerData) {
        let id = this.AllBoardLayerPricesData[key][0].id
        let obj = {
          layer_id: id,
          layer: key,
          price: this.boardLayerData[key]
        }
        data.push(obj)
      }
   
      console.log('Data to be sent for board layers:', data);
      this.server.updateBoardLayerPrice(data).subscribe(
        (res: any) => {
          console.log(res, 'Response from updateBoardLayerPrice');
          if (res.statusCode === 200) {
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
            this.AllBoardLayerPrices(); // Refresh the board layer data after update
          } else {
            this.snackBar.open('Something went wrong', 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          }
        },
        (error: any) => {
          console.error('Error updating board layer prices:', error);
        }
      );
    }




    
  // Fetch tooling cost prices
AllToolingCostPrices() {
  this.server.getAllToolingCostPrices().subscribe(
    (res: any) => {
      console.log(res, 'Res getAllToolingCostPrices');
      if (res && res.statusCode === 200 && res.status === "SUCCESS") {
        this.AllToolingCostPricesData = res.result as ToolingCostPrices;
        this.initializeToolingCostPriceData();
        console.log(this.AllToolingCostPricesData, 'Assigned getAllToolingCostPrices data');
      } else {
        console.log('Failed to fetch getAllToolingCostPrices prices or no data found');
      }
    },
    (error: any) => {
      console.error('Error fetching getAllToolingCostPrices prices:', error);
    }
  );
}

initializeToolingCostPriceData() {
  Object.keys(this.AllToolingCostPricesData).forEach(toolingName => {
    const toolingItems = this.AllToolingCostPricesData[toolingName] as ToolingCostItem[];
    if (toolingItems && Array.isArray(toolingItems)) {
      toolingItems.forEach((costItem: ToolingCostItem) => {
        const cost = costItem.tooling_cost; // Extract tooling_cost
        this.toolingCostData[toolingName] = cost; // Bind the tooling cost to the corresponding tooling name
        console.log(this.toolingCostData[toolingName], 'this.toolingCostData[toolingName]');
      });
    }
  });
}


// Method to get tooling cost by name
getToolingCost(toolingName: string): number {
  return this.toolingCostData[toolingName] || 0;
}


updateToolingCosts() {
  let data = [];
  
  // Prepare data to be sent for tooling costs
  for (let toolingName in this.toolingCostData) {
    const toolingItems = this.AllToolingCostPricesData[toolingName] as ToolingCostItem[];

    if (toolingItems && toolingItems.length > 0) {
      let id = toolingItems[0].id; // Get ID from the first item
      let layer = toolingItems[0].layer; // Get layer from the first item
      let obj = {
        toolingCostId: id, // Assuming you need the tooling cost ID
        tooling_name: toolingName,
        layer: layer,
        price: this.toolingCostData[toolingName]
      };
      data.push(obj);
    }
  }

  console.log('Data to be sent for tooling costs:', data);
  
  // Send data to the server
  this.server.updateToolingCostPrice(data).subscribe(
    (res: any) => {
      console.log(res, 'Response from updateToolingCost');
      if (res.statusCode === 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.AllToolingCostPrices(); // Refresh tooling cost data after update
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    },
    (error: any) => {
      console.error('Error updating tooling costs:', error);
    }
  );
}


// Handle tooling cost change
onToolingCostChange(toolingName: string, newPrice: number) {
  this.toolingCostData[toolingName] = newPrice;
  console.log(`Cost for tooling ${toolingName} changed to ${newPrice}`);
}

    



    AllMaterialPrices() {
      this.server.getAllMaterialPrices().subscribe(
        (res: any) => {
          console.log(res, 'Response from getAllMaterialPrices');
          if (res && res.statusCode === 200 && res.status === "SUCCESS") {
            this.materialPricesData = res.result;  // Store the fetched data
            this.initializeMaterialPriceData();   // Process and organize the data
            console.log(this.materialPricesData, 'Assigned MaterialPrices data');
          } else {
            console.log('Failed to fetch MaterialPricesData prices or no data found');
          }
        },
        (error: any) => {
          console.error('Error fetching MaterialPricesData prices:', error);
        }
      );
    }
    

    initializeMaterialPriceData() {
      this.materials.forEach((material: string) => {
        this.materialData[material] = {};  // Initialize data object for each material
        
        const priceItems = this.materialPricesData[material];  // Get price items for the current material
        
        if (priceItems) {
          priceItems.forEach((priceItem: PriceItem) => {
            const layer = priceItem.layer;  // Get the layer (e.g., 1, 2, etc.)
            const price = priceItem.price;  // Get the price for the layer
            
            this.materialData[material][layer] = price;  // Store price in materialData structure
          });
        }
      });
    }
    
  

    getMaterialPrice(material: string, layer: number): number {
      const materialData = this.materialData[material];  // Get data for the material
      if (materialData) {
        const layerData = materialData[layer];  // Get the price for the layer
        return layerData || 0;  // Return the price or 0 if not found
      }
      return 0;  // Return 0 if material data is not found
    }
    

    updateMaterialPricing() {
      let data = [];
      
      // Iterate through the updated materials
      for (let j = 0; j < this.updatedMaterial.length; j++) {
        const material = this.updatedMaterial[j].material;
        const layer = this.updatedMaterial[j].layer;
        const price = this.updatedMaterial[j].price;
        
        // Get the material ID for the corresponding material and layer
        const materialId = this.materialPricesData[material].find((item: any) => item.layer == layer).id;
    
        // Add the material ID, material, layer, and price to the data array
        data.push({
          materialId: materialId,
          material: material,
          layer: layer,
          price: price
        });
      }
    
      console.log('Data to be sent for material pricing:', data);
      
      // Make API call to update the material prices
      this.server.updateMaterialPrice(data).subscribe(
        (res: any) => {
          console.log(res, 'Response from updateMaterialPrice');
          if (res.statusCode === 200) {
            // Success, show a message and refresh the material prices
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
            this.AllMaterialPrices(); // Refresh data
          } else {
            // Failure, show an error message
            this.snackBar.open('Something went wrong', 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          }
        },
        (error: any) => {
          console.error('Error updating material prices:', error);
        }
      );
    }
    
  
    onMaterialPriceChange(material: string, layer: number, newValue: number) {
      const index = this.updatedMaterial.findIndex((item: any) => {
        return (item.material == material && item.layer == layer);  // Find if the item already exists
      });
    
      if (index !== -1) {
        // If the item exists, update its price
        this.updatedMaterial[index]['price'] = newValue;
      } else {
        // If the item doesn't exist, add a new material-layer-price item
        this.updatedMaterial.push({ material, layer, price: newValue });
      }
    }
    

   
    


  // Fetch and initialize base price data
  AllBasePrices() {
    this.server.getAllBasePrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from BasePricesData');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.basePricesData = res.result; // Ensure this matches the API structure
          this.initializeBasePriceData();
          console.log(this.basePricesData, 'Assigned basepriceData');
        } else {
          console.log('Failed to fetch base prices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching base prices:', error);
      }
    );
  }
  
  // Initialize the base price data for all order types
  initializeBasePriceData() {
    for (const orderType in this.basePricesData) {
      if (this.basePricesData.hasOwnProperty(orderType)) {
        this.basepriceData[orderType] = {}; // Initialize for each order type
  
        const priceData = this.basePricesData[orderType];
        // Iterate through the array for each order type
        priceData.forEach((item: any) => {
          const layer = item.layer;  // Get the layer from the item
          const price = item.price;  // Get the price from the item
          this.basepriceData[orderType][layer] = price;
          console.log(`OrderType: ${orderType}, Layer: ${layer}, Price: ${price}`);
        });
      }
    }
    console.log('Initialized basepriceData:', this.basepriceData);
  }
  
  // Handle base price change
  onBasePriceChange(orderType: string, layer: number, newValue: number) {
    const index = this.updatedBasePrices.findIndex((item: any) => {
      return item.orderType === orderType && item.layer === layer;
    });
    if (index !== -1) {
      // If the item exists, update the price
      this.updatedBasePrices[index]['price'] = newValue;
    } else {
      // If the item doesn't exist, add a new one
      this.updatedBasePrices.push({ orderType, layer, price: newValue });
    }
    console.log('Updated base prices:', this.updatedBasePrices);
  }
  

  updateBasePricing() {
    let data = [];
    console.log(this.basepriceData, 'Base Price Data');
  
    // Loop through the updated base price data
    for (let j = 0; j < this.updatedBasePrices.length; j++) {
      const { orderType, layer, price } = this.updatedBasePrices[j];
  
      // Ensure basePriceId is retrieved from the data structure
      const baseId = this.basePricesData[orderType]?.find((item: any) => item.layer === layer)?.id;
  
      if (baseId) {
        data.push({
          baseId: baseId,
          order_type : orderType.toLowerCase(),
          layer: layer,
          price: price
        });
      } else {
        console.warn(`No matching basePriceId found for orderType: ${orderType} and layer: ${layer}`);
      }
    }
  
    console.log('Data to be sent for base prices:', data);
  
    // Send the update request
    if (data.length > 0) {
      this.server.updateBasePrice(data).subscribe(
        (res: any) => {
          console.log(res, 'Response from updateBasePrice');
          this.spinner.hide();
          
          // Display success or error messages based on the response status code
          if (res.statusCode === 200) {
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
            this.AllBasePrices(); // Refresh the data after update
          } else {
            this.snackBar.open(res.message || 'Something went wrong', 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          }
        },
        (error: any) => {
          console.error('Error updating base prices:', error);
          this.spinner.hide();
          this.snackBar.open('Failed to update base prices', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
      );
    } else {
      this.snackBar.open('No valid data to update', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-style']
      });
    }
  }


  AllSolderMaskPrices() {
    this.server.getAllSolderMaskPrices().subscribe(
      (res: any) => {
        console.log(res, 'Response from solderMaskPricesData');
        if (res && res.statusCode === 200 && res.status === "SUCCESS") {
          this.solderMaskPricesData = res.result; // Ensure this matches the API structure
          this.initializeSolderMaskPriceData();
          console.log(this.solderMaskPricesData, 'Assigned solderMaskPricesData');
        } else {
          console.log('Failed to fetch solderMaskPrices or no data found');
        }
      },
      (error: any) => {
        console.error('Error fetching solderMaskPrices prices:', error);
      }
    );
  }
  
  initializeSolderMaskPriceData() {
    Object.keys(this.solderMaskPricesData).forEach(solderMaskName => {
      const solderMaskItems = this.solderMaskPricesData[solderMaskName];
      if (solderMaskItems && Array.isArray(solderMaskItems)) {
        solderMaskItems.forEach((costItem: any) => {
          const cost = costItem.price; // Extract the 'price' field
          this.solderMaskCostData[solderMaskName] = cost; // Bind the price to the corresponding solder mask name
          console.log(this.solderMaskCostData[solderMaskName], 'this.solderMaskCostData[solderMaskName]');
        });
      }
    });
  }
  

    onSolderMaskCostChange(solderMaskName: string, newPrice: number) {
      this.solderMaskCostData[solderMaskName] = newPrice;
      console.log(`Cost for solder mask ${solderMaskName} changed to ${newPrice}`);
    }
  

    updateSolderMaskCosts() {
      let data = [];
    
      // Prepare data to be sent for solder mask costs
      for (let solderMaskName in this.solderMaskCostData) {
        const solderMaskItems = this.solderMaskPricesData[solderMaskName] as any[];
    
        if (solderMaskItems && solderMaskItems.length > 0) {
          let id = solderMaskItems[0].id; // Get ID from the first item
          let obj = {
            solderMaskId: id, // Assuming you need the solder mask ID
            solderMask: solderMaskName,
            price: this.solderMaskCostData[solderMaskName]
          };
          data.push(obj);
        }
      }
    
      console.log('Data to be sent for solder mask costs:', data);
    
      // Send data to the server
      this.server.updateSolderMaskPrice(data).subscribe(
        (res: any) => {
          console.log(res, 'Response from updateSolderMaskCost');
          if (res.statusCode === 200) {
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
            this.AllSolderMaskPrices(); // Refresh solder mask data after update
          } else {
            this.snackBar.open('Something went wrong', 'x', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['custom-style']
            });
          }
        },
        (error: any) => {
          console.error('Error updating solder mask costs:', error);
        }
      );
    }
    

}  