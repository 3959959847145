<app-spinner></app-spinner>
<div class="card priceCard">
    <h2>Price Update</h2>
    <div class="pricesection">
      
        <div class="section">
            <div class="row pricemain m-0">
                <div class="row pricemain">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">Thickness</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">1 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">2 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">4 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">6 Layer</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let thickness of thicknesses" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ thickness }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="priceData[thickness][layer]" [disabled]="thicknessInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="enableInputs()">Update</button>
                <button mat-button (click)="updatePricing()">Save</button>
            </div>
        </div>

        <!-- Surface Finish -->
        <div class="section">
            <div class="row  m-0">
                <div class="row  m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                        <h3 class="pricetext">Surface Finish</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let surfaceFinish of surfaceFinishes" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                  <button>{{ surfaceFinish }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                     class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                  <input [(ngModel)]="surfacepriceData[surfaceFinish][layer]" [disabled]="surfaceFinishInputDisabled" type="number"
                         [placeholder]="layer + ' Layer'" class="priceInput" (ngModelChange)="onSurfacePriceChange(surfaceFinish, layer, $event)">
                </div>
              </div>
              <div class="buttons">
                <button mat-button (click)="surfaceFinish()">Update</button>
                <button mat-button (click)="updateSurfacePricing()">Save</button> <!-- Call to updateSurfacePricing -->
              </div>
              

        <!-- Finished copper -->
        <div class="section">
            <div class="row pricemain m-0">
                <div class="row m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Finished copper</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let finishedCopper of finishedCoopers" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                  <button>{{ finishedCopper }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]" class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                  <input [(ngModel)]="finishedCopperData[finishedCopper][layer]" [disabled]="finishedCopperInputDisabled" type="number"
                         [placeholder]="layer + ' Layer'" class="priceInput" (ngModelChange)="onFinishedCopperPriceChange(finishedCopper, layer, $event)">
                </div>
              </div>
              <div class="buttons">
                <button mat-button (click)="finishedCopper()">Update</button>
                <button mat-button (click)="updateFinishedCopperPricing()">Save</button>
              </div>
        </div>

        <!-- Material -->
        <div class="section">
            <div class="row pricemain m-0">
                <div class="row m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Material</h3>
                    </div>
                </div>
            </div>

            <!-- <div class="row pricemain m-0">
                <div class=" col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>FR-4(Tg 130-140)</button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="1 Layer" class="priceInput">
                </div>
                <div class=" col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="2 Layer" class="priceInput">
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="4 Layer" class="priceInput">
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="6 Layer" class="priceInput">
                </div>
            </div>
            <div class="row pricemain m-0">
                <div class=" col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>FR-4 (Tg 180)</button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="1 Layer" class="priceInput">
                </div>
                <div class=" col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="2 Layer" class="priceInput">
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="4 Layer" class="priceInput">
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input type="number" placeholder="6 Layer" class="priceInput">
                </div>
            </div> -->

<!-- 
            <div *ngFor="let material of materials" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ material }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="materialData[material][layer]" [disabled]="materialInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="material()">Update</button>
                <button mat-button>Save</button>
            </div> -->


            <div *ngFor="let material of materials" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                  <button>{{ material }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]" class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                  <input [(ngModel)]="materialData[material][layer]" [disabled]="materialInputDisabled" type="number"
                         [placeholder]="layer + ' Layer'" class="priceInput" (ngModelChange)="onMaterialPriceChange(material, layer, $event)">
                </div>
              </div>
              <div class="buttons">
                <button mat-button (click)="material()">Update</button>
                <!-- (click)="updateMaterialPricing()" -->
                <button mat-button >Save</button>
              </div>
              
        </div>

        <div class="sectionn">
            <div class="row pricemain m-0">
                <div class="row ">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5 col-xxl-5">
                        <h3 class="pricetext1">Tooling Cost</h3>
                    </div>
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext2">Tooling Cost</h3>
                    </div>
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <h3 class="pricetext3">Base Price</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let tooling_name of toolingCosts" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ tooling_name }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="toolingCostData[tooling_name][layer]" [disabled]="tooling_nameInputDisabled" type="number"
                        [placeholder]="0" class="priceInput">
                </div>
            </div>
            
            <div class="buttons">
                <button mat-button (click)="tooling_name()">Update</button>
                <button mat-button>Save</button>
            </div>
            </div>
        </div>
    </div>
</div>