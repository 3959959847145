<div class="row m-0 footer pt-4">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 ">
        <h2 class="table">Company</h2>
        <a href="https://sclpcb.com" target="_blank">
            <h4 class="aboutDataLINK">About SCLPCB</h4>
          </a>
        <!-- <h4 class="aboutData">News</h4>
        <h4 class="aboutData">Blog</h4> -->
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 ">
        <!-- <h2 class="table">Services</h2>
        <h4 class="aboutData">PCB Layout</h4>
        <h4 class="aboutData">PCB Assembly</h4>
        <h4 class="aboutData">PCB Stencil</h4> -->
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5  lastDetails">
        <h2 class="table">Come, Visit Us! We are in Bollaram, near Miyapur!</h2>
        <img src="../../assets/images/headerFinalLogo-png.png" class="sclImg">
        <h4 class="aboutData">Plot No. 36 & 37, ANRICH Industrial Estate <br><span>IDA Bollaram, Hyderabad – 502 325, Telangana, India </span></h4>
            
        <h4 class="aboutData">Information@SCLpcb.com</h4>
        <h4 class="aboutData">+91 986 619 4011</h4>
    </div>
</div>