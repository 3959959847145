<div class="succ_page">

    <div class="succ_cont" >
      <div class="close_1">
        
          <!-- <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" matDialogClose >
              <path d="M19.9996 1.60001C9.85481 1.60001 1.59961 9.85521 1.59961 20C1.59961 30.1448 9.85481 38.4 19.9996 38.4C30.1444 38.4 38.3996 30.1448 38.3996 20C38.3996 9.85521 30.1444 1.60001 19.9996 1.60001ZM26.9676 25.832C27.2796 26.144 27.2796 26.656 26.9676 26.968C26.8076 27.12 26.6076 27.2 26.3996 27.2C26.1916 27.2 25.9916 27.12 25.8316 26.968L19.9996 21.136L14.1676 26.968C14.0076 27.12 13.8076 27.2 13.5996 27.2C13.3916 27.2 13.1916 27.12 13.0316 26.968C12.7196 26.656 12.7196 26.144 13.0316 25.832L18.8636 20L13.0316 14.168C12.7196 13.856 12.7196 13.344 13.0316 13.032C13.3436 12.72 13.8556 12.72 14.1676 13.032L19.9996 18.864L25.8316 13.032C26.1436 12.72 26.6556 12.72 26.9676 13.032C27.2796 13.344 27.2796 13.856 26.9676 14.168L21.1356 20L26.9676 25.832Z" fill="#656565"/>
              </svg> -->
       </div> 
      <img class="succ_img" src="../../assets/images/icons-check-mark.png" >
      <p class="pay_succ">Payment successful.</p>
      <p class="pay_msg">Payment has been done successfully,
        Thanks for being there with us.</p>
        <!-- <p class="pay_msg">Payment ID : 84456830487, 01 Apr 2024 : 11:30am.</p> -->
        <button mat-button class="payBtn" matDialogClose >Done</button>
    </div>
   </div>