<mat-sidenav-container class="example-container pb-4">
    <mat-sidenav #sidenav class="side" [mode]="isMobile ? 'over' : 'side'" [opened]="!isMobile">

        <mat-nav-list>
            <div class="card-container">
                <div class="camera-container">
                    <img [src]="url" class="profilePic">
                    <img src="../../assets/images/addImage.svg" class="camera" (click)="openFilePicker()">
                    <input type="file" id="fileInput" (change)="onSelect($event)" style="display: none;" />
                </div>
                <div class="name">{{name}}</div>
                <div class="ID">ID: {{userId}}</div>
            </div>
            <div class="buttons">
                <button class="btn" [ngClass]="{'active': selectedItem === 'dashboard'}"
                    (click)="selectItem('dashboard')">
                    Dashboard
                    <span>
                        <img [src]="selectedItem === 'dashboard' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'priceList'}"
                    (click)="selectItem('priceList')">
                    Price List
                    <span>
                        <img [src]="selectedItem === 'priceList' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/profile'}"
                    (click)="selectItem('admin/profile')">
                    My Profile
                    <span>
                        <img [src]="selectedItem === 'admin/profile' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/calendar'}"
                    (click)="selectItem('admin/calendar')">
                    Holiday Calendar
                    <span>
                        <img [src]="selectedItem === 'admin/calendar' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>
                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/status'}"
                    (click)="selectItem('admin/status')">
                    Admin
                    <span>
                        <img [src]="selectedItem === 'admin/status' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sideContent">
        <mat-toolbar>
            <button mat-icon-button (click)="sidenav.toggle()" class="menuBtn">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>
        <div class="main-content">
            <div class="main">
                <div class="welcome">Hii, Welcome Back!</div>
                <div class="calender">
                    <img src="../../assets/images/calender.svg">
                    <div class="time">{{ currentDateTime }}</div>
                </div>
            </div>
            <!-- Dashboard content here -->
            <div *ngIf="selectedItem === 'dashboard'">

                <div class="pannel mt-2">
                    <mat-accordion [multi]="false">
                        <div class="pannel">
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Registrations</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header1" style="width: 7%;">S.No</th>
                                                <th class="gradient-header" style="width: 36%;">Name</th>
                                                <th class="gradient-header" style="width: 47%;">Email</th>
                                                <th class="gradient-header" style="width: 10%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of RegisterData; let i = index;">
                                                <!-- <td class="num">{{i + 1}}</td> -->
                                                <td class="num">{{ i + 1 + (currentPage * pageSize) }}</td>
                                                <td>{{item.firstName}}</td>
                                                <td>{{item.email}}</td>
                                                <td><button mat-button (click)="registrationDetails(item)">View</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="totalUsers" [pageSize]="pageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChange($event)"></mat-paginator>
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <div class="pannel">
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Quotations</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header1" style="width: 7%;  text-align: center;">
                                                    S.No</th>
                                                <th class="gradient-header" style="width: 11%;">Quote No</th>
                                                <th class="gradient-header" style="width: 32%;">PCB Name</th>
                                                <th class="gradient-header" style="width: 15%;">Order Date</th>
                                                <th class="gradient-header" matTooltip="Delivery Date"
                                                    style="width: 15%;">D' Date</th>
                                                <th class="gradient-header" style="width: 15%;">Status</th>
                                                <th class="gradient-header" style="width: 5%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of quotationsData; let i = index;">
                                                <!-- <td>{{i + 1}}</td> -->
                                                <td class="num">{{ i + 1 + (QuotecurrentPage * QuotepageSize) }}</td>
                                                <td>{{item.quote_id}}</td>
                                                <td>{{item.pcbName}}</td>
                                                <td>{{item.order_date | date: "mediumDate"}}</td>
                                                <td>{{item.delivery_date | date: "mediumDate"}}</td>
                                                <td [style.color]="item.order_status === 'pending' ? 'red' : 'green'">
                                                    {{ formatStatusOrders(item.order_status) }}
                                                </td>
                                                <td><button mat-button (click)="QtatationsDetails(item)">View</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="totalUsersForQuotes" [pageSize]="QuotepageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChangeQuote($event)">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <div class="pannel">
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Orders</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header1" style="width: 7%;">S.No</th>
                                                <th class="gradient-header" style="width: 10%;">Order ID</th>
                                                <th class="gradient-header" style="width: 15%;">Order Date</th>
                                                <th class="gradient-header" style="width: 20%;">Tracking Id</th>
                                                <th class="gradient-header" style="width: 23%;">Tracking url</th>
                                                <th class="gradient-header" style="width: 10%;">Status</th>
                                                <th class="gradient-header" style="width: 5%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of completedOrderDetails; let i = index;">
                                                <td class="num">{{ i + 1 + (OrdercurrentPage * OrderpageSize) }}</td>
                                                <td>{{item.quote_id}}</td>
                                                <td>{{item.order_date | date: "mediumDate"}}</td>
                                                <td style="cursor: pointer;" matTooltip="{{item.track_id}}"
                                                    (click)="rowData1(item)">
                                                    <span *ngIf="item.track_id; else addTrack">
                                                        {{item.track_id}}
                                                    </span>
                                                    <ng-template #addTrack>
                                                        <button mat-button class="add-track-btn">Add</button>
                                                    </ng-template>
                                                </td>

                                                <td style="cursor: pointer;" matTooltip="{{item.track_url}}"
                                                    (click)="rowData2(item)">
                                                    <span *ngIf="item.track_url; else addTrackUrl">
                                                        {{item.track_url | slice:0:20}}
                                                    </span>
                                                    <ng-template #addTrackUrl>
                                                        <button mat-button class="add-track-btn">Add</button>
                                                    </ng-template>
                                                </td>

                                                <td style="cursor: pointer;"
                                                    [style.color]="item.order_status === 'pending' ? 'red' : 'green'"
                                                    (click)="rowData(item)">{{ formatStatusOrders(item.order_status) }}
                                                </td>
                                                <td><button mat-button (click)="orderDetailsData(item)">View</button>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                    <mat-paginator [length]="totalUsersForOrder" [pageSize]="OrderpageSize"
                                        [pageSizeOptions]="[10, 20, 30]" (page)="onPageChangeOrder($event)">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <div class="pannel">
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Price Details</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <div class="Price">
                                        <img src="../../assets/images/calender.svg" class="calenderPic">
                                        <div class="fromTo">From</div>
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="startDate" [matDatepicker]="picker1">
                                            <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                        <div class="fromTo">To</div>
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="endDate" [matDatepicker]="picker2">
                                            <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                        <button class="searchbtn" (click)="searchPriceHistory()">Search</button>
                                    </div>
                                    <table mat-table [dataSource]="dataSourcePrice" class="responsive-table">
                                        <ng-container matColumnDef="sno">
                                            <th class="SNO" mat-header-cell *matHeaderCellDef> S.No</th>
                                            <td mat-cell *matCellDef="let element; let i = index" data-label="S.No.">
                                                {{ priceHistorycalculateSno(i) }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef> Category </th>
                                            <td mat-cell *matCellDef="let element" data-label="Category">
                                                {{element.category}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="subcategory">
                                            <th mat-header-cell *matHeaderCellDef> Subcategory </th>
                                            <td mat-cell *matCellDef="let element" data-label="Subcategory">
                                                {{element.subcategory}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="layertype">
                                            <th mat-header-cell *matHeaderCellDef> Layer Type </th>
                                            <td mat-cell *matCellDef="let element" data-label="Layer Type">
                                                {{element.layertype}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="previousprice">
                                            <th mat-header-cell *matHeaderCellDef> Previous Price </th>
                                            <!-- <td mat-cell *matCellDef="let element" data-label="Previous Price">
                                                {{element.previousprice}} </td> -->
                                            <td mat-cell *matCellDef="let element" data-label="Previous Price">
                                                {{ element.previousprice ? element.previousprice : '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="changedprice">
                                            <th mat-header-cell *matHeaderCellDef> Changed Price </th>
                                            <td mat-cell *matCellDef="let element" data-label="Changed Price">
                                                {{element.changedprice}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pricechangeddate">
                                            <th mat-header-cell *matHeaderCellDef matTooltip="Price Changed Date">
                                                P'Changed Date</th>
                                            <td mat-cell *matCellDef="let element" data-label="Price Changed Date">
                                                {{element.pricechangeddate | date: "mediumDate"}} </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                                    </table>
                                    <mat-paginator [length]="totalPriceHistoryRecords" [pageSize]="priceHistoryPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChangePriceHistory($event)">
                                    </mat-paginator>

                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>
            </div>
            <div *ngIf="selectedItem === 'priceList'" class="priceList">
                <app-price-list></app-price-list>
            </div>
            <div *ngIf="selectedItem === 'admin/profile'">
                <app-admin-profile></app-admin-profile>
            </div>
            <div *ngIf="selectedItem === 'admin/calendar'">
                <app-holiday-calendar></app-holiday-calendar>
            </div>
            <div *ngIf="selectedItem === 'admin/status'">
                <app-admin-status></app-admin-status>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #dialogTemplate11 let-data="data">
    <div>
        <h1 mat-dialog-title>Order ID - {{ iddss }}</h1>
        <h1 mat-dialog-title>Order ID - {{ trackid }}</h1>

        <div mat-dialog-content>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>

<!-- Dialog template -->
<ng-template #dialogTemplate let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>

        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4>Update order status</h4>
                        <mat-form-field>
                            <mat-select [(ngModel)]="OrStatus" name="food" (selectionChange)="onStatusChange($event)">
                                <mat-option *ngFor="let food of foods" [value]="food.value">{{ food.viewValue
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </div>
        <div mat-dialog-actions class="btns">
            <!-- <button mat-button mat-dialog-close>Sent a Email</button> -->
            <button mat-button mat-dialog-close>Close</button>
            <button mat-button mat-dialog-close (click)="updateOrderStatus()">Update</button>
        </div>
    </div>
</ng-template>

<ng-template #dialogTemplateTrackId let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>
        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4 class="m-0 p-0">Update tracking ID</h4>
                        <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput
                            [(ngModel)]="trackid" placeholder="Enter ID">
                    </div>
                    <div>
                        <h4 class="m-0 p-0 mt-3">Update tracking URL</h4>
                        <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput
                            [(ngModel)]="trackurl" placeholder="Enter URL">
                    </div>
                </div>
            </div>

        </div>
        <div mat-dialog-actions class="btns">
            <!-- <button mat-button mat-dialog-close>Sent a Email</button> -->
            <button mat-button mat-dialog-close>Close</button>
            <button mat-button mat-dialog-close (click)="updateOrderStatusTrackId()"
                [disabled]="!trackid || !trackurl">Update</button>
        </div>
    </div>
</ng-template>

<ng-template #dialogTemplateURL let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>

        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4 class="m-0 p-0">Update tracking ID</h4>
                        <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput
                            [(ngModel)]="trackid" placeholder="Enter ID">
                    </div>
                    <div>
                        <h4 class="m-0 p-0 mt-3">Update tracking URL</h4>
                        <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput
                            [(ngModel)]="trackurl" placeholder="Enter URL">
                    </div>

                </div>
            </div>

        </div>
        <div mat-dialog-actions class="btns">
            <button mat-button mat-dialog-close>Close</button>
            <button mat-button mat-dialog-close (click)="updateOrderStatusTrackUrl()"
                [disabled]="!trackid || !trackurl">Update</button>
        </div>
    </div>
</ng-template>

<ng-template #registrationDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
        <div class="publishdialog">
            <div class="d-flex">
                <h5>User Profile Details</h5>
            </div>
        </div>

        <div class="row m-0 p-0">
            <div class="message col-12">
                <div class="row m-0 p-0">
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">First Name:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.firstName
                                }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Last Name:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.lastName }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="name col-6">RegisterAs:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.registerAs
                                }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Mobile No:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.mobileNO }}
                            </div>
                        </div>
                    </div>

                    <!-- Second Column with Address Details -->
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">Address:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.Address }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="name col-6">City:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.city }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="name col-6">State:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.state }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Pincode:</div>
                            <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.pincode }}
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Dialog Buttons -->
                <div class="d-flex mt-5 mb-2 justify-content-center">
                    <button matDialogClose class="cancelBtn">
                        CLOSE
                    </button>
                    <!-- <button matDialogClose class="proceedBtn">
                        PROCEED
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #quatationDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
        <div class="publishdialog">
            <div class="d-flex">
                <h5>Quote Details</h5>
            </div>
        </div>

        <div class="row m-0 p-0">
            <div class="message col-12">
                <div class="row m-0 p-0">
                    <!-- First Column with 4 items -->
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">PCB Name:</div>
                            <!-- {{QuotePopupDetails | json}} -->
                            <div class="names col-6">{{ QuotePopupDetails.pcbName }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">PCB Type:</div>
                            <div class="names col-6">{{ QuotePopupDetails.pcbType }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Material:</div>
                            <div class="names col-6">{{ QuotePopupDetails.material }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Thickness:</div>
                            <div class="names col-6">{{ QuotePopupDetails.thickness }}</div>
                        </div>
                    </div>

                    <!-- Second Column with 3 items -->
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">Quantity:</div>
                            <div class="names col-6">{{ QuotePopupDetails.quantity }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Board Layer:</div>
                            <div class="names col-6">{{ QuotePopupDetails.boardLayer }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Surface Finish:</div>
                            <div class="names col-6">{{ QuotePopupDetails.surfaceFinish }}</div>
                        </div>
                    </div>
                </div>

                <div class="row m-0 p-0">
                    <div class="name col-3 m-0 p-0">Gerber File:</div>
                    <div class="names col-9 m-0 p-0">
                        <a *ngIf="QuotePopupDetails?.gerberFile" [href]="QuotePopupDetails?.gerberFile" download>
                            {{ QuotePopupDetails?.gerberFile }} ({{ getFileExtension(QuotePopupDetails?.gerberFile) }})
                        </a>
                        <button class="downloadBtn" mat-button (click)="downloadFile(QuotePopupDetails?.gerberFile)">Download</button>
                    </div>
               </div>

                <!-- Dialog Buttons -->
                <div class="d-flex mt-5 mb-2 justify-content-center">
                    <button matDialogClose class="cancelBtn">
                        CLOSE
                    </button>
                    <!-- <button matDialogClose class="proceedBtn">
                        PROCEED
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #OrderDetailsDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
        <div class="publishdialog">
            <div class="d-flex">
                <h5>Order Details</h5>
            </div>
        </div>

        <div class="row m-0 p-0">
            <div class="message col-12">
                <div class="row m-0 p-0">
                    <!-- First Column with 4 items -->
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">PCB Name:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.pcbName }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">PCB Type:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.pcbType }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Material:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.material }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Thickness:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.thickness }}</div>
                        </div>
                    </div>
                    <!-- Second Column with 3 items -->
                    <div class="col-md-6 p-2">
                        <div class="row">
                            <div class="name col-6">Quantity:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.quantity }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Board Layer:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.boardLayer }}</div>
                        </div>
                        <div class="row">
                            <div class="name col-6">Surface Finish:</div>
                            <div class="names col-6">{{ OrderDetailsAtPopup.surfaceFinish }}</div>
                        </div>
                      
                        
                        
                    </div>
                </div>

             
                <div class="row m-0 p-0">
                    <div class="name col-3 m-0 p-0">Gerber File:</div>
                    <div class="names col-9 m-0 p-0">
                        <a *ngIf="OrderDetailsAtPopup?.gerberFile" [href]="OrderDetailsAtPopup?.gerberFile" download>
                            {{ OrderDetailsAtPopup?.gerberFile }} ({{ getFileExtension(OrderDetailsAtPopup?.gerberFile) }})
                        </a>
                        <button class="downloadBtn" mat-button (click)="downloadFile(OrderDetailsAtPopup?.gerberFile)">Download</button>
                    </div>
               </div>

                <!-- Dialog Buttons -->
                <div class="d-flex mt-5 mb-2 justify-content-center">
                    <button matDialogClose class="cancelBtn">
                        CLOSE
                    </button>
                    <!-- <button matDialogClose class="proceedBtn">
                        PROCEED
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>