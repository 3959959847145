import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SignupComponent implements OnInit {

  constructor(private server: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
  ) { }


  ngOnInit(): void {

  }

  passwordFieldType: string = 'password';
  togglePasswordVisibility() {
    this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
  }

  signup(form: any) {
    this.spinner.show();
    console.log(form, 'user data')
    let obj = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      confirmEmail: form.confirmEmail,
      password: form.password,
      role: 'user'
    }
    console.log(obj, 'objjjjjjjj')
    this.server.createUser(obj).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resss');
      if (res.statusCode == 200) {
        this.snackBar.open(res.message, 'x', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.router.navigate(['/signin']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else if (res.statusCode == 300) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    })
  }

}