import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  role: string | null = '';


  constructor(private router: Router) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
  }

  goToSignupScreen() {
    localStorage.clear();
    this.router.navigate(['/signin']);
  }

  logOut() {
    localStorage.clear();
    window.location.href = '/signin';
  }
  // Assuming this code is within your component class
  userIdExists(): boolean {
    return !!localStorage.getItem('userId');
  }

  isLoggedIn(): boolean {
    const userId = localStorage.getItem('userId');
    return !!userId; // Returns true if userId is truthy (not null or undefined)
  }

  logOutAdmin() {
    localStorage.clear();
    window.location.href = '/admin/signin';
  }

}
