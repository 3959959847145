<app-spinner></app-spinner>
<div class="card priceCard">
    <h2>Price Update</h2>
    <div class="pricesection"> 

        <div class="section">
            <div class="row pricemain m-0">
                <div class="row pricemain">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">Thickness</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">1 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">2 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">4 Layer</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                        <h3 class="pricetext">6 Layer</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let thickness of thicknesses" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ thickness }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="priceData[thickness][layer]" [disabled]="thicknessInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="enableInputs()">Update</button>
                <button mat-button (click)="updatePricing()">Save</button>
            </div>
        </div>

        <!-- Surface Finish -->
        <div class="section">
            <div class="row mt-2">
                <div class="row  m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                        <h3 class="pricetext">Surface Finish</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let surfaceFinish of surfaceFinishes" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ surfaceFinish }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="surfacepriceData[surfaceFinish][layer]" [disabled]="surfaceFinishInputDisabled"
                        type="number" [placeholder]="layer + ' Layer'" class="priceInput"
                        (ngModelChange)="onSurfacePriceChange(surfaceFinish, layer, $event)">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="surfaceFinish()">Update</button>
                <button mat-button (click)="updateSurfacePricing()">Save</button> <!-- Call to updateSurfacePricing -->
            </div>
        </div>

        <!-- Finished copper -->
        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Finished copper</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let finishedCopper of finishedCoopers" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ finishedCopper }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="finishedCopperData[finishedCopper][layer]"
                        [disabled]="finishedCopperInputDisabled" type="number" [placeholder]="layer + ' Layer'"
                        class="priceInput" (ngModelChange)="onFinishedCopperPriceChange(finishedCopper, layer, $event)">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="finishedCopper()">Update</button>
                <button mat-button (click)="updateFinishedCopperPricing()">Save</button>
            </div>
        </div>

        <!-- Border layer -->
        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Border Layer</h3>
                    </div>
                </div>
            </div>

            <div *ngFor="let layer of boardLayers" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ layer }} Layer</button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="boardLayerData[layer]" [disabled]="borderLayerInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput"
                        (ngModelChange)="onBoardLayerPriceChange(layer, $event)">
                </div>
            </div>

            <div class="buttons">
                <button mat-button (click)="borderLayer()">Update</button>
                <button mat-button (click)="updateBorderLayerPricing()">Save</button>
            </div>
        </div>

        <!-- Material -->
        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Material</h3>
                    </div>
                </div>
            </div>
            <div *ngFor="let material of materials" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ material }}</button>
                </div>
                <div *ngFor="let layer of [1, 2, 4, 6]"
                    class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="materialData[material][layer]" [disabled]="materialInputDisabled" type="number"
                        [placeholder]="layer + ' Layer'" class="priceInput"
                        (ngModelChange)="onMaterialPriceChange(material, layer, $event)">
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="material()">Update</button>
                <!-- (click)="updateMaterialPricing()" -->
                <button mat-button (click)="updateMaterialPricing()">Save</button>
            </div>
        </div>

        <div class="section">
            <div class="row pricemain mt-2">
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <h3 class="pricetext">Tooling</h3>
                    </div>
                </div>
            </div>
            <div *ngFor="let toolingName of toolingCosts" class="row pricemain m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                    <button>{{ toolingName }}</button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                    <input [(ngModel)]="toolingCostData[toolingName]" type="number" 
                           [placeholder]="toolingName" class="priceInput" [disabled]="tooling_nameInputDisabled"
                           (ngModelChange)="onToolingCostChange(toolingName, $event)"
                          >
                </div>
            </div>
            <div class="buttons">
                <button mat-button (click)="tooling_name()" >Update</button>
                <button mat-button (click)="updateToolingCosts()">Save</button>
            </div>
        </div>
        
        <!-- Base price -->
        <div class="section">
            <div class="row mt-2">
              <div class="row m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <h3 class="pricetext">Base Prices</h3>
                </div>
              </div>
            </div>
          
            <div *ngFor="let orderType of orderTypes" class="row pricemain m-0">
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                <button>{{ orderType }}</button>
              </div>
              <div *ngFor="let layer of [1, 2, 4, 6]" 
                class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                <!-- {{basepriceData[orderType][layer] | json}} -->
                <input [(ngModel)]="basepriceData[orderType][layer]" 
                [disabled]="basePriceInputDisabled"
                  type="number" [placeholder]="layer + ' Layer'" class="priceInput"
                  (ngModelChange)="onBasePriceChange(orderType, layer, $event)">
              </div>
            </div>
          
            <div class="buttons">
              <button mat-button (click)="basePriceEdit()">Update</button>
              <button mat-button (click)="updateBasePricing()">Save</button>
            </div>
          </div>

          <div class="sectionn">
            <div class="row pricemain mt-2">
              <div class="row m-0">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <h3 class="pricetext">Solder Mask</h3>
                </div>
              </div>
            </div>
            
            <div *ngFor="let solderMaskName of solderMasks" class="row pricemain m-0">
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 pricecontent">
                <button>{{ solderMaskName }}</button>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 inputt">
                <input [(ngModel)]="solderMaskCostData[solderMaskName]" type="number" 
                       [placeholder]="solderMaskName" class="priceInput" [disabled]="solderMaskInputDisabled"
                       (ngModelChange)="onSolderMaskCostChange(solderMaskName, $event)">
              </div>
            </div>
            
            <div class="buttons">
              <button mat-button (click)="solderMask_name()">Update</button>
              <button mat-button (click)="updateSolderMaskCosts()">Save</button>
            </div>
          </div>
          
        <!-- </div> -->
    </div>
</div>