import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss']
})
export class PaymentFailComponent implements OnInit {

  ngOnInit() {
  
   
  }
  
}
