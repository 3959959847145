import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-practice';

  issignin: boolean = false;
  issignup: boolean = false;

  constructor(private zone: NgZone, private router: Router) {
    this.router.events.subscribe((event: any) => {
      // if (event instanceof NavigationEnd) {
      //   if (event.urlAfterRedirects === '/signin' || event.urlAfterRedirects === '/signup' || event.urlAfterRedirects === '/payment-success' || event.urlAfterRedirects === '/payment-fail' || (!event.urlAfterRedirects.includes('/password') && event.urlAfterRedirects.includes("?")) ) {
      //     this.issignin = true;
      //     this.issignup = true;
      //   } else {
      //     this.issignin = false;
      //     this.issignup = false;
      //   }
 
      // }

      if (event instanceof NavigationEnd) {
        const authRoutes = [
          '/signin',
          '/signup',
          '/admin/signin',
          '/admin/signup',
          '/payment-success',
          '/payment-fail'
        ];
      
        // Check if the current URL matches any of the auth routes or specific patterns
        if (
          authRoutes.includes(event.urlAfterRedirects) ||
          (!event.urlAfterRedirects.includes('/password') && event.urlAfterRedirects.includes('?'))
        ) {
          this.issignin = true;
          this.issignup = true;
        } else {
          this.issignin = false;
          this.issignup = false;
        }
      }
      
    });


  }
}
