import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { multipleOfValidator } from './validators';


interface NavItem {
  name: string;
  children: string[];
  showChildren?: boolean;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('createQuoteForm') createQuoteForm!: NgForm;
  @ViewChild('OrderValueDialog') OrderValueDialog!: TemplateRef<any>;
  @ViewChild('PrivacyAndPolicyDialog') PrivacyAndPolicyDialog!: TemplateRef<any>;
  @ViewChild('dialogContent') dialogContent!: ElementRef; // Reference to dialog content
  dialogRef!: MatDialogRef<any>;

  @ViewChild('quantityInput', { static: false }) quantityInput!: NgModel;
  quoteEnabled: boolean = false;
  PCBType: string = 'Single Piece'
  QuoteThicknes: any;
  QuoteSurface: string = 'HASL Lead';
  showPrice: boolean = false;
  calculatedData: any = { formulaPrice: 0 }; // Replace this with your actual data
  QuoteCooper: any;
  QuoteColor: any;
  QuoteLegend: any;
  borderLayers: any;
  QuoteLayer: number = 2; // Initialize with a default value
  QuoteMaterial: string = 'FR-4 Tg 130-140'
  QuoteData: any;
  imageData: any;
  selectedFile: any;
  userId: any;
  selectedUnit: string = 'mm'; // Default to millimeter
  length: any;
  width: any;
  selectedOption: string = '';
  maxValue: number = 600;
  minValue: number = 5;
  lengthError: boolean = false;
  lengthErrorMessage: string = '';
  widthError: boolean = false;
  widthErrorMessage: string = '';
  piecesPerPanel: number = 0;
  panelPieces: number = 0;
  remainder: number | null = null;
  quantity1: number = 0;
  // Define class-level variables to store length and width
  selectedSize: string = 'mm'; // Assuming you have this variable defined
  selectedSize1: string = 'mm'; // Assuming you have this variable defined
  lengthMM: string = '';
  widthMM: string = '';
  lengthMM1: string = '';
  widthMM1: string = '';
  isStandardChecked = true;
  isPremiumChecked = false;
  quantity: number | null = null;
  quantityNoOf: number | null = null;
  selectedFinishCooper: string = '35';
  selectedILCooler: string = '18';
  selectedThickness: string = '1.6';
  toggle = true;
  fullPanels: any = '';
  solderMaskColor: string = 'Green'; // default value for Solder mask
  legendColor: string = 'White'; // default value for Legend
  disableWhiteLegend: boolean = false; // flag to disable white legend
  disableBlackLegend: boolean = false; // flag to disable black legend
  isFileSelected: boolean = false;
  offerLetter: File | null = null;
  orderTypeValue: string = '';
  showError: boolean = false;
  errorMessage: string = ''; // Add this property
  isPrivacyAccepted: boolean = false;

  SizeData = [
    { name: 'Millimeter', value: 'mm' },
    { name: 'Inches', value: 'inch' }
  ];

  SizeData1 = [
    { name: 'Millimeter', value: 'mm' },
    { name: 'Inches', value: 'inch' }
  ];

  tabs1 = [
    { name: 'Order Now', value: 'Order Now' },
    { name: 'Add to Cart', value: 'Add to Cart' },
    { name: 'Email a Quote', value: 'Email a Quote' },

  ];
  tabs: any[] = [
    { name: 'Order Now' },
    { name: 'Add to Cart' },
    { name: 'Email a Quote' }
  ];

  caluculatedData: any;
  caluculatedDataa: any;
  quoteData: any;
  quoteId: any;
  grandTotal: any;
  formData: any = {};

  constructor(
    private server: ApiService,
    // private router: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private ProgressSpinnerMode: MatProgressSpinnerModule,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.router.queryParams.subscribe(params => {
    //   this.userId = params['userId'];
    //   console.log('Received userId:', this.userId);
    //   // Use the userId as needed
    // });
    this.getBorderLayerData();
    // const savedFormData = localStorage.getItem('formData');
    // if (savedFormData) {
    //   this.formData = JSON.parse(savedFormData);
    //   console.log('Retrieved Form Data:', this.formData);
    //   this.PCBType = this.formData.PCBType || this.PCBType;
    //   console.log(this.PCBType, 'pcb type112233')
    //   localStorage.removeItem('formData');
    // }
    // this.toggleInputFields(this.PCBType);

    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      this.formData = JSON.parse(savedFormData);
      console.log('Retrieved Form Data:', this.formData);
      this.PCBType = this.formData.PCBType || 'Single Piece';
      this.QuoteLayer = this.formData.boardLayer,
      this.QuoteMaterial = this.formData.material,
      this.quantityNoOf = this.formData.pcb_quantity,
      this.selectedFinishCooper = this.formData.finishedCopper,
      this.solderMaskColor = this.formData.solderMask,
      this.selectedThickness = this.formData.thickness,
      this.QuoteSurface = this.formData.surfaceFinish,
      this.legendColor = this.formData.legend,
      this.widthMM = this.formData.width,
      this.lengthMM = this.formData.length,
      this.lengthMM1 = this.formData.pcbRows,
      this.widthMM1 = this.formData.pcbColumns,
      this.selectedSize = this.formData.type,
      this.fullPanels = this.formData.panel_pieces,
      this.selectedILCooler = this.formData.il_copper
      console.log(this.selectedILCooler, 'fullPanels')
      this.toggleInputFields(this.PCBType);
      this.dataaa();
     
    }

  }

  selectSize(size: any) {
    this.selectedSize = size;
    console.log(this.selectedSize, 'radio option value')
  }

  selectSize1(size: any) {
    this.selectedSize1 = size;
    console.log(this.selectedSize1, 'radio option value1')
  }

  enableQuote() {
    this.quoteEnabled = true;
  }

  disableQuote() {
    this.quoteEnabled = false;
  }

  showMMInputs: boolean = false;// Default to hide MM inputs
  showMMInputs2: boolean = false;

  toggleInputFields(option: string): void {
    console.log(option, 'optionnnn')
    this.PCBType = option;
    console.log(this.PCBType, 'typee');
    if (option === 'Panel by Customer') {
      this.showMMInputs = true // Show MM inputs
      this.showMMInputs2 = false
    } else if (option === 'Panel by Scl') {
      this.showMMInputs2 = true // Hide MM inputs
      this.showMMInputs = false
    } else if (option === 'Single Piece') {
      this.showMMInputs = false
      this.showMMInputs2 = false //
    }
  }

  // Function to update length and width when input fields change
  updateDimensions22(length: string, width: string) {
    const lengthValue = Number(length);
    const widthValue = Number(width);
    this.lengthError = lengthValue > this.maxValue;
    this.widthError = widthValue > this.maxValue;
    this.lengthMM = length;
    this.widthMM = width;
    console.log(this.lengthMM, 'this.lengthMM');
    console.log(this.widthMM, 'this.widthMM');
    this.dataaa();
  }

  updateDimensions(length: string, width: string) {
    const lengthValue = Number(length);
    const widthValue = Number(width);
    if (lengthValue > this.maxValue) {
      this.lengthError = true;
      this.lengthErrorMessage = `Max Value ${this.maxValue}`;
    } else if (lengthValue < this.minValue) {
      this.lengthError = true;
      this.lengthErrorMessage = `Min Value ${this.minValue}`;
    } else {
      this.lengthError = false;
      this.lengthErrorMessage = '';
    }

    if (widthValue > this.maxValue) {
      this.widthError = true;
      this.widthErrorMessage = `Max Value ${this.maxValue}`;
    } else if (widthValue < this.minValue) {
      this.widthError = true;
      this.widthErrorMessage = `Min Value ${this.minValue}`;
    } else {
      this.widthError = false;
      this.widthErrorMessage = '';
    }

    this.lengthMM = length;
    this.widthMM = width;
    this.dataaa();
  }


  updateDimensions1(length: string, width: string) {
    const lengthValue = Number(length);
    const widthValue = Number(width);
    if (lengthValue > this.maxValue) {
      this.lengthError = true;
      this.lengthErrorMessage = `Max Value ${this.maxValue}`;
    } else if (lengthValue < this.minValue) {
      this.lengthError = true;
      this.lengthErrorMessage = `Min Value ${this.minValue}`;
    } else {
      this.lengthError = false;
      this.lengthErrorMessage = '';
    }

    if (widthValue > this.maxValue) {
      this.widthError = true;
      this.widthErrorMessage = `Max Value ${this.maxValue}`;
    } else if (widthValue < this.minValue) {
      this.widthError = true;
      this.widthErrorMessage = `Min Value ${this.minValue}`;
    } else {
      this.widthError = false;
      this.widthErrorMessage = '';
    }

    this.lengthMM1 = length;
    this.widthMM1 = width;
    this.dataaa();
  }

  // Function to update quantity when the input field changes
  // updateQuantity(quantity: any) {
  //   console.log(quantity, 'quantityyyyyyyy')
  //   const quantityValue = parseFloat(quantity);
  //   if (quantityValue > 0) {
  //     this.formData.quantity = quantityValue;
  //   } else {
   
  //     console.log("Please enter a positive number greater than zero.");
  //   }
  //   this.dataaa();
  //   this.calculatePanelsAndRemainder();
  // }

  updateQuantity(quantity: any) {
    console.log(quantity, 'quantityyyyyyyy');
    const quantityValue = parseFloat(quantity);
    if (quantityValue > 0) {
        this.formData.quantity = quantityValue;
        console.log(this.formData.quantity, 'this.formData.quantityw')
    } else {
        console.log("Please enter a positive number greater than zero.");
    }
    this.dataaa();
    this.calculatePanelsAndRemainder();
}


  updateQuantity11(quantity1: any) {
    this.quantityNoOf = quantity1;
    console.log(this.quantityNoOf, 'quantityNoOf');
    this.dataaa();
  }

  updateQuantity1(quantity1: any) {
    const quantityValue = parseFloat(quantity1);
    if (quantityValue > 0) {
      this.quantityNoOf = quantityValue;
    } else {
      console.log("Please enter a positive number greater than zero.");
    }
    this.dataaa();
    this.applyValidators();
  }


  // createQuotes(form: any) {
  //   this.spinner.show();
  //   console.log(form, 'form data')
  //   let obj = {
  //     pcbName: form.pcbName || '',
  //     pcbType: this.PCBType || '',
  //     user_id: localStorage.getItem('userId'),
  //     pcbRows: form.pcbRows || '0',
  //     pcbColumns: form.pcbColumns || '0',
  //     length: this.lengthMM || '1',
  //     width: this.widthMM || '1',
  //     quantity: this.quantity || '1',
  //     boardLayer: this.QuoteLayer || '1',
  //     material: this.QuoteMaterial || 'FR-4',
  //     thickness: this.selectedThickness || '1.6',
  //     surfaceFinish: this.QuoteSurface || 'HASL With Lead',
  //     finishedCopper: this.selectedFinishCooper || '135u',
  //     solderMask: this.solderMaskColor || 'Green',
  //     legend: this.legendColor || 'White',
  //     gerberFile: this.offerLetter || '',
  //   }
  //   console.log(obj, 'create quote form')
  //   this.server.createQuote(obj).subscribe((res: any) => {
  //   this.spinner.hide();
  //     console.log(res, 'resssssssss');
  //     // this.quoteData = res.result;
  //     // console.log(this.quoteData, 'quoteData');
  //     // this.quoteId = this.quoteData.id;
  //     // console.log(this.quoteId, 'quoteData');

  //     if (res.statusCode == 200) {
  //       this.quoteData = res.result;
  //       console.log(this.quoteData, 'quoteData');
  //       this.quoteId = this.quoteData.id;
  //       console.log(this.quoteId, 'quoteData');
  //       // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
  //       this.snackBar.open(res.message, 'x', {
  //         duration: 3000,
  //         verticalPosition: 'top',
  //         panelClass: ['custom-style']
  //       });
  //       // this.router.navigate(['/home']);
  //     } else if (res.statusCode == 400) {
  //       this.snackBar.open(res.message, 'x', {
  //         duration: 3000,
  //         verticalPosition: 'top',
  //         panelClass: ['custom-style']
  //       });
  //     } else {
  //       this.snackBar.open('Something went wrong', 'x', {
  //         duration: 3000,
  //         verticalPosition: 'top',
  //         panelClass: ['custom-style']
  //       });
  //     }
  //     this.cartStatusUpdate();
  //   });

  //   // this.server.PcbPriceCalculation(obj).subscribe((res: any) => {
  //   //   console.log(res, 'ressssss');
  //   //   this.caluculatedData = res.result;
  //   //   console.log(this.caluculatedData, 'this.caluculatedDataaaa');
  //   // })

  // }

  dataaa() {
    let obj = {
      length: this.lengthMM || '0',
      width: this.widthMM || '0',
      quantity: this.formData.quantity || '1',
      boardLayer: this.QuoteLayer || '2',
      surfaceFinish: this.QuoteSurface || 'HASL Lead',
      pcbRows: this.widthMM1 || '0',
      pcbColumns: this.lengthMM1 || '0',
      pcb_quantity: this.quantityNoOf || '0',
      pcbType: this.PCBType || '',
      type: this.selectedSize || this.selectedSize1 || 'mm',
      thickness: this.selectedThickness,
      finishedCopper: this.selectedFinishCooper,
      material: this.QuoteMaterial,
      il_copper: this.selectedILCooler,
      solderMask: this.solderMaskColor
    }
    console.log(obj, 'cal obj');
    this.server.PcbPriceCalculation(obj).subscribe((res: any) => {
      // console.log(res, 'ressssss');
      this.caluculatedData = res.result[0];
      this.caluculatedDataa = res.result[1];
      console.log(this.caluculatedData, 'this.caluculatedDataaaa');
      this.checkVolume();
    })
  }

  toggleInputFieldsSurface(surface: string) {
    this.toggle = !this.toggle;
    this.QuoteSurface = surface;
    console.log(this.QuoteSurface, 'QuoteSurface')
    this.dataaa();
  }

 
  toggleInputFieldsMaterial(material: any) {
    console.log(material, 'material')
    this.QuoteMaterial = material;
    console.log(this.QuoteMaterial, 'QuoteMaterial');
    this.dataaa();
  }

  SelectLayer(layerValue: any, i: any) {
    console.log(layerValue, 'layer')
    this.QuoteLayer = layerValue;
    console.log(this.QuoteLayer, 'layer');
    if (this.QuoteLayer == 6) {
      this.QuoteMaterial = 'FR-4 Tg 130-140';
      console.log(this.selectedThickness, 'thicknesss')
    }
    this.dataaa();
  }

  getBorderLayerData() {
    this.spinner.show();
    this.server.getBorderLayer().subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resssssssss');
      this.borderLayers = res.result;
      console.log(this.borderLayers, 'layers data')
    })
  }


  // onSelectFile(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     console.log('Selected file:', file);
  //     // Now you can store the selected file in a variable or do further processing
  //     // For example, you can assign it to a property in your component class
  //     this.selectedFile = file;
  //     console.log(this.selectedFile, 'selected fileeee');
  //   }
  // }

  // onSelectFile(event: any) {
  //   console.log(event, 'eventtt')
  //   this.offerLetter = event.target.files[0];
  //   console.log(this.offerLetter, 'offffff')
  // }

  // onSelectFile(event: any) {
  //   console.log(event, 'eventtt');
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.isFileSelected = true;
  //   } else {
  //     this.isFileSelected = false;
  //   }
  //   this.offerLetter = file;
  //   console.log(this.offerLetter, 'offffff');
  // }

  //25mb
  onSelectFile(event: any) {
    const file = event.target.files[0];
    const maxFileSize = 25 * 1024 * 1024; // 25MB in bytes
    
    if (file) {
      if (file.size > maxFileSize) {
        this.isFileSelected = false;
        this.errorMessage = 'File size exceeds 25MB. Please select a smaller file.';
        console.error(this.errorMessage); // You can show this error in the UI instead
        this.offerLetter = null; // Reset the file
      } else {
        this.isFileSelected = true;
        this.errorMessage = ''; // Clear error message if valid
        this.offerLetter = file;
        console.log(this.offerLetter, 'Selected offer letter');
      }
    } else {
      this.isFileSelected = false;
      this.offerLetter = null; // Reset the file
    }
  }
  
  cartStatusUpdate() {
    let obj = {
      id: this.quoteId,
      cart_status: 'in-progress'
    }
    console.log(obj, 'objjjjjjj');
    this.server.updateCartStatusApi(obj).subscribe((res: any) => {
      console.log(res, 'ressssss cartStatusUpdate');
      if (res.statusCode == 200) {
        this.router.navigate(['/cart']);
        // this.snackBar.open(res.message, 'x', {
        //   duration: 3000,
        //   verticalPosition: 'top',
        //   panelClass: ['custom-style']
        // });
        // this.router.navigate(['/home']);
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
    //   })
    //   this.router.navigate(['/cart']);
  }

  QuoteStatusUpdate() {
    let obj = {
      pcb_id: this.quoteId,
      quote_status: 'in-progress'
    }
    console.log(obj, 'objjjjjjj');
    this.server.updateQuoteStatusApi(obj).subscribe((res: any) => {
      console.log(res, 'ressssss QuoteStatusUpdate')
    })
    this.router.navigate(['/shipping']);

  }

  QuoteAQuoteData() {
    let obj = {
      id: this.quoteId,
      email: localStorage.getItem('userEmail'),
    }
    console.log(obj, 'QuoteAQuoteData obj');
    this.server.updateQuoteStatusApi(obj).subscribe((res: any) => {
      console.log(res, 'ressssss QuoteAQuoteData')
    })
    // this.router.navigate(['/quote']);
    this.cartStatusUpdate();
  }

  email(val: any) {
    console.log(val, 'valueeeee')
  }


  OrderNowBtn(form: any) {
    if (this.caluculatedData?.formulaPrice < 1000) {
      // show error msg
      this.snackBar.open('Minimum order value (WTC) 1000', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-style']
      });

      // const dialogRef = this.dialog.open(this.OrderValueDialog);
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('Dialog closed with result:', result);
      // });
      return; // Stop further execution
    }
    const userId = localStorage.getItem('userId');
    if (!userId) {
      const saveData = {
        pcbName: form.pcbName,
        PCBType: this.PCBType,
        quantity: form.quantity,
        boardLayer: this.QuoteLayer,
        material: this.QuoteMaterial,
        thickness: this.selectedThickness,
        surfaceFinish: this.QuoteSurface,
        finishedCopper: this.selectedFinishCooper,
        solderMask: this.solderMaskColor,
        legend: this.legendColor,
        panel_pieces: this.fullPanels,
        pcb_quantity: this.quantityNoOf,
        pcbRows: this.lengthMM1,
        pcbColumns: this.widthMM1,
        length: this.lengthMM,
        width: this.widthMM,
        type: this.selectedSize,
        order_type: this.orderTypeValue,
        il_copper: this.selectedILCooler
      };
      localStorage.setItem('formData', JSON.stringify(saveData));
      // localStorage.setItem('formData', JSON.stringify(form));
      this.router.navigate(['/signin']);
      return;
    }
    const formData = new FormData();
    console.log('Form Data Before:', form);
    formData.append('pcbName', form.pcbName || '');
    formData.append('pcbType', this.PCBType || '');
    formData.append('user_id', localStorage.getItem('userId') || '');
    formData.append('pcbRows', this.lengthMM1 || '0');
    formData.append('pcbColumns', this.widthMM1 || '0');
    formData.append('length', this.lengthMM || '0');
    formData.append('width', this.widthMM || '0');
    formData.append('type', this.selectedSize || this.selectedSize1 || 'mm');
    // formData.append('quantity', this.quantity || '1');
    formData.append('quantity', this.formData.quantity !== null && this.formData.quantity !== undefined ? this.formData.quantity.toString() : '1');
    formData.append('boardLayer', this.QuoteLayer != null ? this.QuoteLayer.toString() : '2');
    formData.append('material', this.QuoteMaterial || 'FR-4 Tg 130-140');
    // formData.append('pcb_quantity', this.quantityNoOf || '1');
    formData.append('pcb_quantity', this.quantityNoOf !== null && this.quantityNoOf !== undefined ? this.quantityNoOf.toString() : '1');
    formData.append('thickness', this.selectedThickness || '1.6');
    formData.append('surfaceFinish', this.QuoteSurface || 'HASL Lead');
    formData.append('finishedCopper', this.selectedFinishCooper || '35');
    formData.append('solderMask', this.solderMaskColor || 'Green');
    formData.append('legend', this.legendColor || 'White');
    formData.append('file', this.offerLetter || '');
    // formData.append('autoSetMinPrice', 'true' || '');
    formData.append('order_type', this.orderTypeValue || 'standard');
    formData.append('panel_pieces', this.fullPanels || '0');
    formData.append('il_copper', this.selectedILCooler || '0');

    this.server.createQuote(formData).subscribe((res: any) => {
      this.spinner.hide();
      // console.log(res, 'resssssssss');
      if (res.statusCode == 200) {
        this.quoteData = res.result;
        console.log(this.quoteData, 'quoteData');
        this.quoteId = this.quoteData.quote_id;
        this.grandTotal = '50000'
        console.log(this.quoteId, 'quoteData');
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        // this.router.navigate(['/shipping']);
        this.router.navigate(['/shipping'], {
          queryParams: {
            quoteId: this.quoteId,
            // grandTotal: this.caluculatedData.grandTotal,
          }
        });
        this.createQuoteForm.reset();
        localStorage.removeItem('formData');
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
    });
  }

  AddToCartBtnOld(form: any) {
    if (this.caluculatedData?.formulaPrice < 1000) {
      this.snackBar.open('Minimum order value (WTC) 1000', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-style']
      });
      return;
    }
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.router.navigate(['/signin']);
      return;
    }
    const formData = new FormData();
    console.log('Form Data Before:', form);
    formData.append('pcbName', form.pcbName || '');
    formData.append('pcbType', this.PCBType || '');
    formData.append('user_id', localStorage.getItem('userId') || '');
    formData.append('pcbRows', this.lengthMM1 || '0');
    formData.append('pcbColumns', this.widthMM1 || '0');
    formData.append('length', this.lengthMM || '0');
    formData.append('width', this.widthMM || '0');
    formData.append('type', this.selectedSize || this.selectedSize1 || 'mm');
    // formData.append('quantity', this.quantity || '1');
    formData.append('quantity', this.quantity !== null && this.quantity !== undefined ? this.quantity.toString() : '1');
    formData.append('boardLayer', this.QuoteLayer != null ? this.QuoteLayer.toString() : '2');
    formData.append('material', this.QuoteMaterial || 'FR-4 Tg 130-140');
    // formData.append('pcb_quantity', this.quantityNoOf || '0');
    formData.append('pcb_quantity', this.quantityNoOf !== null && this.quantityNoOf !== undefined ? this.quantityNoOf.toString() : '1');
    formData.append('thickness', this.selectedThickness || '1.6');
    formData.append('surfaceFinish', this.QuoteSurface || 'HASL Lead');
    formData.append('finishedCopper', this.selectedFinishCooper || '35');
    formData.append('solderMask', this.solderMaskColor || 'Green');
    formData.append('legend', this.legendColor || 'White');
    formData.append('file', this.offerLetter || '');
    formData.append('order_type', this.orderTypeValue || 'standard');
    formData.append('panel_pieces', this.fullPanels || '0');
    // formData.append('autoSetMinPrice', 'true' || '');

    // console.log(formData, 'create quote form')
    this.server.createQuote(formData).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resssssssss');
      if (res.statusCode == 200) {
        this.quoteData = res.result;
        console.log(this.quoteData, 'quoteData');
        this.quoteId = this.quoteData.id;
        console.log(this.quoteId, 'quoteDataid');
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      this.cartStatusUpdate();
    });
  }


  AddToCartBtn(form: any) {
    console.log(form, 'formmj')
    if (this.caluculatedData?.formulaPrice < 1000) {
      this.snackBar.open('Minimum order value (WTC) 1000', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-style']
      });
      return;
    }
    const userId = localStorage.getItem('userId');
    if (!userId) {
      const saveData = {
        pcbName: form.pcbName,
        PCBType: this.PCBType,
        quantity: form.quantity,
        boardLayer: this.QuoteLayer,
        material: this.QuoteMaterial,
        thickness: this.selectedThickness,
        surfaceFinish: this.QuoteSurface,
        finishedCopper: this.selectedFinishCooper,
        solderMask: this.solderMaskColor,
        legend: this.legendColor,
        panel_pieces: this.fullPanels,
        pcb_quantity: this.quantityNoOf,
        pcbRows: this.lengthMM1,
        pcbColumns: this.widthMM1,
        length: this.lengthMM,
        width: this.widthMM,
        type: this.selectedSize,
        order_type: this.orderTypeValue,
        il_copper: this.selectedILCooler
      };
      localStorage.setItem('formData', JSON.stringify(saveData));
      // localStorage.setItem('formData', JSON.stringify(form));
      this.router.navigate(['/signin']);
      return;
    }
    const formData = new FormData();
    console.log('Form Data Before:', form);
    formData.append('pcbName', form.pcbName || '');
    formData.append('pcbType', this.PCBType || '');
    formData.append('user_id', localStorage.getItem('userId') || '');
    formData.append('pcbRows', this.lengthMM1 || '0');
    formData.append('pcbColumns', this.widthMM1 || '0');
    formData.append('length', this.lengthMM || '0');
    formData.append('width', this.widthMM || '0');
    formData.append('type', this.selectedSize || this.selectedSize1 || 'mm');
    formData.append('quantity', this.formData.quantity !== null && this.formData.quantity !== undefined ? this.formData.quantity.toString() : '1');
    formData.append('boardLayer', this.QuoteLayer != null ? this.QuoteLayer.toString() : '2');
    formData.append('material', this.QuoteMaterial || 'FR-4 Tg 130-140');
    formData.append('pcb_quantity', this.quantityNoOf !== null && this.quantityNoOf !== undefined ? this.quantityNoOf.toString() : '1');
    formData.append('thickness', this.selectedThickness || '1.6');
    formData.append('surfaceFinish', this.QuoteSurface || 'HASL Lead');
    formData.append('finishedCopper', this.selectedFinishCooper || '35');
    formData.append('solderMask', this.solderMaskColor || 'Green');
    formData.append('legend', this.legendColor || 'White');
    formData.append('file', this.offerLetter || '');
    formData.append('order_type', this.orderTypeValue || 'standard');
    formData.append('panel_pieces', this.fullPanels || '0');
    formData.append('il_copper', this.selectedILCooler || '0');


    this.server.createQuote(formData).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resssssssss');
      if (res.statusCode == 200) {
        this.quoteData = res.result;
        console.log(this.quoteData, 'quoteData');
        this.quoteId = this.quoteData.id;
        console.log(this.quoteId, 'quoteDataid');
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.createQuoteForm.reset();
        localStorage.removeItem('formData');
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      this.cartStatusUpdate();
    });
  }

  EmailQuote(form: any) {
    if (this.caluculatedData?.formulaPrice < 1000) {
      // show error msg
      this.snackBar.open('Minimum order value (WTC) 1000', 'x', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['custom-style']
      });
      // const dialogRef = this.dialog.open(this.OrderValueDialog);
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('Dialog closed with result:', result);
      // });
      return; // Stop further execution
    }
    if (this.isFileSelected && this.quantity && this.quantityNoOf && this.createQuoteForm.valid) {
      // Access the form here
      console.log('Form submitted with value:', this.createQuoteForm.value);
    }
    const userId = localStorage.getItem('userId');
    if (!userId) {
      const saveData = {
        pcbName: form.pcbName,
        PCBType: this.PCBType,
        quantity: form.quantity,
        boardLayer: this.QuoteLayer,
        material: this.QuoteMaterial,
        thickness: this.selectedThickness,
        surfaceFinish: this.QuoteSurface,
        finishedCopper: this.selectedFinishCooper,
        solderMask: this.solderMaskColor,
        legend: this.legendColor,
        panel_pieces: this.fullPanels,
        pcb_quantity: this.quantityNoOf,
        pcbRows: this.lengthMM1,
        pcbColumns: this.widthMM1,
        length: this.lengthMM,
        width: this.widthMM,
        type: this.selectedSize,
        order_type: this.orderTypeValue,
        il_copper: this.selectedILCooler
      };
      localStorage.setItem('formData', JSON.stringify(saveData));
      // localStorage.setItem('formData', JSON.stringify(form));
      this.router.navigate(['/signin']);
      return;
    }

    // let obj = {
    //   pcbName: form.pcbName || '',
    //   pcbType: this.PCBType,
    //   user_id: localStorage.getItem('userId'),
    //   pcbRows: form.pcbRows || '0',
    //   pcbColumns: form.pcbColumns || '0',
    //   length: this.lengthMM || '0',
    //   width: this.widthMM || '0',
    //   quantity: this.quantity || '',
    //   boardLayer: this.QuoteLayer || '1',
    //   material: this.QuoteMaterial || 'FR-4',
    //   pcb_quantity: this.quantityNoOf || '0',
    //   // thickness: this.selectedThickness || '1.6',
    //   thickness: this.selectedThickness || '1.6',
    //   surfaceFinish: this.QuoteSurface || 'HASL With Lead',
    //   // finishedCopper: this.selectedFinishCooper || '135u',
    //   finishedCopper: this.selectedFinishCooper|| '135u',
    //   solderMask: this.solderMaskColor || 'Green',
    //   legend: this.legendColor || 'White',
    //   gerberFile: this.offerLetter || '',
    // }

    const formData = new FormData();
    console.log('Form Data Before:', form);
    formData.append('pcbName', form.pcbName || '');
    formData.append('pcbType', this.PCBType || '');
    formData.append('user_id', localStorage.getItem('userId') || '');
    formData.append('pcbRows', this.lengthMM1 || '0');
    formData.append('pcbColumns', this.widthMM1 || '0');
    formData.append('length', this.lengthMM || '0');
    formData.append('width', this.widthMM || '0');
    // formData.append('type', this.selectedSize || 'mm');
    formData.append('type', this.selectedSize || this.selectedSize1 || 'mm');
    // formData.append('quantity', this.quantity || '1');
    formData.append('quantity', this.formData.quantity !== null && this.formData.quantity !== undefined ? this.formData.quantity.toString() : '1');
    formData.append('boardLayer', this.QuoteLayer != null ? this.QuoteLayer.toString() : '2');
    formData.append('material', this.QuoteMaterial || 'FR-4 Tg 130-140');
    // formData.append('pcb_quantity', this.quantityNoOf || '0');
    formData.append('pcb_quantity', this.quantityNoOf !== null && this.quantityNoOf !== undefined ? this.quantityNoOf.toString() : '1');
    formData.append('thickness', this.selectedThickness || '1.6');
    formData.append('surfaceFinish', this.QuoteSurface || 'HASL Lead');
    formData.append('finishedCopper', this.selectedFinishCooper || '35');
    formData.append('solderMask', this.solderMaskColor || 'Green');
    formData.append('legend', this.legendColor || 'White');
    formData.append('file', this.offerLetter || '');
    // formData.append('autoSetMinPrice', 'true' || '');
    formData.append('order_type', this.orderTypeValue || 'standard');
    formData.append('panel_pieces', this.fullPanels || '0');
    formData.append('il_copper', this.selectedILCooler || '0');

    // console.log(obj, 'create quote form')
    this.server.createQuote(formData).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res, 'resssssssss');
      // this.quoteData = res.result;
      // console.log(this.quoteData, 'quoteData');
      // this.quoteId = this.quoteData.id;
      // console.log(this.quoteId, 'quoteData');

      if (res.statusCode == 200) {
        this.quoteData = res.result;
        console.log(this.quoteData, 'quoteData');
        this.quoteId = this.quoteData.id;
        console.log(this.quoteId, 'quoteData');
        // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
        this.createQuoteForm.reset();
        localStorage.removeItem('formData');
      } else if (res.statusCode == 400) {
        this.snackBar.open(res.message, 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      } else {
        this.snackBar.open('Something went wrong', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['custom-style']
        });
      }
      // this.QuoteStatusUpdate();
      // this.QuoteAQuoteData();
      this.sendQuoteMail();
    });
  }

  sendQuoteMail() {
    let obj = {
      id: this.quoteId,
      email: localStorage.getItem('userEmail'),
    }
    console.log(obj, 'sendQuoteMail obj');
    this.server.quotedesignSendQuoteMail(obj).subscribe((res: any) => {
      console.log(res, 'resquotedesignSendQuoteMail')
    })
    // this.router.navigate(['/quote']);
    this.QuoteAQuoteData();
  }

  selectOption11(selectedValue: string, form: any) {
    this.spinner.show();
    console.log(form, 'fffffff')
    console.log('Selected value:', selectedValue);
    this.selectedOption = selectedValue; // Update the selected option
    // You can perform further actions based on the selected value
    if (this.selectedOption === 'Order Now') {
      console.log(form, 'form data')
      let obj = {
        pcbName: form.pcbName || '',
        pcbType: this.PCBType,
        user_id: localStorage.getItem('userId'),
        pcbRows: form.pcbRows || '0',
        pcbColumns: form.pcbColumns || '0',
        length: form.length || '0',
        width: form.width || '0',
        quantity: form.quantity || '',
        boardLayer: this.QuoteLayer || '2',
        material: this.QuoteMaterial || 'Tg 130-140',
        pcb_quantity: this.quantityNoOf || '0',
        thickness: this.selectedThickness || '1.6',
        surfaceFinish: this.QuoteSurface || 'HASL Lead',
        finishedCopper: this.selectedFinishCooper || '35',
        solderMask: this.solderMaskColor || 'Green',
        legend: this.legendColor || 'White',
        gerberFile: this.offerLetter || '',
      }
      console.log(obj, 'create quote form')
      this.server.createQuote(obj).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'resssssssss');
        if (res.statusCode == 200) {
          this.quoteData = res.result;
          console.log(this.quoteData, 'quoteData');
          this.quoteId = this.quoteData.id;
          console.log(this.quoteId, 'quoteData');
          // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          this.router.navigate(['/shipping']);
        } else if (res.statusCode == 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
        // this.cartStatusUpdate();
      });

      // this.snackBar.open('Instant Order', 'x', {
      //   duration: 3000,
      //   verticalPosition: 'top',
      //   panelClass: ['custom-style']
      // });
    } else if (this.selectedOption === 'Add to Cart') {
      // this.createQuotes(selectedValue);
      console.log(form, 'form data')
      let obj = {
        pcbName: form.pcbName || '',
        pcbType: this.PCBType || '',
        user_id: localStorage.getItem('userId'),
        pcbRows: form.pcbRows || '0',
        pcbColumns: form.pcbColumns || '0',
        length: this.lengthMM || '0',
        width: this.widthMM || '0',
        quantity: this.quantity || '1',
        boardLayer: this.QuoteLayer || '2',
        material: this.QuoteMaterial || 'Tg 130-140',
        pcb_quantity: this.quantityNoOf || '0',
        thickness: this.selectedThickness || '1.6',
        surfaceFinish: this.QuoteSurface || 'HASL Lead',
        finishedCopper: this.selectedFinishCooper || '35',
        solderMask: this.solderMaskColor || 'Green',
        legend: this.legendColor || 'White',
        gerberFile: this.offerLetter || '',
      }
      console.log(obj, 'create quote form')
      this.server.createQuote(obj).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'resssssssss');
        // this.quoteData = res.result;
        // console.log(this.quoteData, 'quoteData');
        // this.quoteId = this.quoteData.id;
        // console.log(this.quoteId, 'quoteData');

        if (res.statusCode == 200) {
          this.quoteData = res.result;
          console.log(this.quoteData, 'quoteData');
          this.quoteId = this.quoteData.id;
          console.log(this.quoteId, 'quoteData');
          // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          // this.router.navigate(['/home']);
        } else if (res.statusCode == 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
        this.cartStatusUpdate();
      });

      // this.server.PcbPriceCalculation(obj).subscribe((res: any) => {
      //   console.log(res, 'ressssss');
      //   this.caluculatedData = res.result;
      //   console.log(this.caluculatedData, 'this.caluculatedDataaaa');
      // })

    } else if (this.selectedOption === 'Email a Quote') {
      // this.snackBar.open('Email a Quote', 'x', {
      //   duration: 3000,
      //   verticalPosition: 'top',
      //   panelClass: ['custom-style']
      // });
      console.log(form, 'form data')
      let obj = {
        pcbName: form.pcbName || '',
        pcbType: this.PCBType,
        user_id: localStorage.getItem('userId'),
        pcbRows: form.pcbRows || '0',
        pcbColumns: form.pcbColumns || '0',
        length: this.lengthMM || '0',
        width: this.widthMM || '0',
        quantity: this.quantity || '',
        boardLayer: this.QuoteLayer || '2',
        material: this.QuoteMaterial || 'Tg 130-140',
        pcb_quantity: this.quantityNoOf || '0',
        // thickness: this.selectedThickness || '1.6',
        thickness: this.selectedThickness || '1.6',
        surfaceFinish: this.QuoteSurface || 'HASL Lead',
        // finishedCopper: this.selectedFinishCooper || '135u',
        finishedCopper: this.selectedFinishCooper || '35',
        solderMask: this.solderMaskColor || 'Green',
        legend: this.legendColor || 'White',
        gerberFile: this.offerLetter || '',
      }
      console.log(obj, 'create quote form')
      this.server.createQuote(obj).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'resssssssss');
        // this.quoteData = res.result;
        // console.log(this.quoteData, 'quoteData');
        // this.quoteId = this.quoteData.id;
        // console.log(this.quoteId, 'quoteData');

        if (res.statusCode == 200) {
          this.quoteData = res.result;
          console.log(this.quoteData, 'quoteData');
          this.quoteId = this.quoteData.id;
          console.log(this.quoteId, 'quoteData');
          // this.snackBar.open(" Success : New Candidate Created Successfully...", 'x', {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
          // this.router.navigate(['/quote']);
        } else if (res.statusCode == 400) {
          this.snackBar.open(res.message, 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        } else {
          this.snackBar.open('Something went wrong', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['custom-style']
          });
        }
        // this.QuoteStatusUpdate();
        this.QuoteAQuoteData();
      });

      // this.server.PcbPriceCalculation(obj).subscribe((res: any) => {
      //   console.log(res, 'ressssss');
      //   this.caluculatedData = res.result;
      //   console.log(this.caluculatedData, 'this.caluculatedDataaaa');
      // })

    }
  }


  toggleInputFieldsFinishCooper(cooper: string) {
    this.selectedFinishCooper = cooper;
    console.log(this.selectedFinishCooper, 'this.selectedFinishCooper')
    // Set default thickness to '1.6' when '135u' is selected for Finishead Cooper
    if (cooper === '130') {
      this.selectedThickness = '1.6';
      console.log(this.selectedThickness, 'thicknesss')
    }
    this.dataaa();
  }

  toggleInputFieldsILCooler(cooler: string) {
    this.selectedILCooler = cooler;
    console.log(this.selectedILCooler, 'this.selectedILCooler')

    // if (cooler === '130') {
    //   this.selectedThickness = '1.6';
    //   console.log(this.selectedThickness, 'thicknesss')
    // }
    this.dataaa();
  }

  // toggleInputFieldsMaterial(material: string) {
  //   this.selectedMaterial = material;
  //   console.log(this.selectedMaterial, 'this.selectedMaterial')
  // }

  toggleInputFieldsThickness(thickness: string) {
    this.selectedThickness = thickness;
    console.log(this.selectedThickness, 'tttttttt')
    this.dataaa();
  }


  toggleInputFieldsColor(color: string) {
    this.solderMaskColor = color;
    console.log(this.solderMaskColor, 'solderMaskColor color');

    // Check if the selected color is white or black
    if (color === 'White') {
      // Disable white legend option
      this.disableWhiteLegend = true;
      // Set Legend color to something else (e.g., Black)
      this.legendColor = 'Black'; // or any other color you prefer
      console.log(this.legendColor, 'legend select');
    } else if (color === 'Black') {
      // Disable black legend option
      this.disableBlackLegend = true;
      // Set Legend color to something else (e.g., White)
      this.legendColor = 'White'; // or any other color you prefer
      console.log(this.legendColor, 'legend select');
    } else {
      // Enable legend options
      this.disableWhiteLegend = false;
      this.disableBlackLegend = false;
    }
    this.dataaa();
  }

  toggleInputFieldsLegend(color: string) {
    this.legendColor = color;
    console.log(this.legendColor, 'legend color');
  }

  //   validateQuantity() {
  //     if (this.quantityNoOf === 0 || isNaN(this.quantityNoOf)) {
  //         this.quantityNoOf = null; // Reset to null if equal to 0 or NaN
  //     }
  // }


  openDialogWithTemplateRefMinimumOrderValue(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '1000px',
      height: '430px',
      panelClass: 'my-panelColor',
    });
  }

  onProceedClick() {
    this.showPrice = true
    console.log(this.showPrice, 'showpriceeeeee')
    this.dataaa();
    // if (this.showPrice = true) {
    //   this.calculatedData.formulaPrice = 1000
    //   console.log(this.calculatedData.formulaPrice, 'showwwwwwwwwwww')
    //   this.dataaa();
    // }
  }


  onStandardChange1(event: any) {
    if (event.checked) {
      this.isPremiumChecked = false;
      console.log(this.isPremiumChecked, 'isPremiumChecked');
      console.log(this.isStandardChecked, 'isStandardChecked');

    }
  }

  onPremiumChange1(event: any) {
    if (event.checked) {
      this.isStandardChecked = false;
      console.log(this.isPremiumChecked, 'isPremiumChecked');
      console.log(this.isStandardChecked, 'isStandardChecked');

    }
  }


  onStandardChange(event: any) {
    if (event.checked) {
      this.isPremiumChecked = false;
      this.orderTypeValue = 'standard';
    } else {
      this.orderTypeValue = '';
    }
    console.log(this.isPremiumChecked, 'isPremiumChecked');
    console.log(this.isStandardChecked, 'isStandardChecked');
    console.log(this.orderTypeValue, 'orderTypeValue');
  }

  onPremiumChange(event: any) {
    if (event.checked) {
      this.isStandardChecked = false;
      this.orderTypeValue = 'premium';
    } else {
      this.orderTypeValue = '';
    }
    console.log(this.isPremiumChecked, 'isPremiumChecked');
    console.log(this.isStandardChecked, 'isStandardChecked');
    console.log(this.orderTypeValue, 'orderTypeValue');
  }

  applyValidators(): void {
    console.log('ffffffffff')
    if (this.quantityNoOf && this.quantityInput) {
      const validators = [multipleOfValidator(this.quantityNoOf)];
      this.quantityInput.control.setValidators(validators);
      this.quantityInput.control.updateValueAndValidity();
      this.calculatePanelsAndRemainder();
    }
  }


  calculateRemainder11() {
    if (this.quantity !== null && this.quantityNoOf !== null && this.quantityNoOf > 0) {
      this.remainder = this.quantity % this.quantityNoOf;
      console.log(this.remainder, 'ree');
    } else {
      this.remainder = 0; // Handle invalid input
      console.log(this.remainder, 'ree 0')

    }
  }

  calculatePanelsAndRemainder() {
    if (this.formData.quantity !== null && this.quantityNoOf !== null && this.quantityNoOf > 0) {
      this.fullPanels = Math.floor(this.formData.quantity / this.quantityNoOf);
      this.remainder = this.formData.quantity % this.piecesPerPanel;
      console.log(`Full panels: ${this.fullPanels}, Remainder: ${this.remainder}`);
    } else {
      this.fullPanels = ''; // Handle invalid input
      this.remainder = null;
      console.log('Invalid input');
    }

  }

  checkVolume() {
    if (this.caluculatedDataa?.volume > 0.5) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  openPrivacyPolicy1() {
    this.dialogRef = this.dialog.open(this.PrivacyAndPolicyDialog, {
      height: '400px', // or any desired fixed height
      width: '800px',  // Optional: set a fixed width
      disableClose: true // Ensures the dialog won't close automatically
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogContent.nativeElement.scrollTop = 0; // Reset scroll to top
      console.log('Dialog closed with result:', result);
    });
  }

  onProceedClick1() {
    // Logic when "PROCEED" is clicked
    console.log('Proceed clicked');
    this.dialogRef.close('proceed');
  }


  updatePrivacyStatus() {
    this.isPrivacyAccepted = !this.isPrivacyAccepted;
    this.cd.detectChanges();  // Force a manual change detection
  }


}
