<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav class="side" [mode]="isMobile ? 'over' : 'side'" [opened]="!isMobile">
        <!-- <mat-toolbar>Menu</mat-toolbar> -->
        <mat-nav-list>
            <div class="card-container">
                <!-- <div class="profilePic"></div> -->
                <div class="camera-container">
                    <img [src]="url" class="profilePic">
                    <img src="../../assets/images/addImage.svg" class="camera" (click)="openFilePicker()">
                    <input type="file" id="fileInput" (change)="onSelect($event)" style="display: none;" />
                </div>
                <div class="name">{{name}}</div>
                <div class="ID">ID: {{userId}}</div>
            </div>
            <div class="buttons">
                <button class="btn" [ngClass]="{'active': selectedItem === 'dashboard'}"
                    (click)="selectItem('dashboard')">
                    Dashboard
                    <span>
                        <img [src]="selectedItem === 'dashboard' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'priceList'}"
                    (click)="selectItem('priceList')">
                    Price List
                    <span>
                        <img [src]="selectedItem === 'priceList' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/profile'}"
                    (click)="selectItem('admin/profile')">
                    My Profile
                    <span>
                        <img [src]="selectedItem === 'admin/profile' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>

                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/calendar'}"
                    (click)="selectItem('admin/calendar')">
                    Holiday Calendar
                    <span>
                        <img [src]="selectedItem === 'admin/calendar' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                            class="arrow">
                    </span>
                </button>
                <button class="btn1" [ngClass]="{'active': selectedItem === 'admin/status'}"
                (click)="selectItem('admin/status')">
                Status
                <span>
                    <img [src]="selectedItem === 'admin/status' ? '../../assets/images/rightArrow.svg' : '../../assets/images/blackArrow.svg'"
                        class="arrow">
                </span>
              </button>
            </div>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sideContent">
        <mat-toolbar>
            <button mat-icon-button (click)="sidenav.toggle()" class="menuBtn">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>
        <div class="main-content">
            <div class="main">
                <div class="welcome">Hii, Welcome Back!</div>
                <div class="calender">
                    <img src="../../assets/images/calender.svg">
                    <div class="time">{{ currentDateTime }}</div>
                </div>
            </div>
            <!-- Dashboard content here -->
            <div *ngIf="selectedItem === 'dashboard'">
                <!-- <div class="searchField">
                    <div class="inputContainer">
                        <input type="text" class="inputSearch" placeholder="Search...">
                        <button class="Search">Search</button>
                    </div>
                </div> -->
                <div class="pannel mt-2">
                    <mat-accordion [multi]="false">
                        <div class="pannel">
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Registrations</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                     <!-- <table mat-table [dataSource]="dataSource" class="responsive-table">
                                        <ng-container matColumnDef="sno" class="sno">
                                            <th class="SNO" mat-header-cell *matHeaderCellDef> S.No. </th>
                                            <td mat-cell *matCellDef="let element; let i = index" data-label="S.No.">
                                                {{ calculateSno(i) }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef> Name </th>
                                            <td mat-cell *matCellDef="let element" data-label="Name"> {{element.name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="mobilenumber">
                                            <th mat-header-cell *matHeaderCellDef> Mobile Number </th>
                                            <td mat-cell *matCellDef="let element" data-label="Mobile Number">
                                                {{element.mobilenumber}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="email">
                                            <th mat-header-cell *matHeaderCellDef class="emailText"> Email </th>
                                            <td mat-cell *matCellDef="let element" data-label="Email"> {{element.email}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="companyname">
                                            <th mat-header-cell *matHeaderCellDef> Company Name </th>
                                            <td mat-cell *matCellDef="let element" data-label="Company Name">
                                                {{element.companyname}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="place">
                                            <th mat-header-cell *matHeaderCellDef> Place </th>
                                            <td mat-cell *matCellDef="let element" data-label="Place"> {{element.place}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="viewProfile">
                                            <th mat-header-cell *matHeaderCellDef class="view"></th>
                                            <td mat-cell *matCellDef="let element" data-label="View Profile">
                                                <a href="" class="profileview">{{element.viewProfile}}</a>
                                            </td>

                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>  -->


                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header" style="width: 10%;">S.No</th>
                                                <th class="gradient-header" style="width: 35%;">Name</th>
                                                <th class="gradient-header" style="width: 45%;">Email</th>
                                                <th class="gradient-header" style="width: 10%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of RegisterData; let i = index;">
                                                <td>{{i + 1}}</td>
                                                <td>{{item.firstName}}</td>
                                                <td>{{item.email}}</td>
                                                <!-- <td><button mat-button>View</button></td> -->
                                                <td><button mat-button (click)="registrationDetails(item)">View</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    

                                    <mat-paginator [length]="totalUsers" [pageSize]="pageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChange($event)"></mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <!-- </mat-accordion> -->
                        </div>

                        <div class="pannel">
                            <!-- <mat-accordion> -->
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Quotations</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <!-- <table mat-table [dataSource]="dataSourceQuotations" class="responsive-table">
                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef> S.No. </th>
                                            <td mat-cell *matCellDef="let element; let i = index" data-label="S.No.">
                                                {{ quotecalculateSno(i) }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="quotenumber">
                                            <th mat-header-cell *matHeaderCellDef> Quote Number </th>
                                            <td mat-cell *matCellDef="let element" data-label="Quote Number">
                                                {{element.quotenumber}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="pcbname">
                                            <th mat-header-cell *matHeaderCellDef> PCB Name </th>
                                            <td mat-cell *matCellDef="let element" data-label="PCB Name">
                                                {{element.pcbname}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="orderdate">
                                            <th mat-header-cell *matHeaderCellDef> Order Date </th>
                                            <td mat-cell *matCellDef="let element" data-label="Order Date">
                                                {{element.orderdate | date}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="deliverydate">
                                            <th mat-header-cell *matHeaderCellDef> Delivery Date </th>
                                            <td mat-cell *matCellDef="let element" data-label="Delivery Date">
                                                {{element.deliverydate | date}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef> Status </th>
                                            <td mat-cell *matCellDef="let element" data-label="Status">
                                                {{element.status}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="viewDetails">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element" data-label="View Details">
                                                <a href="" class="profileview"> {{element.viewDetails}} </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                    </table> -->


                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header" style="width: 10%;  text-align: center;">S.No</th>
                                                <th class="gradient-header" style="width: 10%;">Quote No</th>
                                                <th class="gradient-header" style="width: 30%;">PCB Name</th>
                                                <th class="gradient-header" style="width: 15%;">Order Date</th>
                                                <th class="gradient-header" matTooltip="Delivery Date" style="width: 15%;">D' Date</th>
                                                <th class="gradient-header" style="width: 15%;">Status</th>
                                                <th class="gradient-header" style="width: 5%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of quotationsData; let i = index;">
                                                <!-- <td>{{i + 1}}</td> -->
                                                <td>{{ i + 1 + (QuotecurrentPage * QuotepageSize) }}</td>
                                                <td>{{item.quote_id}}</td>
                                                <td>{{item.pcbName}}</td>
                                                <td>{{item.order_date  | date: "mediumDate"}}</td>
                                                <td>{{item.delivery_date  | date: "mediumDate"}}</td>
                                                <td [style.color]="item.order_status === 'pending' ? 'red' : 'green'">
                                                    {{ formatStatusOrders(item.order_status) }}
                                                  </td>                                                  
                                                <td><button mat-button (click)="QtatationsDetails(item)">View</button></td>
                                            </tr>
                                        </tbody>
                                    </table>




                                    <mat-paginator [length]="totalUsersForQuotes" [pageSize]="QuotepageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChangeQuote($event)">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                            <!-- </mat-accordion> -->
                        </div>

                        <div class="pannel">
                            <!-- <mat-accordion> -->
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Total Number of Orders</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <!-- <table mat-table [dataSource]="dataSourceOrders" class="responsive-table">
                                        <ng-container matColumnDef="sno" class="sno">
                                            <th class="SNO" mat-header-cell *matHeaderCellDef> S.No. </th>
                                            <td mat-cell *matCellDef="let element; let i = index" data-label="S.No.">
                                                {{ ordercalculateSno(i) }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="orderid">
                                            <th mat-header-cell *matHeaderCellDef> Order ID </th>
                                            <td style="cursor: pointer;" (click)="rowData(element)" mat-cell *matCellDef="let element"
                                                data-label="Order ID" class="orderId">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v240h-80v-200H520v-200H240v640h360v80H240Zm638 15L760-183v89h-80v-226h226v80h-90l118 118-56 57Zm-638-95v-640 640Z"/></svg>
                                                {{element.orderid}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="orderdate">
                                            <th mat-header-cell *matHeaderCellDef> Order Date </th>
                                            <td mat-cell *matCellDef="let element" data-label="Order Date">
                                                {{element.orderdate | date: "mediumDate"}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="orderdetails">
                                            <th mat-header-cell *matHeaderCellDef> Order Details </th>
                                            <td mat-cell *matCellDef="let element" data-label="Order Details">
                                                {{element.orderdetails}} </td>
                                        </ng-container> 
                                         <ng-container matColumnDef="customerdetails">
                                            <th mat-header-cell *matHeaderCellDef> Customer Details </th>
                                            <td mat-cell *matCellDef="let element" data-label="Customer Details">
                                                {{element.customerdetails}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="trackid">
                                            <th mat-header-cell *matHeaderCellDef> Tracking Id </th>
                                            <td mat-cell *matCellDef="let element" data-label="Tracking Id">
                                                {{element.trackid || '-' }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="trackurl">
                                            <th mat-header-cell *matHeaderCellDef> Tracking url </th>
                                            <td mat-cell *matCellDef="let element" data-label="Tracking url">
                                                {{element.trackurl || '-' }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef> Status </th>
                                            <td mat-cell *matCellDef="let element" data-label="Status">
                                                {{ formatStatus(element.status) }}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                    </table> -->


                                    <table class="registration_table" *ngIf="selectedItem === 'dashboard'">
                                        <thead>
                                            <tr>
                                                <th class="gradient-header" style="width: 10%;">S.No</th>
                                                <th  class="gradient-header" style="width: 10%;">Order ID</th>
                                                <th class="gradient-header" style="width: 20%;">Order Date</th>
                                                <!-- <th class="gradient-header" style="width: 10%;">Order Details</th> -->
                                                <!-- <th class="gradient-header" style="width: 12%;">Customer Details</th> -->
                                                <th class="gradient-header" style="width: 10%;">Tracking Id</th>
                                                <th class="gradient-header" style="width: 15%;">Tracking url</th>
                                                <th class="gradient-header" style="width: 20%;">Status</th>
                                                <th class="gradient-header" style="width: 5%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-2" *ngFor="let item of completedOrderDetails; let i = index;">
                                                <!-- {{item | json}} -->
                                                <td>{{ i + 1 + ((OrdercurrentPage - 1) * OrderpageSize) }}</td>
                                                <td>{{item.quote_id}}</td>
                                                <td>{{item.order_date | date: "mediumDate"}}</td>
                                                <!-- <td>details</td> -->
                                                <!-- <td style="cursor: pointer;" (click)="rowData1(item)" matTooltip="{{item.track_id}}">{{item.track_id || 'add track id'}}</td> -->
                                                <!-- <td style="cursor: pointer;" (click)="rowData2(item)" matTooltip="{{item.track_url}}">{{item.track_url | slice:0:20 || 'add track url'}}</td> -->
                                                <td style="cursor: pointer;" matTooltip="{{item.track_id}}" (click)="rowData1(item)">
                                                    <span *ngIf="item.track_id; else addTrack">
                                                      {{item.track_id}}
                                                    </span>
                                                    <ng-template #addTrack>
                                                        <button mat-button class="add-track-btn" >Add</button>
                                                    </ng-template>
                                                  </td>

                                                  <td style="cursor: pointer;" matTooltip="{{item.track_url}}" (click)="rowData2(item)">
                                                    <span *ngIf="item.track_url; else addTrackUrl" >
                                                      {{item.track_url | slice:0:20}}
                                                    </span>
                                                    <ng-template #addTrackUrl>
                                                      <button mat-button class="add-track-btn">Add</button>
                                                    </ng-template>
                                                  </td>
                                                  
                                                  
                                                <td [style.color]="item.order_status === 'pending' ? 'red' : 'green'" (click)="rowData(item)">{{ formatStatusOrders(item.order_status) }}</td>
                                                <td><button mat-button (click)="orderDetailsData(item)">View</button></td>

                                            </tr>
                                        </tbody>
                                    </table>

                                    <mat-paginator [length]="totalUsersForOrder" [pageSize]="OrderpageSize"
                                        [pageSizeOptions]="[10, 20, 30]" (page)="onPageChangeOrder($event)">
                                    </mat-paginator>
                                </div>
                            </mat-expansion-panel>
                          
                        </div>

                        <div class="pannel">
                            <!-- <mat-accordion> -->
                            <mat-expansion-panel class="mainPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="paneltitle">Price Details</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="mat-elevation-z8">
                                    <div class="Price">
                                        <img src="../../assets/images/calender.svg" class="calenderPic">
                                        <div class="fromTo">From</div>
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="startDate" [matDatepicker]="picker1">
                                            <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                        <div class="fromTo">To</div>
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="endDate" [matDatepicker]="picker2">
                                            <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                        <button class="searchbtn" (click)="searchPriceHistory()">Search</button>
                                    </div>
                                    <table mat-table [dataSource]="dataSourcePrice" class="responsive-table">
                                        <ng-container matColumnDef="sno">
                                            <th class="SNO" mat-header-cell *matHeaderCellDef> S.No</th>
                                            <!-- <td mat-cell *matCellDef="let element" data-label="S.No."> {{element.sno}}
                                            </td> -->
                                            <td mat-cell *matCellDef="let element; let i = index" data-label="S.No.">
                                                {{ priceHistorycalculateSno(i) }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef> Category </th>
                                            <td mat-cell *matCellDef="let element" data-label="Category">
                                                {{element.category}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="subcategory">
                                            <th mat-header-cell *matHeaderCellDef> Subcategory </th>
                                            <td mat-cell *matCellDef="let element" data-label="Subcategory">
                                                {{element.subcategory}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="layertype">
                                            <th mat-header-cell *matHeaderCellDef> Layer Type </th>
                                            <td mat-cell *matCellDef="let element" data-label="Layer Type">
                                                {{element.layertype}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="previousprice">
                                            <th mat-header-cell *matHeaderCellDef> Previous Price </th>
                                            <!-- <td mat-cell *matCellDef="let element" data-label="Previous Price">
                                                {{element.previousprice}} </td> -->
                                            <td mat-cell *matCellDef="let element" data-label="Previous Price">
                                                {{ element.previousprice ? element.previousprice : '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="changedprice">
                                            <th mat-header-cell *matHeaderCellDef> Changed Price </th>
                                            <td mat-cell *matCellDef="let element" data-label="Changed Price">
                                                {{element.changedprice}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pricechangeddate">
                                            <th mat-header-cell *matHeaderCellDef matTooltip="Price Changed Date">P'Changed Date</th>
                                            <td mat-cell *matCellDef="let element" data-label="Price Changed Date">
                                                {{element.pricechangeddate  | date: "mediumDate"}} </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                                    </table>
                                    <mat-paginator [length]="totalPriceHistoryRecords" [pageSize]="priceHistoryPageSize"
                                        [pageSizeOptions]="[5, 10, 20]" (page)="onPageChangePriceHistory($event)">
                                    </mat-paginator>

                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>
            </div>


          





            <div *ngIf="selectedItem === 'priceList'" class="priceList">
                <app-price-list></app-price-list>
            </div>
            <div *ngIf="selectedItem === 'admin/profile'">
                <app-admin-profile></app-admin-profile>
            </div>
            <div *ngIf="selectedItem === 'admin/calendar'">
                <app-holiday-calendar></app-holiday-calendar>
            </div>
            <div *ngIf="selectedItem === 'admin/status'">
                <app-admin-status></app-admin-status>
            </div>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #dialogTemplate11 let-data="data">
    <div>
        <h1 mat-dialog-title>Order ID - {{ iddss }}</h1>
        <h1 mat-dialog-title>Order ID - {{ trackid }}</h1>

        <div mat-dialog-content>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>

<!-- Dialog template -->
<ng-template #dialogTemplate let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>

        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <!-- <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Customer Name -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Order Name -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Customer Mobile Number -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Est. Delivery Date -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Customer Email Address -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Courier Partner -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Order Name -</h4>

            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <div class="shipping">
                    <h4>Shipping Address</h4>
                    <div class="address-content">
                        Sateesh<br>
                        9989022721<br>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Quantity -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <div class="shipping">
                    <h4>Billing Address</h4>
                    <div class="address-content">
                        Sateesh<br>
                        9989022721<br>

                    </div>
                </div>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Price -</h4>
            </div>
            <div class="col-6 mb-3 d-flex align-items-center">
                <h4>Pay status -</h4>
            </div> -->
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4>Order status update</h4>
                        <mat-form-field>
                          <mat-select [(ngModel)]="OrStatus" name="food" (selectionChange)="onStatusChange($event)">
                            <mat-option *ngFor="let food of foods" [value]="food.value">{{ food.viewValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      
                </div>
            </div>

            
        </div>
        <div mat-dialog-actions class="btns">
            <!-- <button mat-button mat-dialog-close>Sent a Email</button> -->
            <button mat-button mat-dialog-close (click)="updateOrderStatus()">Save</button>
            <button mat-button mat-dialog-close>Close</button>
        </div>
        </div>
</ng-template>


<ng-template #dialogTemplateTrackId let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>

        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4>Track ID Update</h4>
                          <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput [(ngModel)]="trackid" placeholder="Enter Track ID">
                      </div>
                </div>
            </div>
            
        </div>
        <div mat-dialog-actions class="btns">
            <!-- <button mat-button mat-dialog-close>Sent a Email</button> -->
            <button mat-button mat-dialog-close (click)="updateOrderStatusTrackId()">Save</button>
            <button mat-button mat-dialog-close>Close</button>
        </div>
        </div>
</ng-template>

<ng-template #dialogTemplateURL let-data="data">
    <h1 mat-dialog-title class="idOrder">Order ID - {{iddss}}</h1>
    <hr class="hrline">
    <div mat-dialog-content>

        <div class="row d-flex flex-wrap m-0 p-0 popup">
            <div class="col-12 mb-3 d-flex align-items-center">
                <div>
                    <div>
                        <h4>Tracking url update</h4>
                        <input style="padding: 10px; font-size: 16px; border-radius: 4px;" matInput [(ngModel)]="trackurl" placeholder="Enter Track URL">

                      </div>
                      
                </div>
            </div>

        </div>
        <div mat-dialog-actions class="btns">
            <!-- <button mat-button mat-dialog-close>Sent a Email</button> -->
            <button mat-button mat-dialog-close (click)="updateOrderStatusTrackUrl()">Save</button>
            <button mat-button mat-dialog-close>Close</button>
        </div>
        </div>
</ng-template>

<!-- <ng-template #registrationDataDialog class="bgdone-dialog">
    <div class="create-post-success">
        <div class="publishdialog ">
            <div class="d-flex">
                <h5>Registration Details</h5>
            </div>

        </div>
        <div class="row m-0 p-0">
            <div class="message col-12">
                <h3>Minimum order value without tooling cost more then 1000.</h3>
                <h4>Please increase quantity, size, layers...</h4>
                <div class="d-flex mt-5 mb-2 justify-content-center">
                    <button matDialogClose class="cancelBtn">
                        CANCEL
                    </button>
                    <button matDialogClose class="proceedBtn">
                        PROCEED
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #registrationDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
      <div class="publishdialog">
        <div class="d-flex">
          <h5>User Profile Details</h5>
        </div>
      </div>
  
      <div class="row m-0 p-0">
        <div class="message col-12">
          <div class="row m-0 p-0">
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">First Name:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.firstName }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Last Name:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.lastName }}</div>
              </div>
              <div class="row">
                <div class="name col-6">RegisterAs:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.registerAs }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Mobile No:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.mobileNO }}</div>
              </div>
            </div>
  
            <!-- Second Column with Address Details -->
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">Address:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.Address }}</div>
              </div>
              <div class="row">
                <div class="name col-6">City:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.city }}</div>
              </div>
              <div class="row">
                <div class="name col-6">State:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.state }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Pincode:</div>
                <div class="names col-6">{{ RegistrationUserDetailsByIdData.slc_userprofiles[0]?.pincode }}</div>
              </div>
            </div>
          </div>
  
          <!-- Dialog Buttons -->
          <div class="d-flex mt-5 mb-2 justify-content-center">
            <button matDialogClose class="cancelBtn">
              CANCEL
            </button>
            <button matDialogClose class="proceedBtn">
              PROCEED
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  



<ng-template #quatationDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
      <div class="publishdialog">
        <div class="d-flex">
          <h5>Quote Details</h5>
        </div>
      </div>
  
      <div class="row m-0 p-0">
        <div class="message col-12">
          <div class="row m-0 p-0">
            <!-- First Column with 4 items -->
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">PCB Name:</div>
                <!-- {{QuotePopupDetails | json}} -->
                <div class="names col-6">{{ QuotePopupDetails.pcbName }}</div>
              </div>
              <div class="row">
                <div class="name col-6">PCB Type:</div>
                <div class="names col-6">{{ QuotePopupDetails.pcbType }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Material:</div>
                <div class="names col-6">{{ QuotePopupDetails.material }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Thickness:</div>
                <div class="names col-6">{{ QuotePopupDetails.thickness }}</div>
              </div>
            </div>
      
            <!-- Second Column with 3 items -->
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">Quantity:</div>
                <div class="names col-6">{{ QuotePopupDetails.quantity }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Board Layer:</div>
                <div class="names col-6">{{ QuotePopupDetails.boardLayer }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Surface Finish:</div>
                <div class="names col-6">{{ QuotePopupDetails.surfaceFinish }}</div>
              </div>
            </div>
          </div>
      
          <!-- Dialog Buttons -->
          <div class="d-flex mt-5 mb-2 justify-content-center">
            <button matDialogClose class="cancelBtn">
              CANCEL
            </button>
            <button matDialogClose class="proceedBtn">
              PROCEED
            </button>
          </div>
        </div>
      </div>
      
      
    </div>
  </ng-template>
  

<ng-template #OrderDetailsDataDialog let-data="data" class="bgdone-dialog">
    <div class="create-post-success">
      <div class="publishdialog">
        <div class="d-flex">
          <h5>Order Details</h5>
        </div>
      </div>
  
      <div class="row m-0 p-0">
        <div class="message col-12">
          <div class="row m-0 p-0">
            <!-- First Column with 4 items -->
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">PCB Name:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.pcbName }}</div>
              </div>
              <div class="row">
                <div class="name col-6">PCB Type:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.pcbType }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Material:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.material }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Thickness:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.thickness }}</div>
              </div>
            </div>
      
            <!-- Second Column with 3 items -->
            <div class="col-md-6 p-2">
              <div class="row">
                <div class="name col-6">Quantity:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.quantity }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Board Layer:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.boardLayer }}</div>
              </div>
              <div class="row">
                <div class="name col-6">Surface Finish:</div>
                <div class="names col-6">{{ OrderDetailsAtPopup.surfaceFinish }}</div>
              </div>
            </div>
          </div>
      
          <!-- Dialog Buttons -->
          <div class="d-flex mt-5 mb-2 justify-content-center">
            <button matDialogClose class="cancelBtn">
              CANCEL
            </button>
            <button matDialogClose class="proceedBtn">
              PROCEED
            </button>
          </div>
        </div>
      </div>
      
      
    </div>
  </ng-template>
  
  