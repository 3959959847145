<!-- header -->
<div class="main">

    <div class=" row m-0 header1">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-8 col-xxl-8"></div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 email">
            <img src="../../assets/images/emailIconHeader.png" alt="email" class="img">
            <h3 class="text1">Information@SCLpcb.com</h3>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 col-xxl-2 email">
            <img src="../../assets/images/callIcon.png" alt="phone" class="img">
            <h3 class="text1">+91 986 619 4011</h3>
        </div>
    </div>
    <div class="row m-0 p-0 header2">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 col-xxl-8 p-0 m-0">
            <img src="../../assets/images/headerFinalLogo-png.png" class="sclImg">
        </div>
      
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 icons" style="padding-top:10px;" align="right">
        <mat-icon style="color: white;" class="icons1 m-2 {{ userIdExists() ? 'pointer-enabled' : 'disabled-icon' }}" routerLink="/home"
          matBadgeColor="warn">
          home
        </mat-icon>

        <mat-icon style="color: white;" class="icons1 m-2 {{ userIdExists() ? 'pointer-enabled' : 'disabled-icon' }}" mat-button routerLink="/cart"
           matBadgeColor="warn">
           shopping_cart
        </mat-icon>

        <mat-icon style="color: white;" class="icons1 m-2 {{ userIdExists() ? 'pointer-enabled' : 'disabled-icon' }}" mat-button [matMenuTriggerFor]="menu"
        matBadgeColor="warn">
        account_circle
        </mat-icon>

        <!-- <mat-icon style="color: white;" class="" mat-button [matMenuTriggerFor]="menu"> account_circle</mat-icon>
        <mat-icon style="color: white;" class=" " mat-button [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon> -->

        <div class="ssdd">
          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/cart">Cart Management</button>
            <button mat-menu-item routerLink="/quote">
              Order Management
            </button>
            <button mat-menu-item routerLink="/profile">
                Profile Management
              </button>
            <button mat-menu-item (click)="goToSignupScreen()">Logout</button>
          </mat-menu>
          <!-- <mat-menu #beforeMenu="matMenu" xPosition="before">
            <div class="notifications">
              <span>Notifications </span>
              <span><button class="btn1">  new </button></span>
              <span>Mark all as read </span>
              <span class="view" routerLink="/notification"> View all</span>
            </div>
            <hr>
          </mat-menu> -->

        </div>

      </div>

      <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1 col-xl-1 col-xxl-1 signin" align="left">
        <h3 (click)="goToSignupScreen()" class="signtext">Log Out</h3>
        <h3 class="signtext">Log In</h3>
      </div> -->

      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1 col-xl-1 col-xxl-1 signin" align="left">
        <h3 *ngIf="isLoggedIn()" (click)="logOut()" class="signtext">Log Out</h3>
        <h3 *ngIf="!isLoggedIn()" (click)="goToSignupScreen()" class="signtext">Log In</h3>
    </div>
    

    </div>

</div>